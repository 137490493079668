import { API, handleApiError } from "./utils";

// Get conversations by user ID
export const getConversations = async (userId) => {
  try {
    console.log(`Fetching conversations for userId: ${userId}`);
    const { data } = await API.get(`/conversations/${userId}`);
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

// Create a new conversation
export const createConversation = async (conversationData) => {
  try {
    const { data } = await API.post("/conversations", conversationData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

// Update a conversation
export const updateConversation = async (conversationId, updatedData) => {
  try {
    const { data } = await API.patch(
      `/conversations/${conversationId}`,
      updatedData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

// Delete a conversation
export const deleteConversation = async (conversationId) => {
  try {
    const { data } = await API.delete(`/conversations/${conversationId}`);
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

// Get a single conversation by ID
export const getConversationById = async (conversationId) => {
  try {
    const { data } = await API.get(`/conversations/${conversationId}`);
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

// Get all conversations with pagination
export const getAllConversations = async (limit = 10, skip = 0) => {
  try {
    const { data } = await API.get(
      `/conversations?limit=${limit}&skip=${skip}`
    );
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};
