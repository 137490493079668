import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import CommonLoading from "../../components/loader/CommonLoading";
import CommunityRightbar from "../../components/community/Rightbar";
import CommunityMainSection from "../../components/community/MainSection";
import { Box, Stack, Fab, Drawer } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import InfoIcon from "@mui/icons-material/Info";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const CommunityHome = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { communityName } = useParams();

  const { joinedCommunities } = useSelector((state) => state.community || {});
  const isAuthorized = joinedCommunities?.some(
    ({ name }) => name === communityName
  );

  const isDesktop = useResponsive("up", "md");

  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (!isAuthorized && joinedCommunities?.length > 0) {
      navigate("/access-denied");
    }
  }, [isAuthorized, joinedCommunities, navigate, communityName]);

  if (!joinedCommunities) {
    return (
      <>
        <CommonLoading />
      </>
    );
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      {/* Floating button to open drawer on mobile */}
      {!isDesktop && (
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: theme.spacing(10),
            right: theme.spacing(2),
          }}
          onClick={handleDrawerOpen}
        >
          <InfoIcon />
        </Fab>
      )}

      {/* Drawer component */}
      {!isDesktop && (
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          BackdropProps={{
            onClick: handleDrawerClose,
          }}
          ModalProps={{
            disableBackdropClick: true, // Prevents closing when backdrop is clicked
          }}
        >
          <Stack>
            <Fab
              color="primary"
              aria-label="close"
              sx={{
                position: "fixed",
                bottom: theme.spacing(2),
                left: theme.spacing(2),
              }}
              onClick={handleDrawerClose}
            >
              <ArrowForwardIosIcon />
            </Fab>

            <CommunityRightbar />
          </Stack>
        </Drawer>
      )}

      <Stack direction={isDesktop ? "row" : "column"} sx={{ width: "100%" }}>
        {isDesktop ? (
          <>
            <Box
              sx={{
                height: "100%",
                width: "calc(100vw - 320px)",
                backgroundColor:
                  theme.palette.mode === "light"
                    ? "#f0f4fa "
                    : theme.palette.background.default,
              }}
            >
              <CommunityMainSection />
            </Box>
            <Stack>
              <CommunityRightbar />
            </Stack>
          </>
        ) : (
          <>
            {/* Render CommunityRightbar on mobile */}
            {!drawerOpen && <CommunityMainSection />}
          </>
        )}
      </Stack>
    </>
  );
};

export default CommunityHome;
