import React, { useEffect, useRef } from "react";

import { Box, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import ChatHeader from "./ChatHeader";
import ChatFooter from "./ChatFooter";
import Message from "./Message";

import useResponsive from "../../hooks/useResponsive";
import { SimpleBarStyle } from "../Scrollbar";
import { useSelector } from "react-redux";

const Conversation = () => {
  const isMobile = useResponsive("between", "md", "xs", "sm");
  const theme = useTheme();

  const { current_messages } = useSelector(
    (state) => state.conversation.direct_chat
  );

  const messageListRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the message list when new messages are added
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  }, [current_messages]);

  return (
    <>
      <Stack
        height={"100%"}
        maxHeight={"100vh"}
        width={isMobile ? "100vw" : "auto"}
      >
        {/* chat header */}
        <ChatHeader />
        {/* messages */}
        <Box
          ref={messageListRef}
          width={"100%"}
          sx={{
            position: "relative",
            flexGrow: 1,
            height: "100%",
            overflowY: "scroll",

            backgroundColor:
              theme.palette.mode === "light"
                ? "#F0F4FA"
                : theme.palette.background,

            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
          }}
        >
          <SimpleBarStyle timeout={500} clickOnTrack={false}>
            <Message menu={true} isMobile={isMobile} />
          </SimpleBarStyle>
        </Box>
        {/* chat input */}
        <ChatFooter />
      </Stack>
    </>
  );
};

export default Conversation;
