import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// import useResponsive from "../../hooks/useResponsive";
import BottomBar from "./BottomBar";
import MiniSidebar from "./MiniSidebar";
import FullSidebar from "./FullSidebar";
import TopBar from "./TopBar";

const SidebarNav = ({ userData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("md"));
  // const isDesktop = useResponsive("up", "md");

  // Debugging logs
  // console.log("isMobile:", isMobile);
  // console.log("isTablet:", isTablet);

  return (
    <>
      {isMobile && <TopBar userData={userData} />}
      {isMobile || isTablet ? (
        <BottomBar userData={userData} />
      ) : (
        <>
          {/* Sidebar content */}
          {isTablet ? (
            <MiniSidebar userData={userData} />
          ) : (
            <FullSidebar userData={userData} />
          )}
        </>
      )}
    </>
  );
};

export default SidebarNav;
