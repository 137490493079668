import { useEffect, useState, useMemo, useCallback } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import LeaveModal from "../modals/LeaveModal";
import { getCommunityAction } from "../../redux/actions/communityActions";
import placeholder from "../../assets/placeholder.png";
import CommonLoading from "../loader/CommonLoading";

import {
  useBannerLoading,
  useIsModeratorUpdated,
} from "../../hooks/useCommunityData";
import { HiUserGroup, HiOutlineCheckBadge } from "react-icons/hi2";
import {
  Box,
  Button,
  ListItem,
  Stack,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import useResponsive from "../../hooks/useResponsive";

const Rightbar = () => {
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");

  const dispatch = useDispatch();
  const { communityName } = useParams();

  const toggleLeaveModal = useCallback(() => {
    setShowLeaveModal((prevState) => !prevState);
  }, []);

  useEffect(() => {
    dispatch(getCommunityAction(communityName));
  }, [dispatch, communityName]);

  const communityData = useSelector((state) => state.community?.communityData);

  const isModeratorOfThisCommunity = useSelector(
    (state) => state.auth?.isModeratorOfThisCommunity
  );

  const { name, description, members, rules, banner } = useMemo(
    () => communityData || {},
    [communityData]
  );

  const bannerLoaded = useBannerLoading(banner);
  const isModeratorUpdated = useIsModeratorUpdated(isModeratorOfThisCommunity);

  if (!communityData) {
    return (
      <>
        <CommonLoading />
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          width: !isDesktop ? "100vw" : 320,
          height: "100vh",
          boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
          backgroundColor:
            theme.palette.mode === "light"
              ? "#f8faff"
              : theme.palette.background.paper,
        }}
      >
        <Stack sx={{ height: "100%" }} p={1.5} spacing={2}>
          <Box
            sx={{
              boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
              width: "100%",
              backgroundColor:
                theme.palette.mode === "light"
                  ? "#f8faff"
                  : theme.palette.background.paper,
            }}
          >
            <Stack
              direction={"column"}
              sx={{ height: "100%", p: 2 }}
              alignItems={"center"}
              spacing={1}
              justifyContent={"space-between"}
            >
              <Typography variant="subtitle2" fontSize={20}>
                {name}
              </Typography>
              <Stack
                direction={"row"}
                spacing={1}
                alignItems={"center"}
                color={"#999"}
              >
                <HiUserGroup />
                <Typography>
                  {members?.length || 0}{" "}
                  {members?.length === 1 ? "member" : "members"}
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Box
            width={"100%"}
            sx={{
              flexGrow: 1,
              height: "100%",
              overflowY: "scroll",
            }}
          >
            <Stack m={1} color={"text.secondary"}>
              {bannerLoaded ? (
                <img
                  src={banner}
                  alt="group banner"
                  style={{ borderRadius: 5 }}
                  onError={(e) => {
                    e.target.src = placeholder;
                  }}
                />
              ) : (
                <img
                  src={placeholder}
                  alt="banner placeholder"
                  style={{ borderRadius: 5 }}
                />
              )}

              <Typography variant="body">{description}</Typography>

              <Stack m={1}>
                {isModeratorOfThisCommunity && (
                  <Button variant="outlined">
                    <MuiLink
                      underline="none"
                      component={RouterLink}
                      to={`/community/${communityName}/moderator`}
                    >
                      Moderation Panel
                    </MuiLink>
                  </Button>
                )}

                {isModeratorUpdated && !isModeratorOfThisCommunity && (
                  <Button onClick={toggleLeaveModal} variant="outlined">
                    Leave Group
                  </Button>
                )}
                {
                  <LeaveModal
                    open={showLeaveModal}
                    handleCloseLeaveModal={toggleLeaveModal}
                    communityName={communityName}
                  />
                }
              </Stack>
              {rules && rules.length > 0 && (
                <Stack spacing={1}>
                  <Typography>Group Guidelines:</Typography>
                  <ul>
                    {rules.map((rule) => (
                      <ListItem
                        sx={{ width: "100%", alignItems: "flex-start", gap: 1 }}
                        key={rule._id}
                        style={{ listStyle: "none" }}
                      >
                        <HiOutlineCheckBadge />
                        <Typography fontSize={"small"}> {rule.rule}</Typography>
                      </ListItem>
                    ))}
                  </ul>
                </Stack>
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Rightbar;
