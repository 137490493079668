import React, { useState } from "react";
import { reportPostAction } from "../../redux/actions/communityActions";
import { useDispatch } from "react-redux";

// import { Dialog } from "@headlessui/react";
import ButtonLoadingSpinner from "../loader/ButtonLoadingSpinner";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Slide,
  Stack,
  Typography,
  TextField,
} from "@mui/material";

import Transition from "../Transition";

const ReportPostModal = ({
  open,
  onClose,
  postId,
  communityId,
  setReportedPost,
}) => {
  const [reportReason, setReportReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleReportSubmit = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        reportPostAction({
          postId,
          reportReason,
          communityId,
        })
      );
      setIsLoading(false);
      setReportedPost(true);
      onClose();
    } catch (error) {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={Transition}
        keepMounted
        justifyContent={"center"}
      >
        <DialogTitle>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
            >
              <Typography variant="caption2" color="primary">
                Report Post
              </Typography>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Stack className="p-4">
                <Stack className="mt-1">
                  <TextField
                    label="Reason for report"
                    variant="outlined"
                    name="report-reason"
                    id="report-reason"
                    value={reportReason}
                    onChange={(e) => setReportReason(e.target.value)}
                  />
                </Stack>
              </Stack>
            </Stack>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
            >
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button
                disabled={isLoading || !reportReason}
                onClick={handleReportSubmit}
                variant="outlined"
                color="error"
                disableElevation
              >
                {isLoading ? (
                  <ButtonLoadingSpinner loadingText={"Reporting..."} />
                ) : (
                  "Report"
                )}
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReportPostModal;
