import { useState } from "react";
import JoinModal from "../modals/JoinModal";
import placeholder from "../../assets/placeholder.png";
import { MdOutlineGroupAdd } from "react-icons/md";
import { Avatar, Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const CommunityCard = ({ community }) => {
  const [openJoinModal, setOpenJoinModal] = useState(false);

  // const handleCloseJoinModal = () => {
  //   setOpenJoinModal(false);
  // };

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const handleOpenModal = () => {
  //   setIsModalOpen(true);
  // };

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  const theme = useTheme();

  const toggleJoinModal = (communityId, visible) => {
    setOpenJoinModal((prev) => ({
      ...prev,
      [communityId]: visible,
    }));
  };
  return (
    <Stack
      direction={"row"}
      spacing={1}
      sx={{
        px: 3,
        py: 3,

        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
        borderRadius: 1,
        "&:hover": {
          boxShadow: "2xl",
        },
        "&.Mui-focusVisible": {
          boxShadow: "2xl",
        },
        justifyContent: "space-between",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
        }}
        direction={"row"}
      >
        <Avatar
          sx={{
            marginRight: 1,
            width: 60,
            height: 60,
          }}
          alt="community banner"
          src={community.banner || placeholder}
          loading="lazy"
        />
        <Stack>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "wrap",
              mb: 0,
            }}
          >
            {community.name}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {community.members.length} members
          </Typography>
        </Stack>
      </Stack>

      <Stack>
        <Button
          onClick={() => toggleJoinModal(community._id, true)}
          variant="outlined"
        >
          <MdOutlineGroupAdd fontSize="large" />
        </Button>
        <JoinModal
          open={openJoinModal[community._id] || false}
          handleCloseJoinModal={() => toggleJoinModal(community._id, false)}
          community={community}
        />
      </Stack>
    </Stack>
  );
};

export default CommunityCard;
