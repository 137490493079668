import { combineReducers } from "redux";

import postsReducer from "./posts";
import authReducer from "./auth";
import communityReducer from "./community";
import moderationReducer from "./moderation";
import userReducer from "./user";
import adminReducer from "./admin";
import appSlice from "./snackbar";
import notificationReducer from "./notification";
import conversationReducer from "./conversation";
import messageReducer from "./message";

const rootReducer = combineReducers({
  posts: postsReducer,
  notifications: notificationReducer,
  auth: authReducer,
  community: communityReducer,
  moderation: moderationReducer,
  user: userReducer,
  admin: adminReducer,
  conversation: conversationReducer,
  message: messageReducer,

  app: appSlice,
});

export default rootReducer;
