import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  sideBar: {
    open: false,
    type: "CONTACT", // Sidebar type (CONTACT, STARRED, SHARED)
  },
  isLoggedIn: true,
  tab: 0, // [0, 1, 2, 3] Active tab index
  snackbar: {
    open: null,
    severity: null,
    message: null,
  },
};

// Create a Redux slice named 'app' with initial state
const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    // Reducer to toggle the sidebar
    toggleSideBar(state) {
      state.sideBar.open = !state.sideBar.open;
    },
    // Reducer to update the type of the sidebar
    updateSideBarType(state, action) {
      state.sideBar.type = action.payload.type;
    },
    // Reducer to update the active tab index
    updateTab(state, action) {
      state.tab = action.payload.tab;
    },

    // Reducer to open a snackbar with severity and message
    openSnackBar(state, action) {
      console.log(action.payload);
      state.snackbar.open = true;
      state.snackbar.severity = action.payload.severity;
      state.snackbar.message = action.payload.message;
    },

    // Reducer to close the snackbar
    closeSnackBar(state) {
      console.log("This is getting executed");
      state.snackbar.open = false;
      state.snackbar.message = null;
    },

    // Reducer to select an individual conversation
    selectConversation(state, action) {
      state.chat_type = "individual";
      state.room_id = action.payload.room_id;
    },
  },
});

// Export the reducer as the default export of the module
export default appSlice.reducer;

// Action creator to close the snackbar
export const closeSnackBar = () => async (dispatch, getState) => {
  dispatch(appSlice.actions.closeSnackBar());
};

// Action creator to show a snackbar with severity and message
export const showSnackbar =
  ({ severity, message }) =>
  async (dispatch, getState) => {
    dispatch(
      appSlice.actions.openSnackBar({
        message,
        severity,
      })
    );

    setTimeout(() => {
      dispatch(appSlice.actions.closeSnackBar());
    }, 4000);
  };

// Action creators for toggling the sidebar, updating sidebar type, and updating the active tab
export function ToggleSidebar() {
  return async (dispatch, getState) => {
    dispatch(appSlice.actions.toggleSideBar());
  };
}
export function UpdateSidebarType(type) {
  return async (dispatch, getState) => {
    dispatch(appSlice.actions.updateSideBarType({ type }));
  };
}
export function UpdateTab(tab) {
  return async (dispatch, getState) => {
    dispatch(appSlice.actions.updateTab(tab));
  };
}
