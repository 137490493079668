import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Divider, Link, Stack, Typography } from "@mui/material";
import SignInForm from "../../sections/auth/SignInForm";

const SignIn = () => {
  return (
    <>
      <Stack spacing={4} sx={{ mb: 2, position: "relative" }}>
        <Stack justifyContent={"space-between"} sx={{ my: 2 }} spacing={0.5}>
          <Typography variant="h6" textAlign={"center"} color={"#4fa3a5"}>
            Welcome to MoMs Meet
          </Typography>
          <Typography variant="h3">Sign In</Typography>
        </Stack>
        <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
          <Typography>Not here?</Typography>
          <Link to="https://meshofmothers.ca" component={RouterLink}>
            Go to main website
          </Link>
        </Stack>
        {/* sign in form */}
        <SignInForm />

        {/* copyright info */}

        <Stack>
          <Divider />
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems="center"
            sx={{ my: 2 }}
            spacing={0.5}
          >
            <Typography fontSize={14}>Designed & Developed By: </Typography>
            <Link
              fontSize={14}
              component={RouterLink}
              to="http://www.veltetech.com"
            >
              Velte Technology Solutions
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default SignIn;
