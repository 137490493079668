import React from "react";
import { Outlet } from "react-router-dom";
import { Container, Stack } from "@mui/material";
import Logo from "../../assets/Images/Logo-mark_coloured.png";

const AuthLayout = () => {
  return (
    <>
      <Container sx={{ mt: 5 }} maxWidth="sm">
        <Stack spacing={5} sx={{ mb: 5 }}>
          <Stack
            sx={{ width: "100%" }}
            direction={"column"}
            alignItems={"center"}
          >
            <img style={{ height: "auto", width: 120 }} src={Logo} alt="Logo" />
          </Stack>
        </Stack>

        <Outlet />
      </Container>
    </>
  );
};

export default AuthLayout;
