import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getNotJoinedCommunitiesAction } from "../../redux/actions/communityActions";
import {
  getPublicUsersAction,
  followUserAndFetchData,
} from "../../redux/actions/userActions";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BsPersonPlusFill } from "react-icons/bs";
import {
  Avatar,
  Button,
  Stack,
  Link as MuiLink,
  Typography,
  Grid,
} from "@mui/material";

const Follow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [publicUsersFetched, setPublicUsersFetched] = useState(false);

  const currentUser = useSelector((state) => state.auth?.userData);

  const recommendedUsers = useSelector((state) => state.user?.publicUsers);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getPublicUsersAction());
    };

    fetchData().then(() => {
      setPublicUsersFetched(true);
    });
  }, [dispatch]);

  const [followLoading, setFollowLoadingState] = useState({});

  const followUserHandler = useCallback(
    async (toFollowId) => {
      setFollowLoadingState((prevState) => ({
        ...prevState,
        [toFollowId]: true,
      }));

      await dispatch(followUserAndFetchData(toFollowId, currentUser));

      setFollowLoadingState((prevState) => ({
        ...prevState,
        [toFollowId]: false,
      }));

      navigate(`/user/${toFollowId}`);
    },
    [dispatch, currentUser, navigate]
  );

  return (
    <>
      {publicUsersFetched && recommendedUsers?.length === 0 && (
        <Stack>No users to follow. Check back later</Stack>
      )}
      <Stack
        sx={{
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
          gap: 1.2,
          alignItems: "center",
          padding: "4px",
          "&.md": {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        {recommendedUsers?.length > 0 &&
          recommendedUsers.map((user) => (
            <li key={user._id} style={{ listStyle: "none" }}>
              <Stack
                direction={"row"}
                spacing={2}
                p={3}
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
                  borderRadius: 1,
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <Stack direction={"row"} spacing={2}>
                  <Avatar
                    sx={{ width: 60, height: 60 }}
                    src={user.avatar}
                    alt={user.name}
                  />
                  <Stack>
                    <MuiLink
                      underline="none"
                      to={`/user/${user._id}`}
                      className="font-medium text-base line-clamp-1"
                    >
                      {user.name}
                    </MuiLink>
                    <Typography color="text.secondary">
                      Followers: {user.followerCount}
                    </Typography>
                  </Stack>
                </Stack>
                <Button
                  //   variant="outlined"
                  disabled={followLoading[user._id]}
                  onClick={() => followUserHandler(user._id)}
                >
                  {followLoading[user._id] ? (
                    <Stack>
                      <span className="loader"></span>
                    </Stack>
                  ) : (
                    <Stack
                      direction={"row"}
                      spacing={0.7}
                      alignItems={"center"}
                    >
                      <BsPersonPlusFill />
                      <Typography>Follow</Typography>
                    </Stack>
                  )}
                </Button>
              </Stack>
            </li>
          ))}
      </Stack>
    </>
  );
};

export default Follow;
