import React from "react";
import { useSelector } from "react-redux";
import UserProfile from "../../components/profile/userProfile";

const Profile = () => {
  const userData = useSelector((state) => state.auth?.userData);
  return (
    <>
      <UserProfile userData={userData} />
    </>
  );
};

export default Profile;
