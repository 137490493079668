import { memo, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFollowingUsersPostsAction } from "../../redux/actions/postActions";
import CommonLoading from "../loader/CommonLoading";
import Post from "../PostElement/Post";
// import NoPost from "../../assets/nopost.jpg";
import { Stack, Typography } from "@mui/material";

const MemoizedPost = memo(Post);

const FollowingUsersPosts = ({ communityData }) => {
  const dispatch = useDispatch();

  const followingUsersPosts = useSelector(
    (state) => state.posts?.followingUsersPosts
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchInitialPosts = async () => {
      setIsLoading(true);
      if (communityData?._id) {
        await dispatch(getFollowingUsersPostsAction(communityData._id));
      }
      setIsLoading(false);
    };
    fetchInitialPosts();
  }, [dispatch, communityData]);

  const memoizedFollowingUsersPost = useMemo(() => {
    return followingUsersPosts.map((post) => (
      <MemoizedPost key={post._id} post={post} />
    ));
  }, [followingUsersPosts]);

  return (
    <div className="main-section">
      {isLoading || !communityData ? (
        <>
          <CommonLoading />
        </>
      ) : (
        <>
          {followingUsersPosts.length > 0 ? (
            <div>{memoizedFollowingUsersPost}</div>
          ) : (
            <Stack>
              <Typography variant="body">
                None of your following users have posted anything yet. Check
                back later!
              </Typography>
              {/* <img loading="lazy" src={NoPost} alt="no post" /> */}
            </Stack>
          )}
        </>
      )}
    </div>
  );
};

export default FollowingUsersPosts;
