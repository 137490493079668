import { useEffect, useState } from "react";
import Post from "../PostElement/Post";
import { useSelector, useDispatch } from "react-redux";
import { getPublicPostsAction } from "../../redux/actions/postActions";
import CommonLoading from "../loader/CommonLoading";
import { Stack } from "@mui/material";

const PublicPost = ({ publicUserId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const publicPosts = useSelector((state) => state.posts?.publicPosts);

  useEffect(() => {
    const getPublicPosts = async () => {
      setLoading(true);
      await dispatch(getPublicPostsAction(publicUserId));
      setLoading(false);
    };
    getPublicPosts();
  }, [dispatch, publicUserId]);

  if (loading) {
    return (
      <>
        <CommonLoading />
      </>
    );
  }

  return (
    <Stack>
      <Stack
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        <h2>Posts</h2>
      </Stack>

      {publicPosts?.length === 0 ? (
        <p className="text-center">
          User has not posted anything yet. Check back later!
        </p>
      ) : (
        <Stack className="">
          {publicPosts?.map((post) => (
            <Post key={post._id} post={post} />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default PublicPost;
