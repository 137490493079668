import { useMemo, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setInitialAuthState } from "../redux/actions/authActions";

import ModeratorRightbar from "../components/moderator/Rightbar";
import { Stack } from "@mui/material";

import SidebarNav from "../components/shared/Navigations";

const noRightbarRoutes = [
  /\/post\/[^/]+$/,
  /\/community\/[^/]+$/,
  /\/community\/[^/]+\/report$/,
  /\/community\/[^/]+\/reported-post$/,
  /\/community\/[^/]+\/moderator$/,
].map((regex) => new RegExp(regex));

const PrivateRoute = ({ userData }) => {
  const isAuthenticated = useMemo(() => {
    return (userData, accessToken) => {
      return userData && accessToken;
    };
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("profile");
  const accessToken = JSON.parse(token)?.accessToken;

  const currentUserIsModerator = userData?.role === "moderator";

  useEffect(() => {
    if (!isAuthenticated(userData, accessToken)) {
      dispatch(setInitialAuthState(navigate));
    }
  }, [dispatch, navigate, userData, accessToken, isAuthenticated]);

  const showRightbar = !noRightbarRoutes.some((regex) =>
    regex.test(location.pathname)
  );

  return isAuthenticated(userData, accessToken) ? (
    <Stack direction="row" sx={{ width: "100vw" }}>
      <SidebarNav userData={userData} />

      <Outlet />

      {showRightbar ? (
        currentUserIsModerator ? (
          <ModeratorRightbar />
        ) : // <Rightbar />
        null
      ) : null}
    </Stack>
  ) : (
    <Navigate to="/signin" />
  );
};

export default PrivateRoute;
