import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import FallbackLoading from "../components/loader/FallbackLoading";
import { publicRoutes, privateRoutes } from "./routes";

import PrivateRoute from "./PrivateRoute";
import SignIn from "../pages/auth/Signin";
import AuthLayout from "../layouts/main";

const AdminPanel = lazy(() => import("../pages/admin/AdminPanel"));
const AdminSignIn = lazy(() => import("../pages/auth/AdminSignIn"));

const Router = () => {
  const userData = useSelector((state) => state.auth?.userData);
  const adminAccessToken = JSON.parse(
    localStorage.getItem("admin")
  )?.accessToken;

  return (
    <Suspense fallback={<FallbackLoading />}>
      <Routes>
        <Route element={<PrivateRoute userData={userData} />}>
          {privateRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>

        <Route element={<AuthLayout />}>
          {publicRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>

        <Route element={<AuthLayout />}>
          <Route
            path="/signin"
            element={userData ? <Navigate to="/" /> : <SignIn />}
          />
        </Route>

        <Route element={<AuthLayout />}>
          <Route
            path="/admin/signin"
            element={
              adminAccessToken ? <Navigate to="/admin" /> : <AdminSignIn />
            }
          />
        </Route>

        <Route
          path="/admin"
          element={
            adminAccessToken ? <AdminPanel /> : <Navigate to="/admin/signin" />
          }
        />
      </Routes>
    </Suspense>
  );
};

export default Router;
