import { API, handleApiError } from "./utils";

export const createNotification = async (notificationData) => {
  try {
    const { data } = await API.post("/notifications", notificationData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const updateNotification = async (notificationId, updatedData) => {
  try {
    const { data } = await API.patch(
      `/notifications/${notificationId}`,
      updatedData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const deleteNotification = async (notificationId) => {
  try {
    const { data } = await API.delete(`/notifications/${notificationId}`);
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const getNotification = async (notificationId) => {
  try {
    const { data } = await API.get(`/notifications/${notificationId}`);
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const getNotifications = async (limit = 10, skip = 0) => {
  try {
    const { data } = await API.get(
      `/notifications?limit=${limit}&skip=${skip}`
    );

    // Log the fetched data
    // console.log("Fetched Notifications from API:", data);

    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const markNotificationAsRead = async (notificationId) => {
  try {
    // console.log(
    //   `Sending request to mark notification ${notificationId} as read`
    // );
    const { data } = await API.patch(
      `/notifications/${notificationId}/mark-read`
    );
    // console.log("Marked Notification as Read:", data);
    return { error: null, data };
  } catch (error) {
    // console.error("Error in marking notification as read:", error);
    return handleApiError(error);
  }
};

export const getUnreadNotificationsCount = async () => {
  try {
    const { data } = await API.get("/notifications/unread-count");
    // console.log("API response:", data);
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};
