import { memo, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getComPostsAction,
  clearCommunityPostsAction,
} from "../../redux/actions/postActions";
import PostForm from "../form/PostForm";
import Post from "../PostElement/Post";
import FollowingUsersPosts from "./FollowingUsersPosts";
import CommonLoading from "../loader/CommonLoading";
import {
  Box,
  Button,
  Container,
  // Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import useResponsive from "../../hooks/useResponsive";
import { SimpleBarStyle } from "../Scrollbar";
import { RiFileUserFill } from "react-icons/ri";
import { FaFileAlt } from "react-icons/fa";
import Footer from "../Footer";

const MemoizedPost = memo(Post);

const MainSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const communityData = useSelector((state) => state.community?.communityData);
  const communityPosts = useSelector((state) => state.posts?.communityPosts);

  const totalCommunityPosts = useSelector(
    (state) => state.posts?.totalCommunityPosts
  );

  const [activeTab, setActiveTab] = useState("All posts");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const LIMIT = 10;

  const postError = useSelector((state) => state.posts?.postError);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchInitialPosts = async () => {
      if (communityData?._id) {
        dispatch(getComPostsAction(communityData._id, LIMIT, 0)).finally(() => {
          setIsLoading(false);
        });
      }
    };

    fetchInitialPosts();

    return () => {
      dispatch(clearCommunityPostsAction());
    };
  }, [dispatch, communityData]);

  const handleLoadMore = () => {
    if (
      !isLoadMoreLoading &&
      communityPosts.length > 0 &&
      communityPosts.length < totalCommunityPosts
    ) {
      setIsLoadMoreLoading(true);
      dispatch(
        getComPostsAction(communityData._id, LIMIT, communityPosts.length)
      ).finally(() => {
        setIsLoadMoreLoading(false);
      });
    }
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const memoizedCommunityPosts = useMemo(() => {
    return communityPosts?.map((post) => (
      <MemoizedPost key={post._id} post={post} />
    ));
  }, [communityPosts]);

  if (isLoading || !communityData || !communityPosts) {
    return (
      <>
        <CommonLoading />
      </>
    );
  }

  return (
    <Stack
      height={"100vh"}
      maxHeight={"100vh"}
      width={isMobile ? "100vw" : "auto"}
    >
      <Box
        width={"100%"}
        sx={{
          position: "relative",
          flexGrow: 1,
          height: "100%",
          overflowY: "scroll",

          backgroundColor:
            theme.palette.mode === "light"
              ? "#f0f4fa "
              : theme.palette.background.default,

          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <SimpleBarStyle timeout={500}>
          <Stack
            spacing={2}
            justifyContent="center"
            alignItems="center"
            width="100%"
            mt={2}
          >
            <Container sx={{ mt: isMobile ? 10 : 5 }} maxWidth="sm">
              <Tabs
                value={activeTab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable auto tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="All posts"
                  value="All posts"
                  icon={<FaFileAlt />}
                  iconPosition="start"
                />
                <Tab
                  label="following"
                  value="following"
                  icon={<RiFileUserFill />}
                  iconPosition="start"
                />
              </Tabs>
              <Stack mt={2} mb={2}>
                {activeTab === "All posts" && (
                  <>
                    <Stack mb={3}>
                      <PostForm
                        communityId={communityData._id}
                        communityName={communityData.name}
                      />
                    </Stack>
                    {postError && (
                      <Box
                        sx={{
                          color: "#dc2626",
                          border: "1px solid #dc2626",
                          padding: "12px",
                          borderRadius: "8px",
                          textAlign: "center",
                          mx: "auto",
                          width: "100%",
                        }}
                        mb={2}
                      >
                        <Typography>{postError}</Typography>
                      </Box>
                    )}

                    <Stack>{memoizedCommunityPosts}</Stack>
                    {communityPosts.length < totalCommunityPosts && (
                      <Button
                        onClick={handleLoadMore}
                        disabled={isLoadMoreLoading}
                      >
                        {isLoadMoreLoading ? "Loading..." : "Load More Posts"}
                      </Button>
                    )}
                  </>
                )}
                {activeTab === "following" && (
                  <FollowingUsersPosts communityData={communityData} />
                )}
              </Stack>
              <Stack mb={3} spacing={2}>
                <Footer />
              </Stack>
            </Container>
          </Stack>
        </SimpleBarStyle>
      </Box>
    </Stack>
  );
};

export default MainSection;
