import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import {
  ArchiveBox,
  CircleDashed,
  MagnifyingGlass,
  Users,
} from "phosphor-react";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getConversationsAction } from "../../redux/actions/conversationActions";
import { getMessagesAction } from "../../redux/actions/messageActions";
import { SimpleBarStyle } from "../../components/Scrollbar";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../components/Search";
import ChatElement from "../../components/ChatElement";
import Friends from "../../sections/dashboard/Friends";
import { socket } from "../../socket";
import Messages from "../../components/messages";

// const user_id = window.localStorage.getItem("user_id");

const Chats = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const user_id = window.localStorage.getItem("user_id");

  const [openDialog, setOpenDialog] = useState(false);

  const { conversations } = useSelector((state) => state.conversation);
  const { selectedConversationId } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(getConversationsAction(user_id));
  }, [dispatch, user_id]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleChatSelect = (conversationId) => {
    dispatch(getMessagesAction(conversationId));
  };

  return (
    <Stack direction="row" sx={{ height: "100vh" }}>
      <Box
        sx={{
          position: "relative",
          width: 320,
          backgroundColor:
            theme.palette.mode === "light"
              ? "#f8faff"
              : theme.palette.background.paper,
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack p={3} spacing={2} sx={{ height: "100vh" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Chats</Typography>
            <Stack direction={"row"} alignItems="center" spacing={1}>
              <IconButton onClick={handleOpenDialog}>
                <Users />
              </IconButton>
              <IconButton>
                <CircleDashed />
              </IconButton>
            </Stack>
          </Stack>
          <Stack sx={{ width: "100%" }}>
            <Search>
              <SearchIconWrapper>
                <MagnifyingGlass color="#4fa3a5" />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Stack>
          <Stack spacing={1}>
            <Stack direction={"row"} spacing={1.5} alignItems="center">
              <ArchiveBox size={24} />
              <Button variant="text">Archive</Button>
            </Stack>
            <Divider />
          </Stack>
          <Stack
            spacing={2}
            direction="column"
            sx={{ flexGrow: 1, overflowY: "scroll", height: "100%" }}
          >
            <SimpleBarStyle timeout={500} clickOnTrack={false}>
              <Stack spacing={2.4}>
                <Typography variant="subtitle2" sx={{ color: "#676767" }}>
                  Pinned
                </Typography>
                {conversations
                  .filter((el) => el.pinned)
                  .map((el) => (
                    <ChatElement
                      key={el._id}
                      {...el}
                      onSelect={handleChatSelect}
                    />
                  ))}
                <Typography variant="subtitle2" sx={{ color: "#676767" }}>
                  All Chats
                </Typography>
                {conversations
                  .filter((el) => !el.pinned)
                  .map((el) => (
                    <ChatElement
                      key={el._id}
                      {...el}
                      onSelect={handleChatSelect}
                    />
                  ))}
              </Stack>
            </SimpleBarStyle>
          </Stack>
        </Stack>
      </Box>
      {openDialog && (
        <Friends open={openDialog} handleClose={handleCloseDialog} />
      )}
      {selectedConversationId && (
        <Box sx={{ flexGrow: 1 }}>
          <Messages />
        </Box>
      )}
    </Stack>
  );
};

export default Chats;
