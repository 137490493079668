export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAIL = "SEND_MESSAGE_FAIL";
export const MARK_MESSAGE_AS_READ_SUCCESS = "MARK_MESSAGE_AS_READ_SUCCESS";
export const MARK_MESSAGE_AS_READ_FAIL = "MARK_MESSAGE_AS_READ_FAIL";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_FAIL = "DELETE_MESSAGE_FAIL";
export const GET_UNREAD_MESSAGES_COUNT_SUCCESS =
  "GET_UNREAD_MESSAGES_COUNT_SUCCESS";
export const GET_UNREAD_MESSAGES_COUNT_FAIL = "GET_UNREAD_MESSAGES_COUNT_FAIL";
