import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { joinCommunityAndFetchData } from "../../redux/actions/communityActions";
// import LoadingSpinner from "../loader/ButtonLoadingSpinner";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { IoIosPeople } from "react-icons/io";

import Transition from "../Transition";

const JoinModal = ({ open, handleCloseJoinModal, community }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const userData = useSelector((state) => state.auth?.userData);

  const joinCommunityHandler = useCallback(
    async (communityName) => {
      try {
        setLoading(true);
        await dispatch(joinCommunityAndFetchData(communityName, userData));
      } finally {
        setLoading(false);
        handleCloseJoinModal();
      }
    },
    [dispatch, userData, handleCloseJoinModal]
  );

  useEffect(() => {
    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseJoinModal}
      fullWidth
      aria-describedby="alert-dialog-slide-description"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={1}
          >
            <IoIosPeople color="#4fa3a5" />
            <Typography variant="caption2" color="primary">
              {community.name}
            </Typography>
          </Stack>
          <Typography color="#999">
            {community.members.length} members
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Are you sure you want to join this group? You can always leave later.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseJoinModal} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={() => joinCommunityHandler(community.name)}
          disabled={loading}
          variant="contained"
          color="primary"
          disableElevation
          startIcon={
            loading ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          Join
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JoinModal;
