import React, { useState } from "react";

import { useDispatch } from "react-redux";
import {
  confirmPostAction,
  rejectPostAction,
} from "../../redux/actions/postActions";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Stack,
  Slide,
  Typography,
} from "@mui/material";
import Transition from "../Transition";

const EligibilityDetectionFailModal = ({
  closeEligibilityDetectionFailModal,
  showEligibilityDetectionFailModal,
  confirmationToken,
}) => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDiscard = async () => {
    setIsProcessing(true);
    await dispatch(rejectPostAction(confirmationToken));
    setIsProcessing(false);
    closeEligibilityDetectionFailModal();
  };

  const handleProcess = async () => {
    setIsProcessing(true);
    await dispatch(confirmPostAction(confirmationToken));
    setIsProcessing(false);
    closeEligibilityDetectionFailModal();
  };

  // const modalClass = showEligibilityDetectionFailModal
  //   ? "fixed inset-0 z-50 flex items-center justify-center"
  //   : "hidden";

  return (
    <>
      <Dialog
        open={showEligibilityDetectionFailModal}
        onClose={handleDiscard}
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={Transition}
        keepMounted
        justifyContent={"center"}
      >
        <DialogTitle>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
            >
              <Typography variant="subtitle2" fontSize={20}>
                Can not determine post eligibility
              </Typography>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={1}
              >
                <Typography variant="body">
                  We regret to inform you that our system was unable to
                  ascertain the eligibility of your post for this community.
                  However, you may still proceed with posting it if you believe
                  it to be relevant. Please be aware that community moderators
                  reserve the right to remove posts that do not adhere to
                  guidelines, which could potentially lead to a ban. Thank you
                  for your understanding.
                </Typography>
              </Stack>
            </Stack>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
            >
              <Button
                variant="outlined"
                onClick={handleDiscard}
                disabled={isProcessing}
              >
                Discard
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={isProcessing}
                onClick={handleProcess}
                sx={{
                  // '&:hover': {
                  //   backgroundColor: '#1976d2',
                  // },
                  // '&:focus': {
                  //   outline: 'none',
                  //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                  // },
                  ...(isProcessing && {
                    cursor: "not-allowed",
                    opacity: 0.5,
                  }),
                }}
              >
                {isProcessing ? "Posting..." : "Post Anyway"}
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EligibilityDetectionFailModal;
