import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Stack,
} from "@mui/material";
import { CallElement } from "../../components/CallElement";
import { all_members } from "../../data";
import { useDispatch } from "react-redux";
import { FetchAllUsers } from "../../redux/slices/app";
import { faker } from "@faker-js/faker";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../components/Search";
import { MagnifyingGlass } from "phosphor-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StartCall = ({ open, handleClose }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchAllUsers());
  }, [dispatch]);

  // console.log(CallLog, all_members, "Call List Info");

  const list = all_members.map((el) => ({
    id: el?.id,
    name: `${el?.name}`,
    image: faker.image.avatar(),
  }));

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{ p: 4 }}
    >
      <Stack
        spacing={3}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? "#F8FAFF"
              : theme.palette.background.paper,
        }}
      >
        <DialogTitle>{"Make A New Call"}</DialogTitle>

        <DialogContent>
          <Stack sx={{ width: "100%" }}>
            <Search>
              <SearchIconWrapper>
                <MagnifyingGlass color="#4fa3a5" />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Stack>
          <Stack sx={{ height: "100%", mt: 2 }}>
            <Stack spacing={2.4}>
              {list.map((el, id) => {
                return (
                  <CallElement key={id} {...el} handleClose={handleClose} />
                );
              })}
            </Stack>
          </Stack>
        </DialogContent>
      </Stack>
    </Dialog>
  );
};

export default StartCall;
