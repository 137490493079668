import React, { useState } from "react";
import { Dialog, DialogContent, Stack, Tab, Tabs } from "@mui/material";

import Follow from "../../pages/dashboard/Follow";
import FriendsList from "./FriendsList";
import UsersList from "./UsersList";
import Transition from "../../components/Transition";

const Friends = ({ open, handleClose }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{ p: 4 }}
    >
      <Stack p={2} sx={{ width: "100%" }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Explore" />
          <Tab label="Friends" />
          <Tab label="Requests" />
        </Tabs>
      </Stack>
      <DialogContent>
        <Stack sx={{ height: "100%" }}>
          <Stack spacing={2.5}>
            {(() => {
              switch (value) {
                // case 0: // Display all users
                //   return <UsersList />;
                case 1: // Display friends
                  return <FriendsList />;
                case 2: // Display requests
                  return <Follow />;
                default:
                  return null;
              }
            })()}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default Friends;
