import React, { useMemo } from "react";
import { useNavigate, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Avatar, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const PostOnProfile = ({ post }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state) => state.user?.user);

  const theme = useTheme();

  const { content, fileUrl, community, createdAt, comments, likes, isMember } =
    post;

  const fullName = `${user.firstName} ${user.lastName}`;

  const isImageFile = useMemo(() => {
    const validExtensions = [".jpg", ".png", ".jpeg", ".gif", ".webp", ".svg"];
    const fileExtension = fileUrl?.slice(fileUrl.lastIndexOf("."));
    return validExtensions.includes(fileExtension);
  }, [fileUrl]);

  return (
    <>
      <Stack spacing={3} height={"100%"} width="100%" sx={{ mb: 1.2 }}>
        <Stack
          p={3}
          sx={{
            backgroundColor:
              theme.palette.mode === "light"
                ? "#f8faff "
                : theme.palette.background.paper,
            boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
            borderRadius: 1,
          }}
          onClick={() => {
            if (isMember) {
              navigate(`/my/post/${post._id}`, {
                state: { from: location.pathname },
              });
            }
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Avatar src={user.avatar} alt={user.name} loading="lazy" />
            <Stack
              direction={"row"}
              spacing={0.3}
              sx={{ justifyContent: "space-between", width: "100%" }}
            >
              <Stack>
                <Typography
                  variant="subtitle2"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  {user._id ? (
                    <Link
                      style={{
                        textDecoration: "none",
                        color:
                          theme.palette.mode === "light"
                            ? "#000"
                            : theme.palette.primary.main,
                      }}
                      to="/profile"
                    >
                      {fullName}
                    </Link>
                  ) : (
                    <Link
                      style={{
                        textDecoration: "none",
                        color:
                          theme.palette.mode === "light"
                            ? "#000"
                            : theme.palette.primary.main,
                      }}
                      to={`/user/${user._id}`}
                    >
                      {fullName}
                    </Link>
                  )}
                </Typography>
                <Stack>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/community/${community.name}`}
                  >
                    <Typography
                      variant="caption"
                      fontSize={"9px"}
                      color={"#999"}
                    >
                      {community.name}
                    </Typography>
                  </Link>
                </Stack>
              </Stack>
              <Typography variant="caption" fontSize={"9px"} color={"#999"}>
                {createdAt}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Stack sx={{ pt: 2, cursor: "pointer" }} spacing={1}>
              {content && <p>{content}</p>}
              {fileUrl && isImageFile ? (
                <Stack>
                  <img
                    className="imagePost"
                    src={fileUrl}
                    alt={content}
                    loading="lazy"
                  />
                </Stack>
              ) : (
                fileUrl && (
                  <Stack>
                    <video className="videoPost" src={fileUrl} controls />
                  </Stack>
                )
              )}
              <Stack>
                <Stack direction={"row"} spacing={1}>
                  <Stack>
                    {comments.length}{" "}
                    {comments.length === 1 ? "Comment" : "Comments"}
                  </Stack>
                  <Stack>
                    {likes.length} {likes.length === 1 ? "Like" : "Likes"}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default PostOnProfile;
