import { useSelector, useDispatch } from "react-redux";
import { useEffect, useMemo } from "react";
import { getOwnPostAction } from "../../redux/actions/postActions";
import { useNavigate, useParams } from "react-router-dom";
import CommonLoading from "../../components/loader/CommonLoading";

import PostView from "../../components/PostElement/PostView";
import { Box, Container, Stack } from "@mui/material";
// import CommentSidebar from "../../components/post/CommentSidebar";

const OwnPost = () => {
  const { postId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.auth?.userData);

  const joinedCommunities = useSelector((state) =>
    state.community?.joinedCommunities?.map(({ _id }) => _id)
  );

  useEffect(() => {
    dispatch(getOwnPostAction(postId));
  }, [dispatch, postId]);

  const { ownPost: post } = useSelector((state) => state.posts);

  const isAuthorized = useMemo(() => {
    return post && joinedCommunities?.includes(post.community._id);
  }, [post, joinedCommunities]);

  useEffect(() => {
    if (isAuthorized === false) {
      navigate("/access-denied");
    }
  }, [isAuthorized, navigate]);

  if (!post || !joinedCommunities)
    return (
      <Stack>
        <CommonLoading />
      </Stack>
    );

  return (
    <>
      <Stack height={"100%"} maxHeight={"100vh"} width="100%">
        <Box
          width={"100%"}
          sx={{ flexGrow: 1, height: "100%", overflowY: "scroll" }}
        >
          <Container sx={{ mt: 5 }} maxWidth="sm">
            <PostView post={post} userData={userData} />
            {/* <CommentSidebar comments={post.comments} /> */}
          </Container>
        </Box>
      </Stack>
    </>
  );
};

export default OwnPost;
