import React, { useState, memo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deletePostAction } from "../../redux/actions/postActions";
import LoadingSpinner from "../loader/ButtonLoadingSpinner";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  // CircularProgress,
  Slide,
  Stack,
  Typography,
} from "@mui/material";

import Transition from "../Transition";

const DeleteModal = memo(({ showModal, postId, onClose, prevPath }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const deleteHandler = async () => {
    setLoading(true);
    await dispatch(deletePostAction(postId));
    navigate(prevPath ? prevPath : "/");
    setLoading(false);
    onClose();
  };

  const cancelButtonRef = useRef(null);

  return (
    <>
      <Dialog
        open={showModal}
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={Transition}
        keepMounted
        justifyContent={"center"}
      >
        <DialogTitle>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
            >
              <Typography variant="caption2" color="primary">
                Delete Post
              </Typography>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={1}
                textAlign={"center"}
              >
                Are you sure you want to delete? <br /> This action cannot be
                undone.
              </Stack>
            </Stack>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
            >
              <Button
                variant="outlined"
                disableElevation
                onClick={() => {
                  onClose();
                }}
                ref={cancelButtonRef}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={deleteHandler}
                disabled={loading}
              >
                {loading ? (
                  <LoadingSpinner loadingText="Deleting..." />
                ) : (
                  "Delete"
                )}
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default DeleteModal;
