import React from "react";
import { Dialog, Slide, DialogContent, DialogTitle } from "@mui/material";

// import {
//   getComPostsAction,
//   clearCommunityPostsAction,
// } from "../../redux/actions/postActions";
// import { useSelector } from "react-redux";

// import InputBar from "../PostElement/InputBar";
// import PostForm from "../form/PostForm";
import GeneralPostForm from "../form/generalPostForm";
import Transition from "../Transition";

const PostDialog = ({ open, handleClose }) => {
  // const generalPosts = useSelector((state) => state.posts?.generalPosts);
  // const postError = useSelector((state) => state.posts?.postError);

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ mb: 2 }}>Create Post</DialogTitle>
        <DialogContent>
          <GeneralPostForm
          // postId={generalPosts._id}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PostDialog;
