import * as types from "../constants/conversationConstants";

const initialState = {
  conversations: [],
  conversationError: null,
};

const conversationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        conversations: payload,
        conversationError: null,
      };

    case types.CREATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversations: [...state.conversations, payload],
        conversationError: null,
      };

    case types.UPDATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversations: state.conversations.map((conversation) =>
          conversation._id === payload._id ? payload : conversation
        ),
        conversationError: null,
      };

    case types.DELETE_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversations: state.conversations.filter(
          (conversation) => conversation._id !== payload._id
        ),
        conversationError: null,
      };

    case types.GET_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversations: [payload],
        conversationError: null,
      };

    case types.GET_CONVERSATIONS_FAIL:
    case types.CREATE_CONVERSATION_FAIL:
    case types.UPDATE_CONVERSATION_FAIL:
    case types.DELETE_CONVERSATION_FAIL:
    case types.GET_CONVERSATION_FAIL:
      return {
        ...state,
        conversationError: payload,
      };

    default:
      return state;
  }
};

export default conversationReducer;
