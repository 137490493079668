import { faker } from "@faker-js/faker";
import {
  ChatCircleDots,
  Gear,
  GearSix,
  Heart,
  Phone,
  // Plus,
  SignOut,
  // User,
  Users,
} from "phosphor-react";

const Profile_Menu = [
  {
    index: 6,
    title: "Settings",
    icon: <Gear />,
  },
  {
    index: 1,
    title: "Sign Out",
    icon: <SignOut />,
  },
];

const Nav_Buttons = [
  // {
  //   index: 1,
  //   icon: <ChatCircleDots />,
  //   caption: "Chat",
  //   // isNotification: true,
  // },
  {
    index: 2,
    icon: <Users />,
    caption: "Groups",
  },
  // {
  //   index: 3,
  //   icon: <Phone />,
  //   caption: "Calls",
  // },
  {
    index: 4,
    icon: <Heart />,
    caption: "Notifications",
    isNotification: true,
  },
  // {
  //   index: 5,
  //   icon: <Plus />,
  //   caption: "Create Post",
  // },
];

const Nav_Setting = [
  {
    index: 5,
    icon: <GearSix />,
  },
];

const ChatList = [
  {
    id: 0,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    msg: faker.music.songName(),
    time: "9:36",
    unread: 0,
    pinned: true,
    online: true,
  },
  {
    id: 1,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    msg: faker.music.songName(),
    time: "12:02",
    unread: 2,
    pinned: true,
    online: false,
  },
  {
    id: 2,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    msg: faker.music.songName(),
    time: "10:35",
    unread: 3,
    pinned: false,
    online: true,
  },
  {
    id: 3,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    msg: faker.music.songName(),
    time: "04:00",
    unread: 0,
    pinned: false,
    online: true,
  },
  {
    id: 4,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    msg: faker.music.songName(),
    time: "08:42",
    unread: 0,
    pinned: false,
    online: false,
  },
  {
    id: 5,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    msg: faker.music.songName(),
    time: "08:42",
    unread: 0,
    pinned: false,
    online: false,
  },
  {
    id: 6,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    msg: faker.music.songName(),
    time: "08:42",
    unread: 0,
    pinned: false,
    online: false,
  },
  {
    id: 7,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    msg: faker.music.songName(),
    time: "08:42",
    unread: 0,
    pinned: false,
    online: false,
  },
];

const Chat_History = [
  {
    type: "msg",
    message: "Hi 👋🏻, How are ya ?",
    incoming: true,
    outgoing: false,
  },
  {
    type: "divider",
    text: "Today",
  },
  {
    type: "msg",
    message: "Hi 👋 Panda, not bad, u ?",
    incoming: false,
    outgoing: true,
  },
  {
    type: "msg",
    message: "Can you send me an abstarct image?",
    incoming: false,
    outgoing: true,
  },
  {
    type: "msg",
    message: "Ya sure, sending you a pic",
    incoming: true,
    outgoing: false,
  },

  {
    type: "msg",
    subtype: "img",
    message: "Here You Go",
    img: faker.image.abstract(),
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    message: "Can you please send this in file format?",
    incoming: false,
    outgoing: true,
  },

  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "link",
    preview: faker.image.cats(),
    message: "Yep, I can also do that",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "reply",
    reply: "This is a reply",
    message: "Yep, I can also do that",
    incoming: false,
    outgoing: true,
  },
];

const Message_options = [
  {
    title: "Reply",
  },
  {
    title: "React to message",
  },
  {
    title: "Forward message",
  },
  {
    title: "Star message",
  },
  {
    title: "Report",
  },
  {
    title: "Delete Message",
  },
];

const Shared_docs = [
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
];

const Shared_links = [
  {
    type: "msg",
    subtype: "link",
    preview: faker.image.cats(),
    message: "Yep, I can also do that",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "link",
    preview: faker.image.cats(),
    message: "Yep, I can also do that",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "link",
    preview: faker.image.cats(),
    message: "Yep, I can also do that",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "link",
    preview: faker.image.cats(),
    message: "Yep, I can also do that",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "link",
    preview: faker.image.cats(),
    message: "Yep, I can also do that",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "link",
    preview: faker.image.cats(),
    message: "Yep, I can also do that",
    incoming: true,
    outgoing: false,
  },
];

const CallLog = [
  {
    id: 0,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    missed: false,
    incoming: true,
    online: false,
    type: "video",
  },
  {
    id: 1,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    missed: true,
    incoming: false,
    online: true,
    type: "audio",
  },
  {
    id: 2,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    missed: false,
    incoming: false,
    online: false,
    type: "audio",
  },
  {
    id: 3,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    missed: false,
    incoming: true,
    online: false,
    type: "video",
  },
  {
    id: 4,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    missed: true,
    incoming: true,
    online: true,
    type: "audio",
  },
  {
    id: 5,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    missed: true,
    incoming: true,
    online: true,
    type: "video",
  },
  {
    id: 6,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
    missed: true,
    incoming: true,
    online: true,
    type: "audio",
  },
];

const all_members = [
  {
    id: 0,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
  },
  {
    id: 1,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
  },
  {
    id: 2,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
  },
  {
    id: 3,
    img: faker.image.avatar(),
    name: faker.person.firstName(),
  },
];

const posts = [
  {
    id: 0,
    img: faker.image.avatar(),
    name: faker.person.fullName(),
    userName: faker.person.zodiacSign(),
    post: faker.lorem.paragraph(),
    postImage: "",
    likes: 320,
    comments: 3,
    time: "9:36",
  },
  {
    id: 1,
    img: faker.image.avatar(),
    name: faker.person.fullName(),
    userName: faker.person.zodiacSign(),
    post: faker.lorem.paragraph(),
    postImage: faker.image.avatar(),
    likes: 20,
    comments: 1,
    time: "9:36",
  },
  {
    id: 2,
    img: faker.image.avatar(),
    name: faker.person.fullName(),
    userName: faker.person.zodiacSign(),
    post: faker.lorem.paragraph(),
    postImage: "",
    likes: 525,
    comments: 13,
    time: "9:36",
  },
  {
    id: 3,
    img: faker.image.avatar(),
    name: faker.person.fullName(),
    userName: faker.person.zodiacSign(),
    post: faker.lorem.paragraph(),
    postImage: "",
    likes: 90,
    comments: 7,
    time: "9:36",
  },
  {
    id: 4,
    img: faker.image.avatar(),
    name: faker.person.fullName(),
    userName: faker.person.zodiacSign(),
    post: "",
    postImage: faker.image.avatar(),
    likes: 0,
    comments: 0,
    time: "9:36",
  },
  {
    id: 5,
    img: faker.image.avatar(),
    name: faker.person.fullName(),
    userName: faker.person.zodiacSign(),
    post: faker.lorem.paragraph(),
    postImage: faker.image.avatar(),
    likes: 740,
    comments: 6,
    time: "9:36",
  },
  {
    id: 6,
    img: faker.image.avatar(),
    name: faker.person.fullName(),
    userName: faker.person.zodiacSign(),
    post: faker.lorem.paragraph(),
    postImage: faker.image.avatar(),
    likes: 0,
    comments: 12,
    time: "9:36",
  },
];

const notifications = [
  {
    id: 0,
    img: faker.image.avatar(),
    name: faker.person.fullName(),
    userName: faker.person.zodiacSign(),
    time: "9:36",
    type: "comment",
  },
  {
    id: 1,
    img: faker.image.avatar(),
    name: faker.person.fullName(),
    userName: faker.person.zodiacSign(),
    time: "9:36",
    type: "comment",
  },
  {
    id: 2,
    img: faker.image.avatar(),
    name: faker.person.fullName(),
    userName: faker.person.zodiacSign(),
    time: "9:36",
    type: "like",
  },
  {
    id: 3,
    img: faker.image.avatar(),
    name: faker.person.fullName(),
    userName: faker.person.zodiacSign(),
    time: "9:36",
    type: "like",
  },
  {
    id: 4,
    img: faker.image.avatar(),
    name: faker.person.fullName(),
    userName: faker.person.zodiacSign(),
    time: "9:36",
    type: "comment",
  },
  {
    id: 5,
    img: faker.image.avatar(),
    name: faker.person.fullName(),
    userName: faker.person.zodiacSign(),
    time: "9:36",
    type: "like",
  },
];

export {
  Profile_Menu,
  Nav_Setting,
  Nav_Buttons,
  ChatList,
  Chat_History,
  Message_options,
  Shared_links,
  Shared_docs,
  CallLog,
  all_members,
  posts,
  notifications,
};
