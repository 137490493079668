import React, { useState } from "react";
import { Button, Stack, Avatar, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  getUserAction,
  updateUserAvatarAction,
} from "../../redux/actions/userActions";
import { useDispatch } from "react-redux";
import useResponsive from "../../hooks/useResponsive";

const UpdateUserAvatar = ({ user }) => {
  const dispatch = useDispatch();
  // const theme = useTheme();
  // const isDesktop = useResponsive("up", "md");

  const [isUpdating, setIsUpdating] = useState(false);

  const [avatar, setAvatar] = useState(null);
  const [avatarError, setAvatarError] = useState(null);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setAvatar(null);
      setAvatarError(null);
      return;
    }
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/jpg"
    ) {
      setAvatar(null);
      setAvatarError("Please upload a valid image file (jpeg, jpg, png)");
    } else if (file.size > 10 * 1024 * 1024) {
      setAvatar(null);
      setAvatarError("Please upload an image file less than 10MB");
    } else {
      setAvatar(file);
      setAvatarError(null);
    }
  };

  const handleUpdateAvatar = async () => {
    setIsUpdating(true);

    if (!avatar) {
      setIsUpdating(false);
      // Handle case where no avatar is uploaded
      console.log("No avatar selected for upload.");
      return; // No avatar to update
    }

    try {
      const formData = new FormData();
      formData.append("avatar", avatar);

      await dispatch(updateUserAvatarAction(user._id, formData));
      await dispatch(getUserAction(user._id));

      setAvatar(null);
      setAvatarError(null);
    } catch (error) {
      console.error("Error updating avatar:", error);
    }

    setIsUpdating(false);
  };
  return (
    <>
      <Stack mb={1} justifyContent={"center"} alignItems={"center"} spacing={2}>
        <Avatar
          alt={user?.name || "User Avatar"}
          src={user.avatar}
          sx={{ width: 240, height: 240 }}
        />
        <input
          name="avatar"
          accept="image/*"
          id="avatar"
          type="file"
          style={{ display: "none" }}
          onChange={handleAvatarChange}
        />
        {avatar && (
          <Stack>
            <Typography>{avatar.name}</Typography>
          </Stack>
        )}
        {avatarError && (
          <Stack>
            <Typography color={"error"}>{avatarError}</Typography>
          </Stack>
        )}
        <Stack direction={"row"} spacing={1}>
          <label htmlFor="avatar">
            <Button variant="outlined" component="span">
              Upload Avatar
            </Button>
          </label>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleUpdateAvatar}
          >
            {isUpdating ? "Updating..." : "Update Avatar"}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default UpdateUserAvatar;
