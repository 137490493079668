import React from "react";
import Chats from "./Chats";
import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Conversation from "../../components/Conversation";
import Contact from "../../components/Contact";
import { useSelector } from "react-redux";
import SharedMessages from "../../components/SharedMessages";
import StarredMessages from "../../components/StarredMessages";
import NoChatSVG from "../../assets/Illustration/NoChat";

const Messenger = () => {
  const theme = useTheme();
  const { sideBar, chat_type, room_id } = useSelector((store) => store.app);

  return (
    <>
      <Stack
        direction={"row"}
        sx={{ width: "calc(100vw - 320px)", paddingLeft: "100px " }}
      >
        {/* chats */}
        <Chats />

        <Box
          sx={{
            height: "100%",
            width: sideBar.open ? "calc(100vw - 740px)" : "calc(100vw - 320px)",

            backgroundColor:
              theme.palette.mode === "light"
                ? "#f0f4fa "
                : theme.palette.background.default,
            // border: "solid red 2px",
          }}
        >
          {/* conversations */}
          {room_id !== null && chat_type === "individual" ? (
            <Conversation />
          ) : (
            <Stack
              spacing={2}
              sx={{ height: "100%", width: "100%" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <NoChatSVG />
              <Typography variant="subtitle2">Start a conversation</Typography>
            </Stack>
          )}
        </Box>

        {/* contact information */}
        {sideBar.open &&
          (() => {
            switch (sideBar.type) {
              case "CONTACT":
                return <Contact />;

              case "STARRED":
                return <StarredMessages />;

              case "SHARED":
                return <SharedMessages />;

              default:
                break;
            }
          })()}
      </Stack>
    </>
  );
};

export default Messenger;
