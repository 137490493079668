import React from "react";
import { Stack, Avatar, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CiLocationOn } from "react-icons/ci";
import { FiUserPlus, FiUserMinus } from "react-icons/fi";
import useResponsive from "../../hooks/useResponsive";
import UserStatsCard from "./UserStatsCard";

const PublicProfileHeader = ({
  avatar,
  name,
  firstName,
  lastName,
  userLocation,
  role,
  bio,
  isFollowing,
  isModerator,
  handleUnfollow,
  handleFollow,
  followLoading,
  unfollowLoading,
  publicUserId,
  followers,
  following,
  totalCommunities,
  // totalPosts,
}) => {
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");

  const fullName = `${firstName} ${lastName}`;

  const CustomButton = ({ loading, onClick, text, icon, color }) => (
    <Button
      variant="outlined"
      onClick={onClick}
      disabled={loading}
      color={color}
    >
      {loading ? (
        <Typography>Wait</Typography>
      ) : (
        <Typography>
          {icon} {text}
        </Typography>
      )}
    </Button>
  );

  const FollowButton = ({ loading, onClick }) => (
    <CustomButton
      loading={loading}
      onClick={onClick}
      text="Follow"
      icon={<FiUserPlus />}
      color="primary"
    />
  );

  const UnfollowButton = ({ loading, onClick }) => (
    <CustomButton
      loading={loading}
      onClick={onClick}
      text="Unfollow"
      icon={<FiUserMinus />}
      color="error"
    />
  );

  return (
    <Stack
      direction={isDesktop ? "row" : "column"}
      spacing={isDesktop ? 10 : 2}
      justifyContent="center"
      alignItems="center"
    >
      <Stack spacing={1}>
        <Avatar
          sx={{
            width: isDesktop ? 240 : 160,
            height: isDesktop ? 240 : 160,
          }}
          src={avatar}
          alt="Profile"
          loading="lazy"
        />
      </Stack>

      <Stack direction="column">
        <Stack
          spacing={0.3}
          justifyContent={isDesktop ? "flex-start" : "center"}
          alignItems={isDesktop ? "flex-start" : "center"}
        >
          <Typography variant="h3">{fullName}</Typography>
          <Typography>
            <CiLocationOn />
            {userLocation || "N/A"}
          </Typography>

          {role === "moderator" && <Typography>Moderator</Typography>}

          <UserStatsCard
            followers={followers}
            following={following}
            totalCommunities={totalCommunities}
            // totalPosts={totalPosts}
          />

          <Stack mt={2}>
            {isFollowing ? (
              <UnfollowButton
                loading={unfollowLoading}
                onClick={() => handleUnfollow(publicUserId)}
              />
            ) : (
              <FollowButton
                loading={followLoading}
                onClick={() => handleFollow(publicUserId)}
              />
            )}
          </Stack>

          <Typography fontSize="regular" sx={{ pt: 2 }}>
            {bio || "This user has not updated their bio yet."}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PublicProfileHeader;
