import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
// import { v4 } from "uuid";

// Define the initial state for the Redux slice
const initialState = {
  user: {},
  sideBar: {
    open: false,
    type: "CONTACT", // Sidebar type (CONTACT, STARRED, SHARED)
  },
  isLoggedIn: true,
  tab: 0, // [0, 1, 2, 3] Active tab index
  snackbar: {
    open: null,
    severity: null,
    message: null,
  },
  users: [], // All users of the app who are not friends and not requested yet
  all_users: [], // All verified users
  friends: [], // All friends
  friendRequests: [], // All friend requests
  chat_type: null, // Type of chat (individual or group)
  room_id: null, // ID of the chat room
  call_logs: [], // Call logs
  userPosts: [],
};

// Create a Redux slice named 'app' with initial state
const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    // Reducer to update call logs
    fetchCallLogs(state, action) {
      state.call_logs = action.payload.call_logs;
    },
    // Reducer to fetch and update user data
    fetchUser(state, action) {
      state.user = action.payload.user;
    },
    // Reducer to update user data
    updateUser(state, action) {
      state.user = action.payload.user;
    },
    // Reducer to toggle the sidebar
    toggleSideBar(state) {
      state.sideBar.open = !state.sideBar.open;
    },
    // Reducer to update the type of the sidebar
    updateSideBarType(state, action) {
      state.sideBar.type = action.payload.type;
    },
    // Reducer to update the active tab index
    updateTab(state, action) {
      state.tab = action.payload.tab;
    },

    // Reducer to open a snackbar with severity and message
    openSnackBar(state, action) {
      console.log(action.payload);
      state.snackbar.open = true;
      state.snackbar.severity = action.payload.severity;
      state.snackbar.message = action.payload.message;
    },

    // Reducer to close the snackbar
    closeSnackBar(state) {
      console.log("This is getting executed");
      state.snackbar.open = false;
      state.snackbar.message = null;
    },

    // Reducer to update the list of users
    updateUsers(state, action) {
      state.users = action.payload.users;
    },

    // Reducer to update the list of all verified users
    updateAllUsers(state, action) {
      state.all_users = action.payload.users;
    },

    // Reducer to update the list of friends
    updateFriends(state, action) {
      state.friends = action.payload.friends;
    },

    // Reducer to update the list of friend requests
    updateFriendRequests(state, action) {
      state.friendRequests = action.payload.requests;
    },

    // Reducer to select an individual conversation
    selectConversation(state, action) {
      state.chat_type = "individual";
      state.room_id = action.payload.room_id;
    },
    fetchUserPosts(state, action) {
      state.userPosts = action.payload.user;
    },
  },
});

// Export the reducer as the default export of the module
export default slice.reducer;

// Action creator to close the snackbar
export const closeSnackBar = () => async (dispatch, getState) => {
  dispatch(slice.actions.closeSnackBar());
};

// Action creator to show a snackbar with severity and message
export const showSnackbar =
  ({ severity, message }) =>
  async (dispatch, getState) => {
    dispatch(
      slice.actions.openSnackBar({
        message,
        severity,
      })
    );

    setTimeout(() => {
      dispatch(slice.actions.closeSnackBar());
    }, 4000);
  };

// Action creators for toggling the sidebar, updating sidebar type, and updating the active tab
export function ToggleSidebar() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.toggleSideBar());
  };
}
export function UpdateSidebarType(type) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateSideBarType({ type }));
  };
}
export function UpdateTab(tab) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateTab(tab));
  };
}

// Action creators for fetching lists of users, all users, friends, and friend requests
export function FetchUsers() {
  return async (dispatch, getState) => {
    // Make an API request to fetch users and update the state
    await axios
      .get(
        "/api/user/users",

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        dispatch(slice.actions.updateUsers({ users: response.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function FetchAllUsers() {
  return async (dispatch, getState) => {
    // Make an API request to fetch all verified users and update the state
    await axios
      .get("/api/user/get-all-verified-users", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        console.log(response);
        dispatch(slice.actions.updateAllUsers({ users: response.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function FetchFriends() {
  return async (dispatch, getState) => {
    // Make an API request to fetch friends and update the state
    await axios
      .get(
        "/api/user/get-friends",

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        dispatch(slice.actions.updateFriends({ friends: response.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function FetchFriendRequests() {
  return async (dispatch, getState) => {
    // Make an API request to fetch friend requests and update the state
    await axios
      .get(
        "/api/user/get-requests",

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        dispatch(
          slice.actions.updateFriendRequests({ requests: response.data.data })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

// Action creator to select an individual conversation
export const SelectConversation = ({ room_id }) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.selectConversation({ room_id }));
  };
};

// Action creator to fetch call logs
export const FetchCallLogs = () => {
  return async (dispatch, getState) => {
    // Make an API request to fetch call logs and update the state
    axios
      .get("/api/user/get-call-logs", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        console.log(response);
        dispatch(
          slice.actions.fetchCallLogs({ call_logs: response.data.data })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Action creator to fetch user profile data
export const FetchUserProfile = () => {
  return async (dispatch, getState) => {
    // Make an API request to fetch user profile data and update the state
    axios
      .get("/api/user/profile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        console.log(response);
        dispatch(slice.actions.fetchUser({ user: response.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export function FetchUserPosts(userId) {
  return async (dispatch, getState) => {
    try {
      // Make an API request to fetch user posts and update the state
      const response = await axios.get(`/api/user/${userId}/posts`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });

      // Dispatch an action to update the user posts in the state
      dispatch(
        slice.actions.fetchUserPosts({ userId, posts: response.data.data })
      );
    } catch (error) {
      console.error(error);
      // Handle the error, e.g., dispatch an error action
      //dispatch(slice.actions.userPostsError(error.message));
    }
  };
}
