import { useMemo, useEffect, useState } from "react";
import {
  getNotJoinedCommunitiesAction,
  getJoinedCommunitiesAction,
} from "../../redux/actions/communityActions";

import { useDispatch, useSelector } from "react-redux";
import CommonLoading from "../../components/loader/CommonLoading";
import CommunityCard from "../../components/community/CommunityCard";
import { Box, Container, Stack, Tab, Tabs, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import JoinedCommunityCard from "../../components/community/JoinedCommunityCard";
import { PiUsersThreeThin } from "react-icons/pi";
import { PiUsersFourLight } from "react-icons/pi";
import useResponsive from "../../hooks/useResponsive";

const AllCommunities = ({ initialTab }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const isDesktop = useResponsive("up", "md");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeTab, setActiveTab] = useState(initialTab || "my groups");

  const joinedCommunities = useSelector(
    (state) => state.community?.joinedCommunities
  );

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getJoinedCommunitiesAction());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const communityCards = useMemo(() => {
    if (!joinedCommunities) {
      return null;
    }
    return joinedCommunities.map((community) => (
      <JoinedCommunityCard key={community._id} community={community} />
    ));
  }, [joinedCommunities]);

  const notJoinedCommunities = useSelector(
    (state) => state.community?.notJoinedCommunities
  );

  useEffect(() => {
    dispatch(getNotJoinedCommunitiesAction());
    setLoading(false);
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (loading) {
    return (
      <>
        <CommonLoading />
      </>
    );
  }

  return (
    <Stack height={"100vh"} maxHeight={"100vh"} width="100%">
      <Box
        width={"100%"}
        sx={{
          flexGrow: 1,
          height: "100%",
          overflowY: "scroll",
          backgroundColor:
            theme.palette.mode === "light"
              ? "#f0f4fa "
              : theme.palette.background.default,
        }}
      >
        <Container sx={{ mt: isMobile ? 10 : 5 }} maxWidth="md">
          <Tabs
            value={activeTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable auto tabs example"
            variant="scrollable"
            scrollButtons="auto"
            sx={{ mb: 2 }}
          >
            <Tab
              label="my groups"
              value="my groups"
              icon={<PiUsersThreeThin />}
              iconPosition="start"
            />
            <Tab
              label="explore groups"
              value="explore groups"
              icon={<PiUsersFourLight />}
              iconPosition="start"
            />
          </Tabs>
          <Stack
            spacing={0}
            height={"100%"}
            width="100%"
            sx={{
              mb: 5,
              display: "grid",
              gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr",
              gap: 1.2,
              alignItems: "center",

              padding: "4px",
              "&.md": {
                gridTemplateColumns: "1fr",
              },
            }}
          >
            {activeTab === "my groups" && <>{communityCards}</>}
            {activeTab === "explore groups" && (
              <>
                {notJoinedCommunities?.map((community) => (
                  <CommunityCard key={community._id} community={community} />
                ))}
              </>
            )}
          </Stack>
        </Container>
      </Box>
    </Stack>
  );
};

export default AllCommunities;
