import React from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { ToggleSidebar, UpdateSidebarType } from "../../redux/slices/app";
import { CaretLeft, X } from "phosphor-react";

import Message from "../Conversation/Message";
import useResponsive from "../../hooks/useResponsive";

const StarredMessages = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDesktop = useResponsive("up", "md");

  return (
    <>
      <Box sx={{ width: !isDesktop ? "100vw" : 320, maxHeight: "100vh" }}>
        <Stack sx={{ height: "100%" }}>
          {/* header */}
          <Box
            sx={{
              boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
              width: "100%",
              backgroundColor:
                theme.palette.mode === "light"
                  ? "#f8faff"
                  : theme.palette.background.paper,
            }}
          >
            <Stack
              direction={"row"}
              sx={{ height: "100%", p: 2 }}
              alignItems={"center"}
              spacing={3}
              justifyContent={"space-between"}
            >
              <IconButton
                onClick={() => {
                  dispatch(UpdateSidebarType("CONTACT"));
                }}
              >
                <CaretLeft />
              </IconButton>
              <Typography variant="subtitle2">Starred Messages</Typography>
              <IconButton
                onClick={() => {
                  dispatch(ToggleSidebar());
                }}
              >
                <X />
              </IconButton>
            </Stack>
          </Box>

          {/* body */}
          <Stack
            sx={{
              height: "100%",
              position: "relative",
              flexGrow: 1,
              overflowY: "scroll",
              backgroundColor:
                theme.palette.mode === "light"
                  ? "#f8faff"
                  : theme.palette.background.paper,
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            }}
            spacing={3}
            p={3}
          >
            <Message />
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default StarredMessages;
