export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";

export const MARK_NOTIFICATION_AS_READ_SUCCESS =
  "MARK_NOTIFICATION_AS_READ_SUCCESS";
export const MARK_NOTIFICATION_AS_READ_FAIL = "MARK_NOTIFICATION_AS_READ_FAIL";

export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL";

export const GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS = "GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS";
export const GET_UNREAD_NOTIFICATIONS_COUNT_FAIL = "GET_UNREAD_NOTIFICATIONS_COUNT_FAIL";
