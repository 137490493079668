import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModProfileAction } from "../../redux/actions/authActions";
import CommonLoading from "../loader/CommonLoading";
import { Avatar, Stack, Typography } from "@mui/material";

const ModeratorProfile = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getModProfileAction());
  }, [dispatch]);

  const moderator = useSelector((state) => state.moderation?.modProfile);
  if (!moderator)
    return (
      <>
        <CommonLoading />
      </>
    );

  return (
    <Stack p={1} spacing={1} alignItems={"center"}>
      <Avatar src={moderator.avatar} alt="user" />
      <Stack color={"text.secondary"} alignItems={"center"}>
        <Typography>{moderator.name}</Typography>
        <Typography fontSize={"small"}>{moderator.email}</Typography>
        <Typography fontSize={"small"}>
          Joined: {moderator.createdAt}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ModeratorProfile;
