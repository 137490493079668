import { Box, Stack } from "@mui/material";
import ModeratorProfile from "./ModeratorProfile";
import { useTheme } from "@mui/material/styles";
import useResponsive from "../../hooks/useResponsive";

const Rightbar = () => {
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");

  // If not desktop view, return null to prevent rendering
  if (!isDesktop) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          width: !isDesktop ? "100vw" : 320,
          height: "100vh",
          boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
          backgroundColor:
            theme.palette.mode === "light"
              ? "#f8faff"
              : theme.palette.background.paper,
        }}
      >
        <Stack
          //  sx={{ height: "100%" }}
          p={1.5}
          spacing={2}
        >
          <Box
            sx={{
              // boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
              width: "100%",
              backgroundColor:
                theme.palette.mode === "light"
                  ? "#f8faff"
                  : theme.palette.background.paper,
            }}
          >
            <ModeratorProfile />
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Rightbar;
