import React from "react";

import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import getPath from "./getPath";
import { ChatCircleDots, HouseSimple, Phone, Users } from "phosphor-react";

const BottomBar = ({ userData }) => {
  const theme = useTheme();
  const navigation = useNavigate();

  const [value, setValue] = React.useState("home");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: "auto",
          bottom: 0,
          backgroundColor:
            theme.palette.mode === "light"
              ? "#fff"
              : theme.palette.background.paper,
        }}
      >
        <Toolbar>
          <BottomNavigation
            sx={{
              width: 500,
              //   background: "transparent",
            }}
            value={value}
            onChange={handleChange}
          >
            <BottomNavigationAction
              label="Home"
              value="home"
              icon={
                <HouseSimple
                  style={{ width: 25, height: 25 }}
                  onClick={() => {
                    navigation(getPath(0));
                  }}
                />
              }
            />
            <BottomNavigationAction
              label="Groups"
              value="groups"
              icon={
                <Users
                  style={{ width: 25, height: 25 }}
                  onClick={() => {
                    navigation(getPath(2));
                  }}
                />
              }
            />
            {/* <BottomNavigationAction
              label="Chats"
              value="chats"
              icon={
                <ChatCircleDots
                  style={{ width: 25, height: 25 }}
                  onClick={() => {
                    navigation(getPath(1));
                  }}
                />
              }
            /> */}

            <BottomNavigationAction
              label="Profile"
              value="profile"
              icon={
                <Avatar
                  src={userData.avatar}
                  sx={{ width: 30, height: 30 }}
                  onClick={() => {
                    navigation(getPath(7));
                  }}
                />
              }
            />
          </BottomNavigation>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default BottomBar;
