import React, { useEffect } from "react";
import { Box, Stack } from "@mui/material";

import {
  DocumentMessage,
  LinkMessage,
  MediaMessage,
  ReplyMessage,
  TextMessage,
  Timeline,
} from "./MessageTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchCurrentMessages,
  SetCurrentConversation,
} from "../../redux/slices/conversation";
import { socket } from "../../socket";

const Message = ({ isMobile, menu }) => {
  const dispatch = useDispatch();

  const { conversations, current_messages } = useSelector(
    (state) => state.conversation.direct_chat
  );
  // const { room_id } = useSelector((state) => state.app);

  // useEffect(() => {
  //   const current = conversations.find((el) => el?.id === room_id);

  //   socket.emit("get_messages", { conversation_id: current?.id }, (data) => {
  //     // data => list of messages
  //     console.log(data, "List of messages");
  //     dispatch(FetchCurrentMessages({ messages: data }));
  //   });

  //   dispatch(SetCurrentConversation(current));
  // }, [dispatch]);

  return (
    <>
      <Box p={isMobile ? 1 : 3}>
        <Stack spacing={3}>
          {current_messages.map((el, idx) => {
            switch (el.type) {
              case "divider":
                return (
                  // Timeline
                  <Timeline el={el} />
                );

              case "msg":
                switch (el.subtype) {
                  case "img":
                    return (
                      //image
                      <MediaMessage el={el} menu={menu} />
                    );

                  case "doc":
                    return (
                      //document
                      <DocumentMessage el={el} menu={menu} />
                    );
                  case "Link":
                    return (
                      //link
                      <LinkMessage el={el} menu={menu} />
                    );

                  case "reply":
                    return (
                      //reply
                      <ReplyMessage el={el} menu={menu} />
                    );

                  default:
                    return (
                      // Text Message
                      <TextMessage el={el} menu={menu} />
                    );
                }

              default:
                return <></>;
            }
          })}
        </Stack>
      </Box>
    </>
  );
};

export default Message;
