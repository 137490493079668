import { lazy } from "react";

import Home from "../pages/dashboard/Home";
import Profile from "../pages/dashboard/Profile";
import Post from "../pages/dashboard/Posts";
import OwnPost from "../pages/dashboard/OwnPost";
import CommunityHome from "../pages/dashboard/CommunityHome";
import Saved from "../pages/dashboard/Saved";
import PublicProfile from "../pages/dashboard/PublicProfile";
import AllCommunities from "../pages/dashboard/AllCommunities";
import MyCommunities from "../pages/dashboard/MyCommunities";
import Following from "../pages/dashboard/Following";
// import SignUp from "../pages/auth/SignUp";
import Messenger from "../pages/dashboard/Messenger";
import Call from "../pages/dashboard/Call";
import Notifications from "../pages/dashboard/Notifications";
import ResetPassword from "../pages/auth/ResetPassword";
import NewPassword from "../pages/auth/NewPassword";
import ProfileSettings from "../pages/dashboard/ProfileSettings";

const ReportedPost = lazy(() => import("../pages/dashboard/ReportedPost"));
const Moderator = lazy(() => import("../pages/dashboard/Moderator"));
const DevicesLocations = lazy(() =>
  import("../pages/dashboard/DevicesLocations")
);
const VerifyEmail = lazy(() => import("../pages/auth/VerifyEmail"));
const EmailVerifiedMessage = lazy(() =>
  import("../pages/auth/EmailVerifiedMessage")
);
// const sendWelcomeEmail = lazy(() => import("../pages/auth/sendWelcomeEmail"));

const BlockDevice = lazy(() => import("../pages/dashboard/BlockDevice"));
const LoginVerified = lazy(() => import("../pages/auth/LoginVerified"));
const AccessDenied = lazy(() => import("../pages/auth/AccessDenied"));
const NotFound = lazy(() => import("../pages/NotFound"));

export const privateRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/chat",
    element: <Messenger />,
  },
  {
    path: "/call",
    element: <Call />,
  },
  {
    path: "/notifications",
    element: <Notifications />,
  },
  {
    path: "/post/:postId",
    element: <Post />,
  },
  {
    path: "/my/post/:postId",
    element: <OwnPost />,
  },
  {
    path: "/community/:communityName",
    element: <CommunityHome />,
  },
  {
    path: "/community/:communityName/reported-post",
    element: <ReportedPost />,
  },
  {
    path: "/community/:communityName/moderator",
    element: <Moderator />,
  },
  {
    path: "/saved",
    element: <Saved />,
  },
  {
    path: "/user/:userId",
    element: <PublicProfile />,
  },
  {
    path: "/communities",
    element: <AllCommunities />,
  },
  {
    path: "/my-communities",
    element: <MyCommunities />,
  },
  {
    path: "/following",
    element: <Following />,
  },
  {
    path: "/devices-locations",
    element: <DevicesLocations />,
  },
  {
    path: "/settings",
    element: <ProfileSettings />,
  },
];

export const publicRoutes = [
  // {
  //   path: "/signup",
  //   element: <SignUp />,
  // },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/auth/new-password",
    element: <NewPassword />,
  },
  {
    path: "/auth/verify",
    element: <VerifyEmail />,
  },
  {
    path: "/email-verified",
    element: <EmailVerifiedMessage />,
  },
  {
    path: "/block-device",
    element: <BlockDevice />,
  },
  {
    path: "/verify-login",
    element: <LoginVerified />,
  },
  {
    path: "/access-denied",
    element: <AccessDenied />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
