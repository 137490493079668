import { API, handleApiError } from "./utils";

// Get messages by conversation ID
export const getMessages = async (conversationId) => {
  try {
    const { data } = await API.get(`/messages/${conversationId}`);
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

// Send a message
export const sendMessage = async (messageData) => {
  try {
    const { data } = await API.post("/messages", messageData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

// Update a message
export const updateMessage = async (messageId, updatedData) => {
  try {
    const { data } = await API.patch(`/messages/${messageId}`, updatedData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

// Delete a message
export const deleteMessage = async (messageId) => {
  try {
    const { data } = await API.delete(`/messages/${messageId}`);
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

// Get a single message by ID
export const getMessageById = async (messageId) => {
  try {
    const { data } = await API.get(`/messages/${messageId}`);
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

// Mark a message as read
export const markMessageAsRead = async (messageId) => {
  try {
    const { data } = await API.patch(`/messages/${messageId}/mark-read`);
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

// Get unread messages count
export const getUnreadMessagesCount = async () => {
  try {
    const { data } = await API.get("/messages/unread-count");
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};
