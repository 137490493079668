export const GET_CONVERSATIONS_SUCCESS = "GET_CONVERSATIONS_SUCCESS";
export const GET_CONVERSATIONS_FAIL = "GET_CONVERSATIONS_FAIL";
export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS";
export const CREATE_CONVERSATION_FAIL = "CREATE_CONVERSATION_FAIL";
export const UPDATE_CONVERSATION_SUCCESS = "UPDATE_CONVERSATION_SUCCESS";
export const UPDATE_CONVERSATION_FAIL = "UPDATE_CONVERSATION_FAIL";
export const DELETE_CONVERSATION_SUCCESS = "DELETE_CONVERSATION_SUCCESS";
export const DELETE_CONVERSATION_FAIL = "DELETE_CONVERSATION_FAIL";
export const GET_CONVERSATION_SUCCESS = "GET_CONVERSATION_SUCCESS";
export const GET_CONVERSATION_FAIL = "GET_CONVERSATION_FAIL";
