import * as api from "../api/conversationAPI";
import * as types from "../constants/conversationConstants.js";

export const getConversationsAction = (userId) => async (dispatch) => {
  try {
    if (!userId) {
      throw new Error("User ID is required");
    }

    const { error, data } = await api.getConversations(userId);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: types.GET_CONVERSATIONS_SUCCESS,
      payload: data,
      meta: {
        requiresAuth: true,
      },
    });
  } catch (error) {
    dispatch({
      type: types.GET_CONVERSATIONS_FAIL,
      payload: error.message,
      meta: {
        requiresAuth: true,
      },
    });
  }
};

export const createConversationAction =
  (conversationData) => async (dispatch) => {
    try {
      const { error, data } = await api.createConversation(conversationData);

      if (error) {
        throw new Error(error);
      }

      dispatch({
        type: types.CREATE_CONVERSATION_SUCCESS,
        payload: data,
        meta: {
          requiresAuth: true,
        },
      });
    } catch (error) {
      dispatch({
        type: types.CREATE_CONVERSATION_FAIL,
        payload: error.message,
        meta: {
          requiresAuth: true,
        },
      });
    }
  };

export const updateConversationAction =
  (conversationId, updatedData) => async (dispatch) => {
    try {
      const { error, data } = await api.updateConversation(
        conversationId,
        updatedData
      );

      if (error) {
        throw new Error(error);
      }

      dispatch({
        type: types.UPDATE_CONVERSATION_SUCCESS,
        payload: data,
        meta: {
          requiresAuth: true,
        },
      });
    } catch (error) {
      dispatch({
        type: types.UPDATE_CONVERSATION_FAIL,
        payload: error.message,
        meta: {
          requiresAuth: true,
        },
      });
    }
  };

export const deleteConversationAction =
  (conversationId) => async (dispatch) => {
    try {
      const { error, data } = await api.deleteConversation(conversationId);

      if (error) {
        throw new Error(error);
      }

      dispatch({
        type: types.DELETE_CONVERSATION_SUCCESS,
        payload: data,
        meta: {
          requiresAuth: true,
        },
      });
    } catch (error) {
      dispatch({
        type: types.DELETE_CONVERSATION_FAIL,
        payload: error.message,
        meta: {
          requiresAuth: true,
        },
      });
    }
  };

export const getConversationByIdAction =
  (conversationId) => async (dispatch) => {
    try {
      const { error, data } = await api.getConversationById(conversationId);

      if (error) {
        throw new Error(error);
      }

      dispatch({
        type: types.GET_CONVERSATION_SUCCESS,
        payload: data,
        meta: {
          requiresAuth: true,
        },
      });
    } catch (error) {
      dispatch({
        type: types.GET_CONVERSATION_FAIL,
        payload: error.message,
        meta: {
          requiresAuth: true,
        },
      });
    }
  };
