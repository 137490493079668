import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";

const OwnInfoCard = ({ user }) => {
  const theme = useTheme();

  // Define styles
  const styles = {
    ownInfoCard: {
      // padding: 3,
      // backgroundColor:
      //   theme.palette.mode === "light"
      //     ? "#f8faff"
      //     : theme.palette.background.paper,
      // boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
      // borderRadius: 1,
    },
    profileSummary: {
      justifyContent: "space-between",
    },
    columnStack: {
      justifyContent: "center",
      alignItems: "center",
      padding: 1,
      // backgroundColor:
      //   theme.palette.mode === "light"
      //     ? "#f8faff"
      //     : theme.palette.background.paper,
      // boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
      borderRadius: 1,
    },
    columnStackText: {
      color: "#999",
      fontSize: "12px",
    },
    columnStackTextHeader: {
      // color: "#999",
      fontSize: "20px",
      fontWeight: "bolder",
    },
    link: {
      textDecoration: "none",
      color: "inherit",
    },
  };

  return (
    <Stack sx={styles.ownInfoCard}>
      {/* <Stack sx={styles.profileSummary} direction={"row"}>
        <h3>Profile Summary</h3>
        <Stack>
          Joined {user.duration} ago (
          {new Date(user.createdAt).toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
          )
        </Stack>
      </Stack> */}

      <Stack
        spacing={2}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack
          component={RouterLink}
          to="/following"
          sx={{ ...styles.columnStack, ...styles.link }}
          direction={"column"}
        >
          <Stack sx={styles.columnStackTextHeader}>
            {user.followers?.length ?? 0}
          </Stack>
          <Typography sx={styles.columnStackText}>Followers</Typography>
        </Stack>

        <Stack
          component={RouterLink}
          to="/following"
          sx={{ ...styles.columnStack, ...styles.link }}
          direction={"column"}
        >
          <Stack sx={styles.columnStackTextHeader}>
            {user.following?.length ?? 0}
          </Stack>
          <Typography sx={styles.columnStackText}>Following</Typography>
        </Stack>

        <Stack
          component={RouterLink}
          to="/communities"
          sx={{ ...styles.columnStack, ...styles.link }}
          direction={"column"}
        >
          <Stack sx={styles.columnStackTextHeader}>
            {user.totalCommunities}
          </Stack>
          <Typography sx={styles.columnStackText}>Total Groups</Typography>
        </Stack>

        {/* <Stack sx={styles.columnStack} direction={"column"}>
          <Stack sx={styles.columnStackTextHeader}>{user.totalPosts}</Stack>
          <Typography sx={styles.columnStackText}>Total Posts</Typography>
        </Stack> */}

        {/* {user.totalPosts > 0 && (
          <Stack sx={styles.columnStack} direction={"column"}>
            <Stack sx={styles.columnStackTextHeader}>
              {user.totalPosts} in {user.totalPostCommunities}{" "}
              {user.totalPostCommunities === 1 ? "group" : "groups"}
            </Stack>
            <Typography sx={styles.columnStackText}>Posts in Groups</Typography>
          </Stack>
        )} */}
      </Stack>
    </Stack>
  );
};

export default OwnInfoCard;
