import React from "react";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const InterestsSection = ({ interests, name }) => {
  const theme = useTheme();

  return (
    <Stack
      p={3}
      sx={{
        backgroundColor:
          theme.palette.mode === "light"
            ? "#f8faff "
            : theme.palette.background.paper,
        boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
        borderRadius: 1,
      }}
    >
      <Typography>Interests</Typography>
      {interests ? (
        <Stack>
          {interests.split(",").map((interest, i) => (
            <Stack key={i} sx={{ display: "flex" }}>
              {interest.trim()}
            </Stack>
          ))}
        </Stack>
      ) : (
        <Typography>{name} has not added any interests.</Typography>
      )}
    </Stack>
  );
};

export default InterestsSection;
