import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormProvider, { InputTextField } from "../../components/hookForm";
import {
  Link,
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Typography,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Eye, EyeSlash } from "phosphor-react";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";

import ButtonLoadingSpinner from "../../components/loader/ButtonLoadingSpinner";
import { signInAction, clearMessage } from "../../redux/actions/authActions";

const SignInForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  // const { isLoading } = useSelector((state) => state.auth);

  const signInError = useSelector((state) => state.auth?.signInError);
  const successMessage = useSelector((state) => state.auth?.successMessage);

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Provide a valid email"),
    password: Yup.string().required("Password is required"),
  });

  const methods = useForm({
    resolver: yupResolver(SignInSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
  } = methods;

  const onSubmit = async (formData) => {
    try {
      await dispatch(signInAction(formData, navigate));
      setLoading(true);
      setLoadingText("Signing in...");
      const timeout = setTimeout(() => {
        setLoadingText(
          "This is taking longer than usual.Wait while backend services are getting started."
        );
      }, 5000);
      await dispatch(signInAction(formData, navigate));
      setLoading(false);
      clearTimeout(timeout);
    } catch (error) {
      console.log(error);
      setError("afterSubmit", {
        type: "manual",
        message: error.message,
      });
    }
  };

  const handleClearMessage = () => {
    dispatch(clearMessage());
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}

          <InputTextField
            name="email"
            label="Email address"
            autoComplete="email"
            type="email"
          />

          <InputTextField
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Eye /> : <EyeSlash />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        {signInError && (
          <Box
            mt={3}
            sx={{
              color: "#dc2626",
              border: "1px solid #dc2626",
              padding: "12px",
              borderRadius: "8px",
              textAlign: "center",
              mx: "auto",
              width: "100%",
            }}
            mb={2}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>{signInError}</Typography>

              <IconButton color="error" onClick={handleClearMessage}>
                <RxCross1 />
              </IconButton>
            </Stack>
          </Box>
        )}

        {successMessage && (
          <Box
            mt={3}
            sx={{
              color: "#dc2626",
              border: "1px solid #dc2626",
              padding: "12px",
              borderRadius: "8px",
              textAlign: "center",
              mx: "auto",
              width: "100%",
            }}
            mb={2}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>{successMessage}</Typography>

              <IconButton color="error" onClick={handleClearMessage}>
                <RxCross1 />
              </IconButton>
            </Stack>
          </Box>
        )}

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems="space-between"
          sx={{ my: 2 }}
        >
          <Link
            component={RouterLink}
            to="/admin"
            variant="body2"
            color="inherit"
            underline="always"
          >
            Admin Sign In
          </Link>
          <Link
            component={RouterLink}
            to="/reset-password"
            variant="body2"
            color="inherit"
            underline="always"
          >
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          // loading={isLoading}
          disabled={loading}
          sx={{
            bgcolor: "#fdae38",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
            "&:hover": {
              bgcolor: "#4fa3a5",
              color: (theme) =>
                theme.palette.mode === "light" ? "common.white" : "grey.800",
            },
          }}
        >
          {loading ? (
            <ButtonLoadingSpinner loadingText={loadingText} />
          ) : (
            "Sign In"
          )}
        </LoadingButton>
      </FormProvider>
    </>
  );
};

export default SignInForm;
