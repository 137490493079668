import { memo, useMemo, useEffect, useState, useCallback } from "react";
import {
  getPostsAction,
  clearPostsAction,
} from "../../redux/actions/postActions";
import { useSelector, useDispatch } from "react-redux";
import Post from "../PostElement/Post";
import CommonLoading from "../loader/CommonLoading";
import { useTheme } from "@mui/material/styles";
// import Home from "../../assets/home.jpg";
// import useResponsive from "../../hooks/useResponsive";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import Footer from "../Footer";

import { useNavigate } from "react-router-dom";

const MemoizedPost = memo(Post);

const LoadMoreButton = ({ onClick, isLoading }) => (
  <Button onClick={onClick} disabled={isLoading}>
    {isLoading ? "Loading..." : "Load More Posts"}
  </Button>
);

const MainSection = ({ userData }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const posts = useSelector((state) => state.posts?.posts);
  const totalPosts = useSelector((state) => state.posts?.totalPosts);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  // const isDesktop = useResponsive("up", "md");

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/communities", { state: { initialTab: "explore groups" } });
  };

  const LIMIT = 10;

  useEffect(() => {
    if (userData) {
      dispatch(getPostsAction(LIMIT, 0)).finally(() => {
        setIsLoading(false);
      });
    }

    return () => {
      dispatch(clearPostsAction());
    };
  }, [userData, dispatch, LIMIT]);

  const handleLoadMore = useCallback(() => {
    setIsLoadMoreLoading(true);
    dispatch(getPostsAction(LIMIT, posts.length)).finally(() => {
      setIsLoadMoreLoading(false);
    });
  }, [dispatch, LIMIT, posts.length]);

  const memoizedPosts = useMemo(() => {
    return posts.map((post) => <MemoizedPost key={post._id} post={post} />);
  }, [posts]);

  if (isLoading) {
    return (
      <>
        <CommonLoading />
      </>
    );
  }
  return (
    <>
      <Stack height={"100%"} maxHeight={"100vh"} width="100%">
        <Box
          width={"100%"}
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflowY: "scroll",
            backgroundColor:
              theme.palette.mode === "light"
                ? "#f0f4fa "
                : theme.palette.background.default,
          }}
        >
          <Container sx={{ mt: isMobile ? 10 : 5 }} maxWidth="sm">
            <Stack spacing={3} height={"100%"} width="100%" sx={{ mb: 5 }}>
              <>
                <Stack>{memoizedPosts}</Stack>

                {posts.length > 0 && posts.length < totalPosts && (
                  <LoadMoreButton
                    onClick={handleLoadMore}
                    isLoading={isLoadMoreLoading}
                  />
                )}

                {posts.length === 0 && (
                  <Stack>
                    <Typography
                      variant="subtitle2"
                      color={"text.secondary"}
                      textAlign={"center"}
                    >
                      No posts to show.{" "}
                      <Button onClick={handleClick}>Join a group </Button> and
                      post something.
                    </Typography>

                    {/* <img loading="lazy" src={Home} alt="no post" /> */}
                  </Stack>
                )}
              </>

              <Footer />
            </Stack>
          </Container>
        </Box>
      </Stack>
    </>
  );
};

export default MainSection;
