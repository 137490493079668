import React, { useState } from "react";
import { Avatar, Stack, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import { Chat, Gear } from "phosphor-react";
import { CiLocationOn } from "react-icons/ci";
// import { GrContactInfo } from "react-icons/gr";
// import { useDispatch, useSelector } from "react-redux";
// import { FetchUserProfile } from "../../redux/slices/app";
// import { defaultAvatar } from "../../assets/defaultAvatar";
// import Tooltip from "../shared/Tooltip";
// import ProfileUpdateModal from "../modals/ProfileUpdateModal";
import OwnInfoCard from "./OwnInfoCard";
import ProfileEditDialog from "../modals/ProfileEditDialog";
import useResponsive from "../../hooks/useResponsive";

const ProfileHeader = ({ user }) => {
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");

  const [openProfileEditDialog, setOpenProfileEditDialog] = useState(false);

  const fullName = `${user.firstName} ${user.lastName}`;

  const handleCloseProfileEditDialog = () => {
    setOpenProfileEditDialog(false);
  };

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const handleOpenModal = () => {
  //   setIsModalOpen(true);
  // };

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  return (
    <>
      <Stack
        direction={isDesktop ? "row" : "column"}
        spacing={isDesktop ? 10 : 2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Avatar
          alt={fullName}
          src={user.avatar}
          sx={{ width: isDesktop ? 240 : 160, height: isDesktop ? 240 : 160 }}
        />

        <Stack direction={"column"}>
          <Stack
            spacing={0.3}
            justifyContent={isDesktop ? "flex-start" : "center"}
            alignItems={isDesktop ? "flex-start" : "center"}
          >
            <Typography variant="h3" textTransform={"capitalize"}>
              {fullName}
            </Typography>
            <Stack
              color={"#999"}
              justifyContent={isDesktop ? "flex-start" : "center"}
              alignItems={isDesktop ? "flex-start" : "center"}
            >
              <Stack mb={0.3}>
                {user.occupation ? (
                  <Typography variant="subtitle1">{user.occupation}</Typography>
                ) : (
                  <Typography variant="subtitle1">
                    No occupation added
                  </Typography>
                )}
              </Stack>

              <Stack mb={0.3}>
                {user.location ? (
                  <Typography>
                    <CiLocationOn />
                    {user.location}
                  </Typography>
                ) : (
                  <Typography>
                    <CiLocationOn />
                    Location not added
                  </Typography>
                )}
              </Stack>

              <Stack
                justifyContent={isDesktop ? "flex-start" : "center"}
                alignItems={isDesktop ? "flex-start" : "center"}
              >
                <Typography variant="caption">Interests</Typography>
                {user.interests ? (
                  // without commas (,)
                  // <Stack direction={"row"}>
                  //   {user.interests.split(",").map((interest, i) => (
                  //     <span key={i}>{interest.trim()}</span>
                  //   ))}
                  // </Stack>

                  <Stack direction="row">
                    {/* width commas (,) */}
                    {user.interests.split(",").map((interest, i, array) => (
                      <React.Fragment key={i}>
                        <span>{interest.trim()}</span>
                        {i !== array.length - 1 && <span> , </span>}
                      </React.Fragment>
                    ))}
                  </Stack>
                ) : (
                  <Typography>
                    No interests have been set yet. Add some interests to let
                    people know more about you.
                  </Typography>
                )}
              </Stack>
            </Stack>

            <OwnInfoCard user={user} />

            <Stack direction={"row"} sx={{ pt: 0.5, pb: 0.5 }} spacing={2}>
              {user ? (
                <Button
                  onClick={() => {
                    setOpenProfileEditDialog(true);
                  }}
                  sx={{
                    bgcolor: "#5c5c5c",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                    "&:hover": {
                      bgcolor: "#4fa3a5",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Gear /> Edit profile
                  </Stack>
                </Button>
              ) : (
                <Button
                  sx={{
                    bgcolor: "#5c5c5c",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                    "&:hover": {
                      bgcolor: "#4fa3a5",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Chat /> Message
                  </Stack>
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack p={3}>
        <Stack mb={1}>
          <Typography variant="caption" color={"#999"}>
            Bio
          </Typography>
          {user.bio ? (
            <Typography fontSize={"regular"} sx={{ pt: 2 }}>
              {user.bio}
            </Typography>
          ) : (
            <Typography fontSize={"regular"} sx={{ pt: 2 }}>
              This is the default bio of the user. You are seeing this because
              user has not updated their about info yet. If this account belongs
              to you click on the Edit Profile button to add a bio.
            </Typography>
          )}
        </Stack>

        <Stack>
          <Link
            style={{ textDecoration: "none", color: "#999" }}
            to="/devices-locations"
          >
            Manage Devices and Locations
          </Link>
        </Stack>
      </Stack>

      {openProfileEditDialog && (
        <ProfileEditDialog
          open={openProfileEditDialog}
          handleClose={handleCloseProfileEditDialog}
          user={user}
        />
      )}
    </>
  );
};

export default ProfileHeader;
