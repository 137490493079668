import * as api from "../api/messageAPI.js";
import * as types from "../constants/messageConstants.js";

export const getMessagesAction = (conversationId) => async (dispatch) => {
  try {
    const { error, data } = await api.getMessages(conversationId);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: types.GET_MESSAGES_SUCCESS,
      payload: data,
      meta: {
        requiresAuth: true,
      },
    });
  } catch (error) {
    dispatch({
      type: types.GET_MESSAGES_FAIL,
      payload: error.message,
      meta: {
        requiresAuth: true,
      },
    });
  }
};

export const sendMessageAction = (messageData) => async (dispatch) => {
  try {
    const { error, data } = await api.sendMessage(messageData);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: types.SEND_MESSAGE_SUCCESS,
      payload: data,
      meta: {
        requiresAuth: true,
      },
    });
  } catch (error) {
    dispatch({
      type: types.SEND_MESSAGE_FAIL,
      payload: error.message,
      meta: {
        requiresAuth: true,
      },
    });
  }
};

export const markMessageAsReadAction = (messageId) => async (dispatch) => {
  try {
    const { error, data } = await api.markMessageAsRead(messageId);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: types.MARK_MESSAGE_AS_READ_SUCCESS,
      payload: data,
      meta: {
        requiresAuth: true,
      },
    });
  } catch (error) {
    dispatch({
      type: types.MARK_MESSAGE_AS_READ_FAIL,
      payload: error.message,
      meta: {
        requiresAuth: true,
      },
    });
  }
};

export const deleteMessageAction = (messageId) => async (dispatch) => {
  try {
    const { error, data } = await api.deleteMessage(messageId);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: types.DELETE_MESSAGE_SUCCESS,
      payload: data,
      meta: {
        requiresAuth: true,
      },
    });
  } catch (error) {
    dispatch({
      type: types.DELETE_MESSAGE_FAIL,
      payload: error.message,
      meta: {
        requiresAuth: true,
      },
    });
  }
};

export const getUnreadMessagesCountAction = () => async (dispatch) => {
  try {
    const { error, data } = await api.getUnreadMessagesCount();

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: types.GET_UNREAD_MESSAGES_COUNT_SUCCESS,
      payload: { count: data.unreadCount },
      meta: {
        requiresAuth: true,
      },
    });
  } catch (error) {
    dispatch({
      type: types.GET_UNREAD_MESSAGES_COUNT_FAIL,
      payload: error.message,
      meta: {
        requiresAuth: true,
      },
    });
  }
};
