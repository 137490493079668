import { Box, Container, Stack } from "@mui/material";
import { BarLoader } from "react-spinners";

const CommonLoading = () => {
  return (
    <>
      <Box
        width={"100%"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Container sx={{ mt: 5 }} maxWidth="sm">
          <Stack
            spacing={3}
            height={"100%"}
            width="100%"
            sx={{
              mb: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BarLoader color="#4fa3a5" height={5} width={100} />
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default CommonLoading;
