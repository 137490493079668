import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getFollowingUsersAction,
  getFollowersAction,
} from "../../redux/actions/userActions";
import PublicProfileCard from "../../components/profile/PublicProfileCard";
import CommonLoading from "../../components/loader/CommonLoading";
import { useTheme } from "@mui/material/styles";
// import noFollow from "../../assets/nofollow.jpg";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import Follow from "./Follow";
import { TbUser, TbUsersPlus } from "react-icons/tb";
import { TbUsers } from "react-icons/tb";

const Following = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const followingUsers = useSelector((state) => state.user?.followingUsers);
  const followers = useSelector((state) => state.user?.followers);

  const [activeTab, setActiveTab] = useState("following");

  useEffect(() => {
    const fetchFollowingUsers = async () => {
      setLoading(true);
      await dispatch(getFollowingUsersAction());
      setLoading(false);
    };

    fetchFollowingUsers();
  }, [dispatch]);

  useEffect(() => {
    const fetchFollowers = async () => {
      setLoading(true);
      await dispatch(getFollowersAction());
      setLoading(false);
    };

    fetchFollowers();
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Stack
      height={"100vh"}
      maxHeight={"100vh"}
      width="100%"
      sx={{
        backgroundColor:
          theme.palette.mode === "light"
            ? "#f0f4fa "
            : theme.palette.background.default,
      }}
    >
      <Box
        width={"100%"}
        sx={{
          flexGrow: 1,
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <Container sx={{ mt: isMobile ? 10 : 5 }} maxWidth="md">
          <Tabs
            value={activeTab}
            onChange={handleChange}
            aria-label="tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label="following"
              value="following"
              icon={<TbUsers />}
              iconPosition="start"
            />
            <Tab
              label="followers"
              value="followers"
              icon={<TbUser />}
              iconPosition="start"
            />
            <Tab
              label="suggested"
              value="suggested"
              icon={<TbUsersPlus />}
              iconPosition="start"
            />
          </Tabs>
          <Stack mt={2}>
            {activeTab === "following" && (
              <>
                <Stack
                  sx={{ width: "100%" }}
                  // maxWidth="sm"
                >
                  {loading ? (
                    <>
                      <CommonLoading />
                    </>
                  ) : (
                    <Stack>
                      {followingUsers?.length > 0 ? (
                        <Grid
                          container
                          // border={"solid red 1px"}
                          gap={1.2}
                        >
                          {followingUsers.map((user) => (
                            <PublicProfileCard key={user._id} user={user} />
                          ))}
                        </Grid>
                      ) : (
                        <Box
                          textAlign="center"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                        >
                          <Typography
                            color="text.secondary"
                            variant="subtitle2"
                          >
                            You are not following anyone yet.
                          </Typography>
                          {/* <img src={noFollow} alt="no post" className="max-w-md" /> */}
                        </Box>
                      )}
                    </Stack>
                  )}
                </Stack>
              </>
            )}
          </Stack>

          <Stack mt={2}>
            {activeTab === "followers" && (
              <>
                <Stack sx={{ width: "100%" }}>
                  {loading ? (
                    <>
                      <CommonLoading />
                    </>
                  ) : (
                    <Stack>
                      {followers?.length > 0 ? (
                        <Grid container gap={1.2}>
                          {followers.map((user) => (
                            <PublicProfileCard key={user._id} user={user} />
                          ))}
                        </Grid>
                      ) : (
                        <Box
                          textAlign="center"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                        >
                          <Typography
                            color="text.secondary"
                            variant="subtitle2"
                          >
                            You don't have any followers yet.
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                  )}
                </Stack>
              </>
            )}
          </Stack>

          {activeTab === "suggested" && (
            <>
              <Follow />
            </>
          )}
        </Container>
      </Box>
    </Stack>
  );
};

export default Following;
