import React, { useState } from "react";
import {
  Dialog,
  Slide,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  TextField,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  getUserAction,
  updateUserAction,
} from "../../redux/actions/userActions";
import { useDispatch } from "react-redux";
import ButtonLoadingSpinner from "../loader/ButtonLoadingSpinner";
import { FiUser, FiMapPin, FiEdit } from "react-icons/fi";
import useResponsive from "../../hooks/useResponsive";
import { RxCross1 } from "react-icons/rx";
import UpdateUserAvatar from "../updateUserAvatar";
import Transition from "../Transition";
// const suggestedInterests = [
//   "🎨 Art",
//   "📚 Books",
//   "💼 Business",
//   "🚗 Cars",
//   "📖 Comics",
//   "🌍 Culture",
//   "✏️ Design",
//   "🍽️ Food",
//   "🎮 Gaming",
//   "🎶 Music",
//   "🏋️ Fitness",
//   "🏞️ Travel",
//   "🎯 Sports",
//   "🎬 Movies",
//   "📺 TV Shows",
//   "📷 Photography",
//   "💻 Technology",
//   "🧘‍♀️ Yoga",
//   "🌱 Sustainability",
//   "📝 Writing",
// ];

const ProfileEditDialog = ({ open, handleClose, user }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isUpdating, setIsUpdating] = useState(false);
  const [bio, setBio] = useState(user.bio ? user.bio : "");
  const [location, setLocation] = useState(user.location ? user.location : "");
  const [firstName, setFirstName] = useState(
    user.firstName ? user.firstName : ""
  );
  const [lastName, setLastName] = useState(user.lastName ? user.lastName : "");
  const [interests, setInterests] = useState(
    user.interests ? user.interests : ""
  );

  const [name, setName] = useState(user.name ? user.name : "");
  // const [email, setEmail] = useState("");

  const handleUpdateProfile = async () => {
    setIsUpdating(true);

    const formData = {
      bio,
      location,
      interests,
      name,
      firstName,
      lastName,
    };

    // console.log("Form Data:", formData);

    await dispatch(updateUserAction(user._id, formData));
    await dispatch(getUserAction(user._id));
    setBio("");
    setLocation("");
    setInterests("");
    setName("");
    setFirstName("");
    setLastName("");

    setIsUpdating(false);
  };

  // const handleUpdateAvatar = async () => {
  //   setIsUpdating(true);

  //   if (!avatar) {
  //     setIsUpdating(false);
  //     return; // No avatar to update
  //   }

  //   try {
  //     const formData = new FormData();
  //     formData.append("avatar", avatar);

  //     console.log("FormData:", formData);

  //     await dispatch(updateUserAvatarAction(user._id, formData));
  //     await dispatch(getUserAction(user._id));

  //     setAvatar(null);
  //     setAvatarError(null);
  //   } catch (error) {
  //     console.error("Error updating avatar:", error);
  //   }

  //   setIsUpdating(false);
  // };

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ mb: 2 }}>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Typography> Edit Profile</Typography>
            <IconButton variant="outlined" type="button" onClick={handleClose}>
              <RxCross1 />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction={"column"} spacing={3}>
            <UpdateUserAvatar user={user} />
            <Divider />
            <Typography>Change Personal Info:</Typography>
            <Stack spacing={2}>
              <Stack
                direction={isDesktop ? "row" : "column"}
                spacing={2}
                width={"100%"}
              >
                <TextField
                  id="filled-basic"
                  label={
                    <>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center", gap: "2px" }}
                      >
                        {/* <FiMapPin /> */}
                        Firstname
                      </Stack>
                    </>
                  }
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  sx={{ width: "100%" }}
                />
                <TextField
                  id="filled-basic"
                  label={
                    <>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center", gap: "2px" }}
                      >
                        {/* <FiMapPin /> */}
                        Lastname
                      </Stack>
                    </>
                  }
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </Stack>

              <Stack>
                <TextField
                  label={
                    <>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center", gap: "2px" }}
                      >
                        <FiUser />
                        Bio
                      </Stack>
                    </>
                  }
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  type="text"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                />
              </Stack>

              <Stack>
                <TextField
                  id="filled-basic"
                  label={
                    <>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center", gap: "2px" }}
                      >
                        <FiMapPin />
                        Location
                      </Stack>
                    </>
                  }
                  variant="outlined"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </Stack>

              <Stack>
                <TextField
                  id="filled-basic"
                  label={
                    <>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center", gap: "2px" }}
                      >
                        <FiEdit />
                        Interests (Separated by comma)
                      </Stack>
                    </>
                  }
                  variant="outlined"
                  value={interests}
                  onChange={(e) => {
                    if (e.target.value.length <= 50) {
                      setInterests(e.target.value);
                    }
                  }}
                  maxLength={50}
                />

                {/* <Stack mt={4} sx={{ overflowY: "auto" }}>
                <Stack
                  sx={{ display: "flex", gap: "2", flexWrap: "wrap" }}
                  direction={"row"}
                >
                  {suggestedInterests.map((interest, index) => (
                    <Button
                      key={index}
                      type="button"
                      disabled={isUpdating || interests.length >= 50}
                      onClick={() =>
                        setInterests(
                          interests === ""
                            ? interest
                            : interests + ", " + interest
                        )
                      }
                    >
                      {interest}
                    </Button>
                  ))}
                </Stack>
              </Stack> */}
              </Stack>

              <Stack>
                <TextField
                  id="name"
                  label={
                    <>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center", gap: "2px" }}
                      >
                        <FiUser />
                        Username
                      </Stack>
                    </>
                  }
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Stack>
              <Stack
                spacing={1}
                direction={"row"}
                mt={2}
                width={"100%"}
                sx={{ justifyContent: "flex-end" }}
              >
                <Button
                  variant="outlined"
                  disabled={isUpdating}
                  type="button"
                  onClick={handleUpdateProfile}
                >
                  {isUpdating ? (
                    <ButtonLoadingSpinner loadingText={"Updating..."} />
                  ) : (
                    <Typography>Update</Typography>
                  )}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProfileEditDialog;
