import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Stack,
  Slide,
  Typography,
} from "@mui/material";

import Transition from "../Transition";

const TopicConflictModal = ({
  communityName,
  closeTopicConflictModal,
  showTopicConflictModal,
  recommendedCommunity,
}) => {
  const handleClose = () => {
    if (showTopicConflictModal) {
      closeTopicConflictModal();
    }
  };

  return (
    <>
      <Dialog
        open={showTopicConflictModal}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={Transition}
        keepMounted
        justifyContent={"center"}
      >
        <DialogTitle>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
            >
              <Typography variant="subtitle2" fontSize={20}>
                Important Message
              </Typography>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={1}
              >
                <Typography variant="body">
                  Hello! We've noticed that your post in the{" "}
                  <strong className="text-primary">{communityName}</strong>{" "}
                  community may not be the best fit for that audience. However,
                  we believe it would be a great fit for the{" "}
                  <strong className="text-primary">
                    {recommendedCommunity}
                  </strong>{" "}
                  community!
                </Typography>
              </Stack>
            </Stack>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
            >
              <Button variant="outlined" onClick={handleClose}>
                Got it, thanks!
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TopicConflictModal;
