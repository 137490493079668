import React, { lazy, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Avatar, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import { defaultAvatar } from "../../assets/defaultAvatar";
import CommentForm from "../form/CommentForm";
import { getCommunityAction } from "../../redux/actions/communityActions";
import { PhotoProvider, PhotoView } from "react-photo-view";
import Like from "./Like";
import Save from "./Save";
import { IoIosArrowBack } from "react-icons/io";
import CommonLoading from "../loader/CommonLoading";
import { IoChatbubbleOutline } from "react-icons/io5";

import ReportPostModal from "../modals/ReportPostModal";

// import Tooltip from "../shared/Tooltip";
import DeleteModal from "../modals/DeleteModal";
import { TbMessageReport } from "react-icons/tb";
import { MdOutlineDelete } from "react-icons/md";

const CommentsView = lazy(() => import("./CommentsView"));

const PostView = ({ post, userData }) => {
  const [loading, setLoading] = useState(true);
  // const [openPicker, setOpenPicker] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const theme = useTheme();
  // const image = defaultAvatar;

  const {
    content,
    fileUrl,
    fileType,
    user,
    community,
    dateTime,
    comments,
    // savedByCount,
    isReported,
  } = post;

  const fullName = `${user?.firstName} ${user?.lastName}`;
  // console.log(fullName);

  useEffect(() => {
    dispatch(getCommunityAction(community.name)).then(() => setLoading(false));
  }, [dispatch, community.name, loading]);

  const [showModal, setShowModal] = useState(false);
  const toggleModal = (value) => {
    setShowModal(value);
  };

  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isReportedPost, setIsReportedPost] = useState(isReported);

  const handleReportClick = () => {
    setIsReportModalOpen(true);
  };

  const handleReportClose = () => {
    setIsReportModalOpen(false);
  };

  if (loading) {
    return (
      <>
        <CommonLoading />
      </>
    );
  }

  return (
    <>
      <Stack spacing={3} height={"100%"} width="100%" sx={{ mb: 5 }}>
        <Stack
          p={3}
          sx={{
            backgroundColor:
              theme.palette.mode === "light"
                ? "#f8faff "
                : theme.palette.background.paper,
            boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
            borderRadius: 1,
          }}
        >
          <Stack spacing={1} sx={{ mb: 1 }}>
            <Stack>
              <IconButton sx={{ width: "40px", mb: 1 }}>
                <IoIosArrowBack
                  onClick={() => navigate(location.state?.from || "/")}
                />
              </IconButton>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <Avatar alt={"user image"} src={user?.avatar} loading="lazy" />
              <Stack
                direction={"row"}
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <Stack spacing={0.3} direction={"column"}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {userData._id === user?._id ? (
                      <Link
                        style={{
                          textDecoration: "none",
                          color:
                            theme.palette.mode === "light"
                              ? "#000"
                              : theme.palette.primary.main,
                        }}
                        to="/profile"
                      >
                        {user ? fullName : "Deleted User"}
                      </Link>
                    ) : (
                      <Link
                        style={{
                          textDecoration: "none",
                          color:
                            theme.palette.mode === "light"
                              ? "#000"
                              : theme.palette.primary.main,
                        }}
                        to={`/user/${user?._id}`}
                      >
                        {user ? fullName : "Deleted User"}
                      </Link>
                    )}
                  </Typography>
                  <Typography variant="subtitle2">
                    <Link
                      to={`/community/${community.name}`}
                      style={{
                        textDecoration: "none",
                        color: "#999",
                        fontSize: "11px",
                      }}
                    >
                      {community.name}
                    </Link>
                  </Typography>
                </Stack>
                <Typography variant="caption" fontSize={"9px"} color={"#999"}>
                  {dateTime}
                </Typography>
              </Stack>
            </Stack>

            <Stack>
              <Stack sx={{ pt: 2 }} spacing={1}>
                <Typography>{content}</Typography>
                <Stack>
                  {fileUrl && fileType === "image" ? (
                    <PhotoProvider
                      overlayRender={() => (
                        <Stack>
                          <Typography variant="subtitle2">
                            {fullName}
                          </Typography>
                          <Typography variant="subtitle2">
                            {community.name}
                          </Typography>
                          <Typography variant="subtitle2">
                            {dateTime}
                          </Typography>
                        </Stack>
                      )}
                    >
                      <PhotoView src={fileUrl}>
                        <Stack>
                          <img
                            src={fileUrl}
                            alt={content}
                            loading="lazy"
                            className="imagePost"
                          />
                        </Stack>
                      </PhotoView>
                    </PhotoProvider>
                  ) : (
                    fileUrl && (
                      <Stack>
                        <video className="videoPost" src={fileUrl} controls />
                      </Stack>
                    )
                  )}
                </Stack>
              </Stack>
              <Divider />

              <Stack direction={"row"} spacing={0.5}>
                <Like post={post} />
                <IconButton>
                  <IoChatbubbleOutline />
                  <Typography variant="subtitle2">{comments.length}</Typography>
                </IconButton>
                <Save postId={post._id} />

                {isReportedPost ? (
                  <IconButton disabled>
                    <TbMessageReport style={{ color: "#4fa3a5" }} />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleReportClick}>
                    <TbMessageReport />
                  </IconButton>
                )}

                <Stack>
                  {userData?._id === post.user?._id && (
                    <IconButton onClick={() => toggleModal(true)}>
                      <MdOutlineDelete />
                    </IconButton>
                  )}
                </Stack>
              </Stack>
              <Divider />

              <CommentsView comments={post.comments} />
            </Stack>
            <CommentForm communityId={community._id} postId={post._id} />
          </Stack>

          <DeleteModal
            showModal={showModal}
            postId={post._id}
            onClose={() => toggleModal(false)}
            prevPath={location.state?.from || "/"}
          />

          <ReportPostModal
            open={isReportModalOpen}
            onClose={handleReportClose}
            postId={post._id}
            communityId={community._id}
            setReportedPost={setIsReportedPost}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default PostView;
