import { getSavedPostsAction } from "../../redux/actions/postActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SavedPost from "../../components/PostElement/SavedPost";
// import NoSavedPost from "../../assets/nopost.jpg";
import { Stack, Typography } from "@mui/material";

const Saved = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSavedPostsAction());
  }, [dispatch]);

  const savedPosts = useSelector((state) => state.posts?.savedPosts);

  return (
    <>
      <Stack>
        {savedPosts && savedPosts.length > 0 ? (
          <Stack>
            {savedPosts.reverse().map((post) => (
              <SavedPost key={post._id} post={post} />
            ))}
          </Stack>
        ) : (
          <Stack>
            <Typography fontSize={13} textAlign={"center"} color={"grey"}>
              You haven't saved any post yet.
            </Typography>
            {/* <img loading="lazy" src={NoSavedPost} alt="no post" /> */}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default Saved;
