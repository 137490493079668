import React, { useState, useEffect } from "react";

import { Provider as ReduxProvider } from "react-redux";
import createAppStore from "./redux/store";
// import axios from "axios";
import CommonLoading from "./components/loader/CommonLoading";
import App from "./App";
import { getTitleFromRoute } from "./utils/docTitle";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
// import { Stack } from "@mui/material";

const ErrorComponent = ({ errorMessage }) => (
  <div className="text-center font-bold text-red-500">{errorMessage}</div>
);

const AppWrapper = () => {
  const location = useLocation();
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   const checkServerStatus = async () => {
  //     try {
  //       await axios.get("/server-status");
  //     } catch (err) {
  //       setError("Server is down. Please try again later.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   checkServerStatus();
  // }, []);

  useEffect(() => {
    const initializeStore = async () => {
      try {
        const appStore = await createAppStore();
        setStore(appStore);
      } catch (err) {
        setError(`Error initializing the app: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    initializeStore();
  }, []);

  if (loading || error) {
    return (
      <>
        {loading ? <CommonLoading /> : <ErrorComponent errorMessage={error} />}
      </>
    );
  }

  return (
    <ReduxProvider store={store}>
      <Helmet>
        <title>{getTitleFromRoute(location.pathname)}</title>
      </Helmet>
      <App />
    </ReduxProvider>
  );
};

export default AppWrapper;
