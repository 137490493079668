import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getPublicUserAction,
  unfollowUserAction,
  followUserAction,
} from "../../redux/actions/userActions";
import PublicProfileHeader from "../../components/publicProfile/publicProfileHeader";
import CommonLoading from "../../components/loader/CommonLoading";
import {
  Box,
  Container,
  Divider,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Footer from "../../components/Footer";
import InterestsSection from "../../components/publicProfile/InterestsSection";
import FollowInfo from "../../components/publicProfile/FollowInfo";
import PublicPost from "../../components/profile/PublicPost";

const PublicProfile = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [followLoading, setFollowLoading] = useState(false);
  const [unfollowLoading, setUnfollowLoading] = useState(false);

  const userData = useSelector((state) => state.auth?.userData);
  const userProfile = useSelector((state) => state.user?.publicUserProfile);
  const isUserFollowing = useSelector((state) => state.user?.isFollowing);
  const isModerator = useSelector(
    (state) => state.auth?.userData?.role === "moderator"
  );

  const publicUserId = location.pathname.split("/")[2];

  useEffect(() => {
    dispatch(getPublicUserAction(publicUserId));
  }, [dispatch, publicUserId, isUserFollowing]);

  useEffect(() => {
    if (publicUserId === userData?._id) {
      navigate("/profile", { replace: true });
    }
  }, [publicUserId, userData, navigate]);

  const handleUnfollow = async (publicUserId) => {
    setUnfollowLoading(true);
    await dispatch(unfollowUserAction(publicUserId));
    setUnfollowLoading(false);
  };

  const handleFollow = async (publicUserId) => {
    setFollowLoading(true);
    await dispatch(followUserAction(publicUserId));
    setFollowLoading(false);
  };

  if (!userProfile) {
    return <CommonLoading />;
  }

  const {
    name,
    firstName,
    lastName,
    avatar,
    location: userLocation,
    bio,
    role,
    interests,
    totalFollowers,
    totalFollowing,
    totalCommunities,
  } = userProfile;

  return (
    <Stack height="100vh" maxHeight="100vh" width="100%">
      <Box
        width="100%"
        sx={{
          flexGrow: 1,
          height: "100%",
          overflowY: "scroll",
          backgroundColor:
            theme.palette.mode === "light"
              ? "#f0f4fa "
              : theme.palette.background.default,
        }}
      >
        <Container sx={{ mt: isMobile ? 10 : 5 }} maxWidth="md">
          <Stack spacing={3} height="100%" width="100%" sx={{ mb: 5 }}>
            <Stack spacing={2}>
              <PublicProfileHeader
                avatar={avatar}
                name={name}
                firstName={firstName}
                lastName={lastName}
                userLocation={userLocation}
                role={role}
                bio={bio}
                isFollowing={isUserFollowing}
                isModerator={isModerator}
                handleUnfollow={handleUnfollow}
                handleFollow={handleFollow}
                followLoading={followLoading}
                unfollowLoading={unfollowLoading}
                publicUserId={publicUserId}
                followers={totalFollowers}
                following={totalFollowing}
                totalCommunities={totalCommunities}
              />
              <FollowInfo
                isFollowing={isUserFollowing}
                role={role}
                totalFollowers={totalFollowers}
                name={name}
              />
              <InterestsSection interests={interests} name={name} />
            </Stack>
            <Divider />
            <Stack
              sx={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Container maxWidth="sm">
                {isUserFollowing && <PublicPost publicUserId={publicUserId} />}
              </Container>
            </Stack>
          </Stack>
          <Footer />
        </Container>
      </Box>
    </Stack>
  );
};

export default PublicProfile;
