import { useMemo } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import Like from "./Like";

import { Avatar, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IoChatbubbleOutline } from "react-icons/io5";

const SavedPost = ({ post }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const { content, fileUrl, user, community, createdAt, comments } = post;

  const isImageFile = useMemo(() => {
    const validExtensions = [".jpg", ".png", ".jpeg", ".gif", ".webp", ".svg"];
    const fileExtension = fileUrl?.slice(fileUrl.lastIndexOf("."));
    return validExtensions.includes(fileExtension);
  }, [fileUrl]);

  // const handleBack = () => {
  //   navigate(-1);
  // };

  return (
    <>
      <Stack spacing={3} height={"100%"} width="100%" sx={{ mb: 1.2 }}>
        <Stack
          p={2}
          sx={{
            backgroundColor:
              theme.palette.mode === "light"
                ? "#f8faff "
                : theme.palette.background.paper,
            boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
            borderRadius: 1,
          }}
        >
          <Stack spacing={1} sx={{ mb: 1 }}>
            {/* <Stack>
              <IconButton sx={{ width: "40px", mb: 1 }}>
                <IoIosArrowBack onClick={handleBack} />
              </IconButton>
            </Stack> */}

            <Stack direction={"row"} spacing={2}>
              <Avatar alt={"user image"} src={user?.avatar} loading="lazy" />
              <Stack
                direction={"row"}
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <Stack spacing={0.3} direction={"column"}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textTransform: "capitalize",
                      color:
                        theme.palette.mode === "light"
                          ? "#000"
                          : theme.palette.primary.main,
                    }}
                  >
                    {user ? user.name : "Deleted User"}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: "#999",
                      fontSize: "11px",
                    }}
                  >
                    {community?.name}
                  </Typography>
                </Stack>
                <Typography variant="caption" fontSize={"9px"} color={"#999"}>
                  {createdAt}
                </Typography>
              </Stack>
            </Stack>

            <Stack
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/post/${post._id}`, {
                  state: { from: location.pathname },
                });
              }}
            >
              <Typography>{content}</Typography>
              <Stack className="flex justify-center" mt={1}>
                {fileUrl && isImageFile ? (
                  <img
                    className="imagePost"
                    src={fileUrl}
                    alt={content}
                    loading="lazy"
                  />
                ) : (
                  fileUrl && (
                    <video className="videoPost" src={fileUrl} controls />
                  )
                )}
              </Stack>
            </Stack>
            <Divider />

            <Stack direction={"row"} spacing={0.5}>
              <Like post={post} />
              <Link to={`/post/${post._id}`}>
                <IconButton>
                  <IoChatbubbleOutline />
                  <Typography variant="subtitle2">{comments.length}</Typography>
                </IconButton>
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default SavedPost;
