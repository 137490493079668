import { useEffect, useState, memo } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  savePostAction,
  unsavePostAction,
  getSavedPostsAction,
  increaseSavedByCount,
  decreaseSavedByCount,
} from "../../redux/actions/postActions";
import { IconButton, Typography } from "@mui/material";
import { IoBookmark, IoBookmarkOutline } from "react-icons/io5";

const Save = ({ postId }) => {
  const dispatch = useDispatch();

  const [saved, setSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const savedPosts = useSelector((state) => state.posts?.savedPosts);
  const savedPostsIds = savedPosts.map((post) => post._id);

  useEffect(() => {
    dispatch(getSavedPostsAction());
  }, [dispatch]);

  useEffect(() => {
    if (savedPostsIds.includes(postId)) {
      setSaved(true);
    } else {
      setSaved(false);
    }
  }, [postId, savedPostsIds]);

  const handleSave = async () => {
    try {
      setIsSaving(true);
      await dispatch(savePostAction(postId));
      dispatch(increaseSavedByCount(postId));
      setSaved(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleUnsave = async () => {
    try {
      setIsSaving(true);
      await dispatch(unsavePostAction(postId));
      dispatch(decreaseSavedByCount(postId));
      setSaved(false);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <IconButton onClick={saved ? handleUnsave : handleSave} disabled={isSaving}>
      {isSaving ? (
        <Typography variant="subtitle2">Saving...</Typography>
      ) : saved ? (
        <>
          {/* <span className="tooltiptext">Remove from saved</span> */}
          <IoBookmark style={{ color: "#4fa3a5" }} />
        </>
      ) : (
        <>
          {/* <span className="tooltiptext">Save post</span> */}
          <IoBookmarkOutline />
        </>
      )}
    </IconButton>
  );
};

export default memo(Save);
