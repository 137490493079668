import axios from "axios";

// Import the BASE_URL constant from the configuration file.
import { BASE_URL } from "../config";

//  Axios instance with a predefined base URL using the BASE_URL constant.
const axiosInstance = axios.create({ baseURL: BASE_URL });

// an interceptor to handle responses from Axios requests.
axiosInstance.interceptors.response.use(
  // If the response is successful, pass it through without any modifications.
  (response) => response,

  // If there is an error response, handle the error.
  (error) =>
    // Reject the promise with the error response data if it exists, or a generic error message if not.
    Promise.reject(
      (error.response && error.response.data) || "Sorry! something went wrong"
    )
);

export default axiosInstance;
