import React, { Fragment } from "react";
import { Stack, Typography, Box } from "@mui/material";
import { FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const FollowInfo = ({
  isFollowing,
  role,
  totalFollowers,
  name,
  followingSince,
  commonCommunities,
}) => {
  const theme = useTheme();
  return (
    <Stack
      p={2}
      sx={{
        backgroundColor:
          theme.palette.mode === "light"
            ? "#f8faff "
            : theme.palette.background.paper,
        boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
        borderRadius: 1,
      }}
    >
      {isFollowing && role !== "moderator" ? (
        <>
          {totalFollowers === 1 ? (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <FiUser />
              <Typography>Followed by you</Typography>
            </Stack>
          ) : (
            <Stack direction={"row"}>
              <FiUser />
              {`Followed by you and `}
              <Typography>{totalFollowers - 1} others</Typography>
            </Stack>
          )}
          <Box>
            You are following
            <Typography> {name} </Typography>
            since {followingSince}
          </Box>
        </>
      ) : null}
      <Stack direction={"row"}>
        {commonCommunities?.length === 0 ? (
          <Typography>You have no groups in common.</Typography>
        ) : (
          <Stack direction={"row"} spacing={0.6}>
            <Typography> You both are members of</Typography>{" "}
            {commonCommunities?.slice(0, 1).map((c) => (
              <Fragment key={c._id}>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    fontWeight: "bold",
                  }}
                  to={`/community/${c.name}`}
                >
                  {c.name}
                </Link>
              </Fragment>
            ))}
            {commonCommunities?.length > 1 && (
              <Stack direction={"row"} spacing={0.6}>
                <Typography>and</Typography>
                <Stack className="tooltip" direction={"row"} spacing={0.6}>
                  {`${commonCommunities?.length - 1} other ${
                    commonCommunities?.length - 1 === 1 ? "group" : "groups"
                  }`}
                  <Typography className="tooltiptext">
                    {commonCommunities
                      ?.slice(1)
                      .map((c) => `${c.name}`)
                      .join(", ")}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default FollowInfo;
