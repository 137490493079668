import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Stack } from "@mui/material";

const Messages = () => {
  const { messages } = useSelector((state) => state.message);

  return (
    <Box sx={{ padding: 3 }}>
      <Stack spacing={2}>
        {messages.map((message) => (
          <Typography key={message._id} variant="body2">
            {message.content}
          </Typography>
        ))}
      </Stack>
    </Box>
  );
};

export default Messages;
