import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import useSettings from "../../hooks/useSettings";
import SearchIcon from "@mui/icons-material/Search";
import { LuSettings2 } from "react-icons/lu";
import SearchModal from "../../components/modals/SearchModal";
import getPath from "./getPath";
import { logoutAction } from "../../redux/actions/authActions";
import { Heart } from "phosphor-react";
import {
  Stack,
  Menu,
  IconButton,
  MenuItem,
  ListItemIcon,
  AppBar,
  Toolbar,
  Badge,
} from "@mui/material";
import Logo from "../../assets/Images/Logo-mark_coloured.png";
import LogoWhite from "../../assets/Images/logo_white.png";

import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import AntSwitch from "../AntSwitch";
import { getUnreadNotificationsCountAction } from "../../redux/actions/notificationActions";

const TopBar = ({ userData }) => {
  const theme = useTheme();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [openSearchDialog, setOpenSearchDialog] = useState(false);

  const { onToggleMode } = useSettings();

  const unreadNotificationsCount = useSelector(
    (state) => state.notifications.unreadCount
  );
  useEffect(() => {
    dispatch(getUnreadNotificationsCountAction());
  }, [dispatch]);

  useEffect(() => {
    // Log out the unread notifications count
    // console.log("Unread Notifications Count:", unreadNotificationsCount);
  }, [unreadNotificationsCount]);

  const handleCloseSearchDialog = () => {
    setOpenSearchDialog(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          {/* Logo */}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Stack>
              <Stack
                sx={{
                  height: 64,
                  width: 64,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={theme.palette.mode === "light" ? Logo : LogoWhite}
                  alt="MoMs Chat"
                />
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{ width: "40%" }}
            >
              {/* Search bar */}
              {/* Notification icon */}

              <IconButton
                color="inherit"
                onClick={() => {
                  setOpenSearchDialog(true);
                }}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                color="inherit"
                onClick={() => {
                  navigation(getPath(4));
                }}
              >
                <Badge badgeContent={unreadNotificationsCount} color="error">
                  <Heart />
                </Badge>
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <LuSettings2 />
              </IconButton>
            </Stack>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {/* <MenuItem onClick={handleClose}>
                <Avatar
                  src={userData.avatar}
                  sx={{ width: 30, height: 30 }}
                  onClick={() => {
                    navigation(getPath(7));
                  }}
                />
                My Profile
              </MenuItem>

              <Divider /> */}

              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <AntSwitch
                    onChange={() => {
                      onToggleMode();
                      handleClose();
                    }}
                    defaultChecked
                  />
                </ListItemIcon>
                Theme
              </MenuItem>

              <MenuItem
                onClick={() => {
                  navigation(getPath(6));
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  await dispatch(logoutAction());
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Stack>
        </Toolbar>
      </AppBar>

      {openSearchDialog && (
        <SearchModal
          open={openSearchDialog}
          handleCloseModal={handleCloseSearchDialog}
        />
      )}
    </>
  );
};

export default TopBar;
