import React from "react";

const getPath = (index) => {
  switch (index) {
    case 0:
      return "/home";
    case 1:
      return "/chat";
    case 2:
      return "/communities";
    case 3:
      return "/call";
    case 4:
      return "/notifications";
    case 5:
      return "/new-post";
    case 6:
      return "/settings";
    case 7:
      return "/profile";

    default:
      return "/";
  }
};

export default getPath;
