import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import debounce from "lodash/debounce";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../components/Search";
import { MagnifyingGlass } from "phosphor-react";
import { MdClear } from "react-icons/md";
import { MoonLoader } from "react-spinners";
import JoinModal from "./JoinModal";
import Transition from "../Transition";

const BASE_URL = process.env.REACT_APP_API_URL;

const SearchModal = ({ open, handleCloseModal }) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);
  const [community, setCommunity] = useState(null);
  const [joinedCommunity, setJoinedCommunity] = useState(null);
  const [loading, setLoading] = useState(false);

  const accessToken = JSON.parse(localStorage.getItem("profile"))?.accessToken;
  const [openJoinModal, setOpenJoinModal] = useState(false);

  // const handleCloseJoinModal = () => {
  //   setOpenJoinModal(false);
  // };

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const handleOpenModal = () => {
  //   setIsModalOpen(true);
  // };

  const setInitialValue = () => {
    setUsers([]);
    setPosts([]);
    setCommunity(null);
    setJoinedCommunity(null);
    setLoading(false);
  };

  const debouncedHandleSearch = useMemo(
    () =>
      debounce((q) => {
        setLoading(true);
        const encodedQuery = encodeURIComponent(q);
        axios
          .get(`${BASE_URL}/search?q=${encodedQuery}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            const { posts, users, community, joinedCommunity } = res.data;
            setPosts(posts);
            setUsers(users);
            setCommunity(community);
            setJoinedCommunity(joinedCommunity);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }, 800),
    [accessToken]
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value === "") {
      setInitialValue();
      return;
    }

    debouncedHandleSearch(value);
  };

  const clearValues = () => {
    setInitialValue();
    setInputValue("");
  };

  useEffect(() => {
    return () => {
      setInitialValue();
    };
  }, []);

  const toggleJoinModal = () => {
    setOpenJoinModal((prev) => !prev);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseModal}
      aria-describedby="alert-dialog-slide-description"
      sx={{ p: 4 }}
    >
      <Stack
        spacing={3}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? "#F8FAFF"
              : theme.palette.background.paper,
        }}
      >
        <DialogTitle>{"Search"}</DialogTitle>

        <DialogContent>
          <Stack sx={{ width: "100%" }}>
            <Search>
              <SearchIconWrapper>
                <MagnifyingGlass color="#4fa3a5" />
              </SearchIconWrapper>

              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                id="search"
                value={inputValue}
                onChange={handleInputChange}
                aria-label="Search"
                autoComplete="off"
                style={{ width: "90%" }}
              />
              {inputValue !== "" && (
                <IconButton onClick={clearValues}>
                  <SearchIconWrapper>
                    <MdClear color="#4fa3a5" />
                  </SearchIconWrapper>
                </IconButton>
              )}
            </Search>
          </Stack>

          {/* search results */}
          <Stack sx={{ height: "100%", mt: 2 }}>
            <Stack spacing={2.4}>
              {inputValue !== "" && (
                <Stack spacing={1} onBlur={() => !community && clearValues()}>
                  {loading && (
                    <Stack justifyContent={"center"} alignItems={"center"}>
                      <MoonLoader size={20} color={"#4fa3a5"} />
                      <span className="ml-2">Searching...</span>
                    </Stack>
                  )}
                  {posts.length > 0 && (
                    <ul className="z-30">
                      {posts.map((post) => (
                        <li key={post._id} style={{ listStyle: "none" }}>
                          <Stack
                            onClick={() => {
                              navigate(`/post/${post._id}`);
                              clearValues();
                              handleCloseModal();
                            }}
                            direction={"row"}
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.mode === "light"
                                  ? "#F8FAFF"
                                  : theme.palette.background.default,
                              cursor: "pointer",
                            }}
                            borderRadius={1}
                            p={1}
                          >
                            <Stack
                              direction={"row"}
                              spacing={1}
                              alignItems={"center"}
                            >
                              <Stack className="flex-shrink-0">
                                <Avatar
                                  src={post.user.avatar}
                                  alt={post.user.name}
                                  loading="lazy"
                                />
                              </Stack>
                              <Stack className="ml-3">
                                <Typography>{post.title}</Typography>
                                <Typography>{post.content}</Typography>
                                <Typography variant="caption" color={"#999"}>
                                  Posted by {post.user.name} in{" "}
                                  {post.community.name}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </li>
                      ))}
                    </ul>
                  )}
                  {users.length > 0 && (
                    <ul className="z-30">
                      {users.map((user) => (
                        <li key={user._id} style={{ listStyle: "none" }}>
                          <Stack
                            onClick={() => {
                              navigate(`/user/${user._id}`);
                              clearValues();
                              handleCloseModal();
                            }}
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.mode === "light"
                                  ? "#F8FAFF"
                                  : theme.palette.background.default,
                              cursor: "pointer",
                            }}
                            borderRadius={1}
                            p={1}
                          >
                            <Stack
                              direction={"row"}
                              spacing={1}
                              alignItems={"center"}
                            >
                              <Stack>
                                <Avatar src={user.avatar} alt={user.name} />
                              </Stack>
                              <Stack>
                                <Typography>{user.name}</Typography>
                                <Typography variant="caption" color={"#999"}>
                                  {user.email}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </li>
                      ))}
                    </ul>
                  )}
                  {community && (
                    <Stack className="border-b py-2 px-4">
                      <Stack
                        direction={"row"}
                        spacing={1}
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                              ? "#F8FAFF"
                              : theme.palette.background.default,
                          cursor: "pointer",
                        }}
                        borderRadius={1}
                        p={1}
                      >
                        <Stack sx={{ flexShrink: 0 }}>
                          <Avatar src={community.banner} alt={community.name} />
                        </Stack>
                        <Stack direction={"row"}>
                          <Stack>
                            <Typography>{community.name}</Typography>

                            <Typography
                              variant="body2"
                              sx={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                WebkitLineClamp: 1,
                                color: "#999",
                              }}
                            >
                              {community.description}
                            </Typography>
                          </Stack>

                          {!community.isMember && (
                            <>
                              <JoinModal
                                open={openJoinModal}
                                handleCloseJoinModal={() => {
                                  toggleJoinModal(false);
                                  setCommunity(null);
                                }}
                                community={community}
                              />
                              <Button
                                sx={{ height: 40 }}
                                onClick={() => toggleJoinModal(true)}
                              >
                                Join
                              </Button>
                            </>
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                  )}

                  {joinedCommunity && (
                    <Stack
                      key={joinedCommunity._id}
                      onClick={() => {
                        navigate(`/community/${joinedCommunity.name}`);
                        clearValues();
                        handleCloseModal();
                      }}
                    >
                      <Stack
                        direction={"row"}
                        spacing={1}
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                              ? "#F8FAFF"
                              : theme.palette.background.default,
                          cursor: "pointer",
                        }}
                        borderRadius={1}
                        p={1}
                      >
                        <Stack sx={{ flexShrink: 0 }}>
                          <Avatar
                            src={joinedCommunity.banner}
                            alt={joinedCommunity.name}
                          />
                        </Stack>
                        <Stack>
                          <Typography>{joinedCommunity.name}</Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              WebkitLineClamp: 1,
                              color: "#999",
                            }}
                          >
                            {joinedCommunity.description}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </DialogContent>
      </Stack>
    </Dialog>
  );
};

export default SearchModal;
