import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  markNotificationAsRead,
  deleteNotification,
  getNotifications,
} from "../../redux/api/notificationAPI";

const NotificationElement = () => {
  const [notifications, setNotifications] = useState([]);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const { data } = await getNotifications();

        // Log the entire data for inspection
        // console.log("Fetched Notifications:", data);

        // Log the comment information specifically
        // data.forEach((notification) => {
        //   if (notification.comment) {
        //     console.log("Comment Info:", notification.comment);
        //   }
        // });

        setNotifications(data || []);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, []);

  // const handleNotificationClick = async (notificationId, postId) => {
  //   try {
  //     const notification = notifications.find((n) => n._id === notificationId);
  //     const currentPostId =
  //       notification.post?._id || notification.postId || null;

  //     // console.log(
  //     //   "Notification ID:",
  //     //   notificationId,
  //     //   "Post ID:",
  //     //   currentPostId
  //     // );

  //     await markNotificationAsRead(notificationId);
  //     await deleteNotification(notificationId);
  //     setNotifications((prevNotifications) =>
  //       prevNotifications.filter(
  //         (notification) => notification._id !== notificationId
  //       )
  //     );
  //     if (currentPostId) {
  //       navigate(`/post/${currentPostId}`);
  //     }
  //   } catch (error) {
  //     console.error("Error handling notification:", error);
  //   }
  // };

  const handleNotificationClick = async (notificationId) => {
    try {
      const notification = notifications.find((n) => n._id === notificationId);

      await markNotificationAsRead(notificationId);
      await deleteNotification(notificationId);
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) => notification._id !== notificationId
        )
      );

      if (notification.type === "follow") {
        const userId = notification.fromUser._id;
        navigate(`/user/${userId}`);
      } else if (
        notification.type === "comment" ||
        notification.type === "like"
      ) {
        const postId = notification.post?._id || notification.postId || null;
        if (postId) {
          navigate(`/post/${postId}`);
        }
      }
    } catch (error) {
      console.error("Error handling notification:", error);
    }
  };

  return (
    <Stack spacing={0.9} height={"100%"} width="100%" sx={{ mb: 5 }}>
      {notifications && notifications.length > 0 ? (
        notifications
          .sort((a, b) => {
            // Sort in descending order based on the createdAt property
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
          .map((notification) => {
            let commenterName, notificationMessage;

            if (notification.type === "comment") {
              commenterName = notification.comment?.user
                ? `${notification.comment.user.firstName} ${notification.comment.user.lastName}`
                : notification.details &&
                  (() => {
                    const match = notification.details.match(
                      /\b(\w+)\s+(\w+)\s+commented/
                    );
                    return match ? `${match[1]} ${match[2]}` : null;
                  })();
              notificationMessage = "commented on your post";
            } else if (notification.type === "like") {
              commenterName = `${notification.fromUser.firstName} ${notification.fromUser.lastName}`;
              notificationMessage = "likes your post";
            } else if (notification.type === "follow") {
              commenterName = `${notification.fromUser.firstName} ${notification.fromUser.lastName}`;
              notificationMessage = "started following you";
            }

            // Log the comment information for each notification of type "comment"
            // if (notification.type === "comment") {
            //   console.log(
            //     "Notification Comment Info:",
            //     notification.comment?.user
            //   );
            // }

            // console.log("All Notifications:", notifications);

            return (
              <Stack
                key={notification._id}
                p={2}
                onClick={() => handleNotificationClick(notification._id)}
                sx={{
                  cursor: "pointer",
                  boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? "#f8faff"
                      : theme.palette.background.paper,
                  borderRadius: 1,
                }}
              >
                <Stack spacing={1} sx={{ mb: 0.3 }}>
                  <Stack direction={"row"} spacing={1}>
                    <Avatar
                      sx={{ width: 25, height: 25 }}
                      alt={commenterName}
                      src={
                        notification.comment?.user?.avatar ||
                        notification.fromUser?.avatar
                      }
                    />

                    <Stack
                      spacing={0.3}
                      direction={"row"}
                      justifyContent={"space-between"}
                      width={"100%"}
                      alignItems={"center"}
                    >
                      <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        spacing={0.5}
                      >
                        <Box>
                          <Typography fontSize={14}>
                            <span>
                              <b>{commenterName}</b>
                            </span>{" "}
                            {notificationMessage}
                          </Typography>
                        </Box>
                      </Stack>

                      <Typography fontSize={10} color={"text.secondary"}>
                        {notification.formattedDate}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            );
          })
      ) : (
        <Typography textAlign={"center"}>
          No notifications to display
        </Typography>
      )}
    </Stack>
  );
};

export default NotificationElement;
