import React, { useEffect, useState, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Container,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { FaBookmark } from "react-icons/fa";
import Footer from "../Footer";
import ProfileHeader from "./ProfileHeader";
import { getUserAction } from "../../redux/actions/userActions";
import PostOnProfile from "../PostElement/PostOnProfile";
import CommonLoading from "../loader/CommonLoading";
import Saved from "../../pages/dashboard/Saved";
// import useResponsive from "../../hooks/useResponsive";

const UserProfile = ({ userData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("my posts");

  // const isDesktop = useResponsive("up", "md");

  const user = useSelector((state) => state.user?.user);
  const posts = user?.posts;

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setLoading(true);
    const fetchUser = async () => {
      await dispatch(getUserAction(userData._id));
    };
    fetchUser().then(() => setLoading(false));
  }, [dispatch, userData._id]);

  const MemoizedPostOnProfile = memo(PostOnProfile);

  let postToShow;

  postToShow = posts?.map((post) => (
    <MemoizedPostOnProfile key={post._id} post={post} />
  ));

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {loading || !user || !posts ? (
        <>
          <CommonLoading />
        </>
      ) : (
        <Stack height={"100%"} maxHeight={"100vh"} width="100%">
          <Box
            width={"100%"}
            sx={{
              flexGrow: 1,
              height: "100%",
              overflowY: "scroll",
              backgroundColor:
                theme.palette.mode === "light"
                  ? "#f0f4fa "
                  : theme.palette.background.default,
            }}
          >
            <Container sx={{ mt: isMobile ? 10 : 5 }} maxWidth="md">
              <Stack spacing={3} height={"100%"} width="100%" sx={{ mb: 5 }}>
                {/* profile header */}
                <ProfileHeader user={user} />

                <Divider />
                <Stack
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Container maxWidth="sm">
                    <Tabs
                      value={activeTab}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="scrollable auto tabs example"
                      variant="scrollable"
                      scrollButtons="auto"
                      sx={{ mb: 2 }}
                    >
                      <Tab
                        label="my posts"
                        value="my posts"
                        icon={<BsFillFileEarmarkTextFill />}
                        iconPosition="start"
                      />
                      <Tab
                        label="saved posts"
                        value="saved posts"
                        icon={<FaBookmark />}
                        iconPosition="start"
                      />
                    </Tabs>

                    {activeTab === "my posts" && (
                      <>
                        {/* my posts */}

                        {postToShow?.length === 0 ? (
                          <Stack>
                            <Typography
                              fontSize={13}
                              textAlign={"center"}
                              color={"grey"}
                            >
                              You haven't posted anything yet
                            </Typography>
                            {/* <img
                      className="max-w-md rounded-full"
                      src={NoPost}
                      alt="no post"
                    /> */}
                          </Stack>
                        ) : (
                          postToShow
                        )}
                      </>
                    )}
                    {activeTab === "saved posts" && (
                      <>
                        <Saved />
                      </>
                    )}
                  </Container>
                </Stack>
                <Footer />
              </Stack>
            </Container>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default UserProfile;
