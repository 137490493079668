import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { ToggleSidebar, UpdateSidebarType } from "../../redux/slices/app";
import { CaretLeft, X } from "phosphor-react";
import { faker } from "@faker-js/faker";
import { Shared_docs, Shared_links } from "../../data";
import { DocumentMessage, LinkMessage } from "../Conversation/MessageTypes";
import useResponsive from "../../hooks/useResponsive";

const SharedMessages = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const isDesktop = useResponsive("up", "md");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ width: !isDesktop ? "100vw" : 320, maxHeight: "100vh" }}>
        <Stack sx={{ height: "100%" }}>
          {/* header */}
          <Box
            sx={{
              boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
              width: "100%",
              backgroundColor:
                theme.palette.mode === "light"
                  ? "#f8faff"
                  : theme.palette.background.paper,
            }}
          >
            <Stack
              direction={"row"}
              sx={{ height: "100%", p: 2 }}
              alignItems={"center"}
              spacing={3}
              justifyContent={"space-between"}
            >
              <IconButton
                onClick={() => {
                  dispatch(UpdateSidebarType("CONTACT"));
                }}
              >
                <CaretLeft />
              </IconButton>
              <Typography variant="subtitle2">Shared Messages</Typography>
              <IconButton
                onClick={() => {
                  dispatch(ToggleSidebar());
                }}
              >
                <X />
              </IconButton>
            </Stack>
          </Box>

          {/* tabs */}
          <Tabs
            sx={{
              px: 2,
              pt: 2,
              backgroundColor:
                theme.palette.mode === "light"
                  ? "#f8faff"
                  : theme.palette.background.paper,
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            }}
            value={value}
            onChange={handleChange}
            centered
          >
            <Tab label="Media" />
            <Tab label="Links" />
            <Tab label="Docs" />
          </Tabs>

          {/* body */}
          <Stack
            sx={{
              height: "100%",
              position: "relative",
              flexGrow: 1,
              overflowY: "scroll",
              backgroundColor:
                theme.palette.mode === "light"
                  ? "#f8faff"
                  : theme.palette.background.paper,
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            }}
            spacing={value === 1 ? 1 : 3}
            p={3}
          >
            {(() => {
              switch (value) {
                case 0:
                  // images
                  return (
                    <Grid spacing={2} container>
                      {[0, 1, 2, 3, 4, 5, 6].map((el) => {
                        return (
                          <Grid item xs={4}>
                            <img
                              src={faker.image.avatar()}
                              alt={faker.person.fullName()}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  );

                case 1:
                  // links
                  return Shared_links.map((el) => <LinkMessage el={el} />);

                case 2:
                  // docs
                  return Shared_docs.map((el) => <DocumentMessage el={el} />);

                default:
                  break;
              }
            })()}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default SharedMessages;
