import * as api from "../api/notificationAPI";
import * as types from "../constants/notificationConstants.js";

export const getNotificationsAction = () => async (dispatch) => {
  try {
    const { error, data } = await api.getNotifications();

    if (error) {
      throw new Error(error);
    }

    // Log the data to the console
    // console.log("Fetched Notifications:", data);

    dispatch({
      type: types.GET_NOTIFICATIONS_SUCCESS,
      payload: data,
      meta: {
        requiresAuth: true,
      },
    });
  } catch (error) {
    dispatch({
      type: types.GET_NOTIFICATIONS_FAIL,
      payload: error.message,
      meta: {
        requiresAuth: true,
      },
    });
  }
};

export const markNotificationAsReadAction =
  (notificationId) => async (dispatch) => {
    try {
      const { error, data } = await api.markNotificationAsRead(notificationId);

      if (error) {
        throw new Error(error);
      }

      dispatch({
        type: types.MARK_NOTIFICATION_AS_READ_SUCCESS,
        payload: data,
        meta: {
          requiresAuth: true,
        },
      });
    } catch (error) {
      dispatch({
        type: types.MARK_NOTIFICATION_AS_READ_FAIL,
        payload: error.message,
        meta: {
          requiresAuth: true,
        },
      });
    }
  };

export const deleteNotificationAction =
  (notificationId) => async (dispatch) => {
    try {
      const { error, data } = await api.deleteNotification(notificationId);

      if (error) {
        throw new Error(error);
      }

      dispatch({
        type: types.DELETE_NOTIFICATION_SUCCESS,
        payload: data,
        meta: {
          requiresAuth: true,
        },
      });
    } catch (error) {
      dispatch({
        type: types.DELETE_NOTIFICATION_FAIL,
        payload: error.message,
        meta: {
          requiresAuth: true,
        },
      });
    }
  };

export const getUnreadNotificationsCountAction = () => async (dispatch) => {
  try {
    const { error, data } = await api.getUnreadNotificationsCount();

    if (error) {
      throw new Error(error);
    }

    // console.log("Dispatching count:", data.unreadCount);

    dispatch({
      type: types.GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS,
      payload: { count: data.unreadCount },
      meta: {
        requiresAuth: true,
      },
    });
  } catch (error) {
    dispatch({
      type: types.GET_UNREAD_NOTIFICATIONS_COUNT_FAIL,
      payload: error.message,
      meta: {
        requiresAuth: true,
      },
    });
  }
};
