import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useResponsive from "../../hooks/useResponsive";
import useSettings from "../../hooks/useSettings";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Logo from "../../assets/Images/Logo-mark_coloured.png";
import LogoWhite from "../../assets/Images/logo_white.png";
import { Nav_Buttons, Profile_Menu } from "../../data";
import { HouseSimple, Plus } from "phosphor-react";

import AntSwitch from "../../components/AntSwitch";
// import { socket } from "../../socket";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PostDialog from "../../components/modals/PostDialog";

import { logoutAction } from "../../redux/actions/authActions";
import { getJoinedCommunitiesAction } from "../../redux/actions/communityActions";
import { FiSearch, FiMenu } from "react-icons/fi";
import SearchModal from "../../components/modals/SearchModal";
import { LuSettings2 } from "react-icons/lu";
import getPath from "./getPath";
import { getUnreadNotificationsCountAction } from "../../redux/actions/notificationActions";

const MiniSidebar = ({ userData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useResponsive("up", "md");

  const dispatch = useDispatch();

  const navigation = useNavigate();

  const [selectedIcon, setSelectedIcon] = useState(0);
  // const [showSidebar, setShowSidebar] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);

  const { onToggleMode } = useSettings();
  // const [loggingOut, setLoggingOut] = useState(false);

  const [openPostDialog, setOpenPostDialog] = useState(false);
  const [openSearchDialog, setOpenSearchDialog] = useState(false);

  const unreadNotificationsCount = useSelector(
    (state) => state.notifications.unreadCount
  );
  useEffect(() => {
    dispatch(getUnreadNotificationsCountAction());
  }, [dispatch]);

  useEffect(() => {
    // Log out the unread notifications count
    // console.log("Unread Notifications Count:", unreadNotificationsCount);
  }, [unreadNotificationsCount]);

  const handleCloseSearchDialog = () => {
    setOpenSearchDialog(false);
  };

  const handleClosePostDialog = () => {
    setOpenPostDialog(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    // console.log("Event:", event);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const user = useSelector((state) => state.auth?.userData);
  // const joinedCommunities = useSelector(
  //   (state) => state.community?.joinedCommunities
  // );

  useEffect(() => {
    dispatch(getJoinedCommunitiesAction());
  }, [dispatch]);

  // const visibleCommunities = useMemo(() => {
  //   return joinedCommunities?.slice(0, 5);
  // }, [joinedCommunities]);

  // const communityLinks = useMemo(() => {
  //   return visibleCommunities?.map((community) => ({
  //     href: `/community/${community.name}`,
  //     label: community.name,
  //   }));
  // }, [visibleCommunities]);

  return (
    <>
      <Box
        p={2}
        sx={{
          backgroundColor: theme.palette.background.paper,
          boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
          height: "100vh",
          width: 100,

          display: "block",

          position: "fixed",
          top: 0,
          left: 0,

          transition: "left 0.3s ease-in-out",
          zIndex: 998,
        }}
      >
        <Stack
          direction="column"
          alignItems={"center"}
          sx={{ height: "100%" }}
          spacing={2}
          justifyContent="space-between"
        >
          <Stack alignItems={"center"} spacing={2}>
            <Box
              sx={{
                height: 64,
                width: 64,
              }}
              p={1}
            >
              <img
                src={theme.palette.mode === "light" ? Logo : LogoWhite}
                alt="MoMs Chat"
              />
            </Box>
            <Stack
              spacing={2}
              sx={{ width: "max-content" }}
              direction="column"
              alignItems="center"
            >
              {/* home icon */}
              {selectedIcon === 0 ? (
                <Box p={0}>
                  <Tooltip title="Posts" placement="right">
                    <IconButton
                      sx={{
                        width: "max-content",
                        color: "#fff",

                        backgroundColor: theme.palette.primary.main,
                      }}
                      alignItems="center"
                    >
                      <HouseSimple />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                <Tooltip title="Posts" placement="right">
                  <IconButton
                    onClick={() => {
                      setSelectedIcon(0);
                      navigation(getPath(0));
                    }}
                    sx={{
                      width: "max-content",
                      color:
                        theme.palette.mode === "light"
                          ? "#000"
                          : theme.palette.text.primary,
                    }}
                  >
                    <HouseSimple />
                  </IconButton>
                </Tooltip>
              )}
              {/* line divider */}
              <Divider sx={{ width: "48px" }} />

              {/* chat icons */}
              {Nav_Buttons.map((el) =>
                el.index === selectedIcon ? (
                  <Box p={0}>
                    <Tooltip title={el.caption} placement="right">
                      <IconButton
                        sx={{
                          width: "max-content",
                          color: "#fff",
                          backgroundColor: theme.palette.primary.main,
                        }}
                        key={el.index}
                      >
                        {el.icon}
                      </IconButton>
                    </Tooltip>
                  </Box>
                ) : (
                  <Tooltip title={el.caption} placement="right">
                    <IconButton
                      onClick={() => {
                        setSelectedIcon(el.index);
                        navigation(getPath(el.index));
                      }}
                      sx={{
                        width: "max-content",
                        color:
                          theme.palette.mode === "light"
                            ? "#000"
                            : theme.palette.text.primary,
                      }}
                      key={el.index}
                    >
                      {el.isNotification ? (
                        <Badge
                          badgeContent={unreadNotificationsCount}
                          color="error"
                        >
                          {el.icon}
                        </Badge>
                      ) : (
                        el.icon
                      )}
                    </IconButton>
                  </Tooltip>
                )
              )}

              {/* line divider */}
              <Divider sx={{ width: "48px" }} />

              {/* post icon */}

              {/* <Box p={0}>
                <Tooltip title="Create Post" placement="right">
                  <IconButton
                    onClick={() => {
                      setOpenPostDialog(true);
                    }}
                    sx={{
                      width: "max-content",
                      color:
                        theme.palette.mode === "light"
                          ? "#000"
                          : theme.palette.text.primary,
                    }}
                  >
                    <Plus />
                  </IconButton>
                </Tooltip>
              </Box> */}

              {selectedIcon === 7 ? (
                <Box
                  p={0.4}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 5,
                  }}
                >
                  <Tooltip title="Profile" placement="right">
                    <Avatar
                      src={userData.avatar}
                      onClick={() => {
                        setSelectedIcon(7);
                        navigation(getPath(7));
                      }}
                    />
                  </Tooltip>
                </Box>
              ) : (
                <Tooltip title="Profile" placement="right">
                  <Avatar
                    src={userData.avatar}
                    onClick={() => {
                      setSelectedIcon(7);
                      navigation(getPath(7));
                    }}
                  />
                </Tooltip>
              )}
            </Stack>

            {/* line divider */}
            <Divider sx={{ width: "48px" }} />
          </Stack>

          <Stack spacing={2} alignItems={"center"}>
            <Box p={1}>
              <Tooltip title="Search" placement="right">
                <IconButton
                  onClick={() => {
                    setOpenSearchDialog(true);
                  }}
                  sx={{
                    width: "max-content",
                    color:
                      theme.palette.mode === "light"
                        ? "#000"
                        : theme.palette.text.primary,
                  }}
                >
                  <FiSearch />
                </IconButton>
              </Tooltip>
            </Box>

            {/* menu icon */}
            {selectedIcon === 6 ? (
              <Box
                p={1}
                sx={{
                  backgroundColor: theme.palette.primary.default,
                  borderRadius: 5,
                }}
              >
                <Tooltip title="Menu" placement="right">
                  <IconButton sx={{ width: "max-content", color: "#fff" }}>
                    <LuSettings2 />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              <Tooltip title="Menu" placement="right">
                <IconButton
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{
                    width: "max-content",
                    color:
                      theme.palette.mode === "light"
                        ? "#000"
                        : theme.palette.text.primary,
                  }}
                >
                  <LuSettings2 />
                </IconButton>
              </Tooltip>
            )}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              transformOrigin={{
                horizontal: "left",
                vertical: "bottom",
              }}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
            >
              <Stack spacing={1.5} px={1} alignItems={"center"}>
                {/* switch */}
                <Stack
                  spacing={1.5}
                  alignItems={"center"}
                  direction={"row"}
                  justifyContent={"space-between"}
                  sx={{ width: 100 }}
                >
                  <Typography variant="subtitle2">Theme</Typography>
                  <AntSwitch
                    onChange={() => {
                      onToggleMode();
                      handleClose();
                    }}
                    defaultChecked
                  />
                </Stack>
                {Profile_Menu.map((el, id) => (
                  <MenuItem onClick={handleClose}>
                    <Stack
                      onClick={async () => {
                        if (id === 1) {
                          // if id is 1 then dispatch logout
                          // setLoggingOut(true);
                          await dispatch(logoutAction());
                          // setLoggingOut(false);
                        } else {
                          navigation(getPath(6));
                          handleClose();
                        }
                      }}
                      sx={{ width: 100 }}
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      key={el.index}
                    >
                      <span>{el.title}</span> {el.icon}
                    </Stack>
                  </MenuItem>
                ))}
              </Stack>
            </Menu>
          </Stack>
        </Stack>
        {openPostDialog && (
          <PostDialog
            open={openPostDialog}
            handleClose={handleClosePostDialog}
          />
        )}

        {openSearchDialog && (
          <SearchModal
            open={openSearchDialog}
            handleCloseModal={handleCloseSearchDialog}
          />
        )}
      </Box>
    </>
  );
};

export default MiniSidebar;
