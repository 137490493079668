import { API, handleApiError } from "./utils";

export const getUser = async (id) => {
  try {
    const { data } = await API.get(`/users/${id}`);
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const updateUser = async (id, formData) => {
  try {
    console.log("Update User FormData:", formData);

    const { data } = await API.put(`/users/${id}`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Update User Response:", data);
    return { error: null, data };
  } catch (error) {
    console.error("Error updating user:", error);
    return handleApiError(error);
  }
};

// export const updateUserAvatar = async (id, formData) => {
//   console.log("Avatar File in FormData:", formData.get("avatar"));

//   try {
//     const { data } = await API.put(`/users/${id}/update-avatar`, formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     });
//     console.log("Update User Avatar Response:", data);
//     return { error: null, data };
//   } catch (error) {
//     console.error("Error updating user avatar:", error);
//     return handleApiError(error);
//   }
// };

export const updateUserAvatar = async (id, formData) => {
  // console.log("Avatar File in FormData:", formData.get("avatar"));
  try {
    const res = await API.put(`/users/${id}/update-avatar`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { error: null, data: res.data };
  } catch (error) {
    return {
      error: error.response.data.errors,
      data: null,
    };
  }
};

export const updateUserPassword = async (id, formData) => {
  try {
    const { data } = await API.put(`/users/${id}/update-password`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Update User Response:", data);
    return { error: null, data };
  } catch (error) {
    // console.error("Error updating user:", error);
    return handleApiError(error);
  }
};

//! this is handled in admin api
// export const deleteUser = async (id) => {
//   try {
//     const { data } = await API.delete(`/users/${id}`);
//     return { error: null, data };
//   } catch (error) {
//     return handleApiError(error);
//   }
// };

// export const getUsers = async () => {
//   try {
//     const { data } = await API.get("/users");
//     return { error: null, data };
//   } catch (error) {
//     return handleApiError(error);
//   }
// };

export const getPublicUsers = async () => {
  try {
    const { data } = await API.get("/users/public-users");
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};
export const getPublicUser = async (id) => {
  try {
    const { data } = await API.get(`/users/public-users/${id}`);
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const followUser = async (id) => {
  try {
    const { data } = await API.patch(`/users/${id}/follow`);
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const unfollowUser = async (id) => {
  try {
    const { data } = await API.patch(`/users/${id}/unfollow`);
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const getFollowingUsers = async () => {
  try {
    const { data } = await API.get("/users/following");
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const getFollowers = async () => {
  try {
    const { data } = await API.get("/users/followers");
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const getFriends = async () => {
  try {
    const { data } = await API.get("/users/friends");
    return { error: null, data };
  } catch (error) {
    return handleApiError(error);
  }
};
