import * as api from "../api/userAPI";
import * as types from "../constants/userConstants";
import { getPostsAction, getSavedPostsAction } from "./postActions";

export const getUserAction = (id) => async (dispatch) => {
  try {
    const { error, data } = await api.getUser(id);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: types.GET_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_USER_FAIL,
      payload: error.message,
    });
  }
};

export const updateUserAction = (id, formData) => async (dispatch) => {
  console.log("Update User Action Dispatched");
  console.log("User ID:", id);
  console.log("Form Data:", formData);
  try {
    const { error, data } = await api.updateUser(id, formData);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: types.GET_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_USER_FAIL,
      payload: error.message,
    });
  }
};

// export const updateUserAvatarAction = (id, formData) => async (dispatch) => {
//   console.log("Update User Avatar Action Dispatched");
//   console.log("User ID:", id);

//   // Log FormData entries for better visibility
//   for (const pair of formData.entries()) {
//     console.log(pair[0] + ", " + pair[1]);
//   }

//   try {
//     const { error, data } = await api.updateUserAvatar(id, formData);

//     if (error) {
//       throw new Error(error);
//     }

//     dispatch({
//       type: types.UPDATE_AVATAR_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: types.UPDATE_AVATAR_FAIL,
//       payload: error.message,
//     });
//   }
// };

export const updateUserAvatarAction = (id, formData) => async (dispatch) => {
  // console.log("Update User Avatar Action Dispatched");
  // console.log("User ID:", id);

  // Log FormData entries for better visibility
  // for (const pair of formData.entries()) {
  //   console.log("FormData entries:", pair[0] + ", " + pair[1]);
  // }

  // Check if formData includes a file with key 'avatar'
  const avatarFile = formData.get("avatar");
  if (!avatarFile) {
    dispatch({
      type: types.UPDATE_AVATAR_FAIL,
      payload: "No avatar file received.",
    });
    return;
  }

  // Check if avatarFile is of type 'image'
  if (!avatarFile.type.startsWith("image/")) {
    dispatch({
      type: types.UPDATE_AVATAR_FAIL,
      payload: "Invalid file type. Please upload an image file.",
    });
    return;
  }

  try {
    const { error, data } = await api.updateUserAvatar(id, formData);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: types.UPDATE_AVATAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.UPDATE_AVATAR_FAIL,
      payload: error.message,
    });
  }
};

export const updateUserPasswordAction = (id, formData) => async (dispatch) => {
  // console.log("Update User Action Dispatched");
  // console.log("User ID:", id);
  // console.log("Form Data:", formData);
  try {
    const { error, data } = await api.updateUserPassword(id, formData);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: types.UPDATE_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.UPDATE_PASSWORD_FAIL,
      payload: error.message,
    });
  }
};

// export const getUsersAction = () => async (dispatch) => {
//   try {
//     const { error, data } = await api.getUsers();

//     if (error) {
//       throw new Error(error);
//     }

//     dispatch({
//       type: types.GET_USERS_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: types.GET_USERS_FAIL,
//       payload: error.message,
//     });
//   }
// };

export const getPublicUsersAction = () => async (dispatch) => {
  try {
    const { error, data } = await api.getPublicUsers();

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: types.GET_PUBLIC_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_PUBLIC_USERS_FAIL,
      payload: error.message,
    });
  }
};

export const getPublicUserAction = (id) => async (dispatch) => {
  try {
    const { error, data } = await api.getPublicUser(id);
    if (error) {
      throw new Error(error);
    }
    dispatch({
      type: types.GET_PUBLIC_USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_PUBLIC_USER_PROFILE_FAIL,
      payload: error.message,
    });
  }
};

// export const followUserAction = (id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: types.CHANGE_FOLLOW_STATUS_SUCCESS,
//       payload: { isFollowing: true },
//     });

//     const { data, error } = await api.followUser(id);
//     if (error) {
//       throw new Error(error);
//     }

//     // Fetch updated data to ensure counts are accurate
//     dispatch(getPublicUserAction(id));
//   } catch (error) {
//     dispatch({
//       type: types.CHANGE_FOLLOW_STATUS_FAIL,
//       payload: error.message,
//     });
//   }
// };

// export const unfollowUserAction = (id) => async (dispatch) => {
//   try {
//     const { error } = await api.unfollowUser(id);
//     if (error) {
//       throw new Error(error);
//     }

//     dispatch({
//       type: types.CHANGE_FOLLOW_STATUS_SUCCESS,
//       payload: { isFollowing: false },
//     });
//   } catch (error) {
//     dispatch({
//       type: types.CHANGE_FOLLOW_STATUS_FAIL,
//       payload: error.message,
//     });
//   }
// };

export const followUserAction = (id) => async (dispatch) => {
  try {
    // Optimistic UI update
    dispatch({
      type: types.CHANGE_FOLLOW_STATUS_SUCCESS,
      payload: { isFollowing: true },
    });

    const { data, error } = await api.followUser(id);
    if (error) {
      throw new Error(error);
    }

    // Fetch updated data to ensure counts are accurate
    dispatch(getPublicUserAction(id));
  } catch (error) {
    dispatch({
      type: types.CHANGE_FOLLOW_STATUS_FAIL,
      payload: error.message,
    });
  }
};

export const unfollowUserAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: types.CHANGE_FOLLOW_STATUS_SUCCESS,
      payload: { isFollowing: false },
    });

    const { data, error } = await api.unfollowUser(id);
    if (error) {
      throw new Error(error);
    }

    // Fetch updated data to ensure counts are accurate
    dispatch(getPublicUserAction(id));
  } catch (error) {
    dispatch({
      type: types.CHANGE_FOLLOW_STATUS_FAIL,
      payload: error.message,
    });
  }
};

export const getFollowingUsersAction = () => async (dispatch) => {
  try {
    const { error, data } = await api.getFollowingUsers();
    if (error) {
      throw new Error(error);
    }
    dispatch({
      type: types.GET_FOLLOWING_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_FOLLOWING_USERS_FAIL,
      payload: error.message,
    });
  }
};

export const getFollowersAction = () => async (dispatch) => {
  try {
    const { error, data } = await api.getFollowers();
    if (error) {
      throw new Error(error);
    }
    dispatch({
      type: types.GET_FOLLOWERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_FOLLOWERS_FAIL,
      payload: error.message,
    });
  }
};

export const followUserAndFetchData =
  (toFollowId, currentUser) => async (dispatch) => {
    try {
      await dispatch(followUserAction(toFollowId));
      await dispatch(getPublicUsersAction());
      if (currentUser) {
        await dispatch(getPostsAction());
        await dispatch(getUserAction(currentUser._id));
        await dispatch(getSavedPostsAction());
      }
    } catch (error) {
      dispatch({
        type: types.CHANGE_FOLLOW_STATUS_FAIL,
        payload: "Failed to follow user",
      });
    }
  };

export const fetchFriends = () => async (dispatch) => {
  try {
    const { error, data } = await api.getFriends();
    if (error) {
      dispatch({ type: types.GET_FRIENDS_FAIL, payload: error });
    } else {
      dispatch({ type: types.GET_FRIENDS_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({ type: types.GET_FRIENDS_FAIL, payload: error.message });
  }
};
