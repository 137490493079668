import { Link as RouterLink } from "react-router-dom";
import {
  Link as MuiLink,
  Typography,
  Card,
  CardContent,
  Avatar,
  Stack,
} from "@mui/material";

const JoinedCommunityCard = ({ community }) => {
  return (
    <MuiLink
      to={`/community/${community.name}`}
      underline="none"
      component={RouterLink}
    >
      <Card
        sx={{
          borderRadius: 1,
          "&:hover": {
            boxShadow: "2xl",
          },
          "&.Mui-focusVisible": {
            boxShadow: "2xl",
          },
        }}
      >
        <CardContent>
          <Stack direction={"row"} spacing={1}>
            <Avatar
              src={community.banner}
              sx={{ width: 60, height: 60 }}
              alt=""
              loading="lazy"
            />
            <Stack>
              <Typography variant="h5" component="h3" className="mb-2">
                {community.name}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                className="mb-2"
              >
                {community.members.length} members
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </MuiLink>
  );
};

export default JoinedCommunityCard;
