import { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getJoinedCommunitiesAction } from "../../redux/actions/communityActions";
import JoinedCommunityCard from "../../components/community/JoinedCommunityCard";
import CommonLoading from "../../components/loader/CommonLoading";
import { Stack } from "@mui/material";

const MyCommunities = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const joinedCommunities = useSelector(
    (state) => state.community?.joinedCommunities
  );

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getJoinedCommunitiesAction());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const communityCards = useMemo(() => {
    if (!joinedCommunities) {
      return null;
    }
    return joinedCommunities.map((community) => (
      <Stack key={community._id}>
        <JoinedCommunityCard community={community} />
      </Stack>
    ));
  }, [joinedCommunities]);

  if (loading) {
    return (
      <>
        <CommonLoading />
      </>
    );
  }

  return <Stack>{communityCards}</Stack>;
};

export default MyCommunities;
