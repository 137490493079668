import React, { useEffect, lazy, useMemo, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Stack, useMediaQuery } from "@mui/material";
import {
  getPostAction,
  clearPostAction,
} from "../../redux/actions/postActions";
import { useParams, useNavigate } from "react-router-dom";

import CommonLoading from "../../components/loader/CommonLoading";
import FallbackLoading from "../../components/loader/FallbackLoading";

import Footer from "../../components/Footer";
import { useTheme } from "@emotion/react";

const PostView = lazy(() => import("../../components/PostElement/PostView"));

const Posts = () => {
  const theme = useTheme();
  const { postId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const userData = useSelector((state) => state.auth?.userData);

  const joinedCommunities = useSelector((state) =>
    state.community?.joinedCommunities?.map(({ _id }) => _id)
  );

  useEffect(() => {
    dispatch(getPostAction(postId));

    return () => {
      dispatch(clearPostAction());
    };
  }, [dispatch, postId]);

  const post = useSelector((state) => state.posts?.post);

  const isAuthorized = useMemo(() => {
    return post && joinedCommunities?.includes(post.community._id);
  }, [post, joinedCommunities]);

  useEffect(() => {
    if (isAuthorized === false) {
      navigate("/access-denied");
    }
  }, [isAuthorized, navigate]);

  // if (!post || !joinedCommunities) {
  //   return (
  //     <Stack>
  //       <CommonLoading />
  //     </Stack>
  //   );
  // }

  return (
    <>
      <Suspense fallback={<FallbackLoading />}>
        <Stack height={"100%"} maxHeight={"100vh"} width="100%">
          <Box
            width={"100%"}
            sx={{
              flexGrow: 1,
              height: "100%",
              overflowY: "scroll",
              backgroundColor:
                theme.palette.mode === "light"
                  ? "#f0f4fa "
                  : theme.palette.background.default,
            }}
          >
            <Container sx={{ mt: isMobile ? 10 : 5 }} maxWidth="sm">
              {post ? (
                <Stack spacing={3} height={"100%"} width="100%" sx={{ mb: 5 }}>
                  <PostView post={post} userData={userData} />
                  <Footer />
                </Stack>
              ) : (
                <CommonLoading />
              )}
            </Container>
          </Box>
        </Stack>
      </Suspense>
    </>
  );
};

export default Posts;
