import * as types from "../constants/notificationConstants";

const initialState = {
  notifications: [],
  unreadCount: 0,
  notificationError: null,
};

const notificationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: payload,
        notificationError: null,
      };

    case types.GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS:
      // console.log("Reducer count:", payload.count);
      return {
        ...state,
        unreadCount: payload.count,
      };

    case types.GET_NOTIFICATIONS_FAIL:
    case types.GET_UNREAD_NOTIFICATIONS_COUNT_FAIL:
      return {
        ...state,
        notificationError: payload,
      };

    default:
      return state;
  }
};

export default notificationReducer;
