import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Stack,
  Slide,
  Typography,
} from "@mui/material";

import Transition from "../Transition";

const InappropriatePost = ({
  showInappropriateContentModal,

  contentType,
  open,
  handleCloseInappropriateContentModal,
}) => {
  // const modalClass = showInappropriateContentModal
  //   ? "fixed inset-0 overflow-y-auto"
  //   : "hidden";

  const handleClose = () => {
    if (showInappropriateContentModal) {
      handleCloseInappropriateContentModal();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseInappropriateContentModal}
      aria-describedby="alert-dialog-slide-description"
      TransitionComponent={Transition}
      keepMounted
      justifyContent={"center"}
    >
      <DialogTitle>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={1}
          >
            <Typography variant="subtitle2" fontSize={20}>
              Warning: Inappropriate Content
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
            >
              <Typography variant="body">
                Your {contentType} contains content that violates our community
                guidelines. To maintain a positive and respectful environment
                for all users, please remove the inappropriate content and
                ensure compliance with our guidelines.
              </Typography>
            </Stack>
          </Stack>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={1}
          >
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default InappropriatePost;
