import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import LoadingSpinner from "../loader/ButtonLoadingSpinner";
import { leaveFetchData } from "../../redux/actions/communityActions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Slide,
  Stack,
  Typography,
} from "@mui/material";

import Transition from "../Transition";

const LeaveModal = ({ communityName, open, handleCloseLeaveModal }) => {
  const [leaving, setLeaving] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const leaveCommunityHandler = async () => {
    setLeaving(true);
    await dispatch(leaveFetchData(communityName));
    setLeaving(false);
    handleCloseLeaveModal();
    navigate("/");
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseLeaveModal}
      aria-describedby="alert-dialog-slide-description"
      TransitionComponent={Transition}
      keepMounted
      justifyContent={"center"}
    >
      <DialogTitle>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={1}
          >
            <Typography variant="caption2" color="primary">
              Leave Group
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
            >
              Are you sure you want to leave this group?
            </Stack>
          </Stack>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={1}
          >
            <Button
              variant="outlined"
              onClick={handleCloseLeaveModal}
              disabled={leaving}
            >
              Cancel
            </Button>
            <Button
              disabled={leaving}
              onClick={leaveCommunityHandler}
              variant="contained"
              color="error"
              disableElevation
              sx={{
                "&:hover": {
                  backgroundColor: "#cc0c0c",
                },
              }}
            >
              {leaving ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Leave"
              )}
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default LeaveModal;
