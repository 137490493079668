import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createPostAction,
  clearCreatePostFail,
} from "../../redux/actions/postActions";
import InappropriatePostModal from "../modals/InappropriatePostModal";
import TopicConflictModal from "../modals/TopicConflictModal";
import EligibilityDetectionFailModal from "../modals/EligibilityDetectionFailModal";
import {
  Box,
  Button,
  Input,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const PostForm = ({ communityId, communityName }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [showInappropriateContentModal, setShowInappropriateContentModal] =
    useState(false);
  const [showTopicConflictModal, setShowTopicConflictModal] = useState(false);
  const [
    showEligibilityDetectionFailModal,
    setShowEligibilityDetectionFailModal,
  ] = useState(false);

  const [formData, setFormData] = useState({
    content: "",
    file: null,
    error: "",
    loading: false,
  });

  const { isPostInappropriate, postCategory, confirmationToken } = useSelector(
    (state) => ({
      isPostInappropriate: state.posts?.isPostInappropriate,
      postCategory: state.posts?.postCategory,
      confirmationToken: state.posts?.confirmationToken,
    })
  );

  const handleContentChange = (event) => {
    setFormData({
      ...formData,
      content: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      selectedFile.size <= 50 * 1024 * 1024 // 50MB
    ) {
      setFormData({
        ...formData,
        file: selectedFile,
        error: "",
      });
    } else {
      setFormData({
        ...formData,
        file: null,
        error: "Please select an image or video file under 50MB.",
      });
    }
  };

  useEffect(() => {
    if (isPostInappropriate) setShowInappropriateContentModal(true);
    if (postCategory) setShowTopicConflictModal(true);
    if (confirmationToken) setShowEligibilityDetectionFailModal(true);
  }, [isPostInappropriate, postCategory, confirmationToken]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { content, file, loading } = formData;
    if (loading) return;

    if (!content && !file) {
      setFormData({
        ...formData,
        error: "Please enter a message or select a file.",
      });
      return;
    }

    const newPost = new FormData();
    newPost.append("content", content);
    newPost.append("communityId", communityId);
    newPost.append("communityName", communityName);
    newPost.append("file", file);

    setFormData({
      ...formData,
      loading: true,
    });

    try {
      await dispatch(createPostAction(newPost));
      setFormData({
        content: "",
        file: null,
        error: "",
        loading: false,
      });
      event.target.reset();
    } catch (error) {
      setFormData({
        ...formData,
        loading: false,
      });
    }
  };

  const handleRemoveFile = () => {
    setFormData({
      ...formData,
      file: null,
      error: "",
    });
  };

  return (
    <>
      <InappropriatePostModal
        handleCloseInappropriateContentModal={() => {
          setShowInappropriateContentModal(false);
          dispatch(clearCreatePostFail());
        }}
        showInappropriateContentModal={showInappropriateContentModal}
        contentType={"post"}
      />

      <TopicConflictModal
        closeTopicConflictModal={() => {
          setShowTopicConflictModal(false);
          dispatch(clearCreatePostFail());
        }}
        showTopicConflictModal={showTopicConflictModal}
        communityName={postCategory?.community}
        recommendedCommunity={postCategory?.recommendedCommunity}
      />

      <EligibilityDetectionFailModal
        closeEligibilityDetectionFailModal={() => {
          setShowEligibilityDetectionFailModal(false);
          dispatch(clearCreatePostFail());
        }}
        showEligibilityDetectionFailModal={showEligibilityDetectionFailModal}
        confirmationToken={confirmationToken}
      />
      <Stack
        sx={{
          boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
          backgroundColor:
            theme.palette.mode === "light"
              ? "#f8faff"
              : theme.palette.background.paper,
        }}
        p={2}
        borderRadius={1}
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <Stack>
              <TextField
                label="Post something....."
                variant="filled"
                name="content"
                id="content"
                value={formData.content}
                onChange={handleContentChange}
                minLength={10}
                maxLength={3000}
                required
              />
            </Stack>

            <Stack direction={"column"}>
              <Box
                component="label"
                htmlFor="file"
                sx={{
                  mx: "auto",
                  mt: "6",
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  borderRadius: "8px",
                  border: "1px dashed #999",
                  justifyContent: "center",
                  px: "12px",
                  py: "12px",
                  textAlign: "center",
                  width: "100%",
                  color: "gray",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-300"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  width={30}
                  height={30}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                  />
                </svg>
                <Typography variant="body2" sx={{ mx: 3 }}>
                  Photo / Video
                </Typography>
                <Input
                  name="file"
                  type="file"
                  id="file"
                  inputProps={{
                    accept: "image/*, video/*",
                    onChange: handleFileChange,
                  }}
                  style={{ display: "none" }}
                />
              </Box>

              {formData.file && (
                <Stack
                  mt={1}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography color={"#ccc"}>{formData.file.name}</Typography>
                  <Button
                    type="button"
                    onClick={handleRemoveFile}
                    color="error"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      width={20}
                      height={20}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </Button>
                </Stack>
              )}

              {formData.error && (
                <Typography color={"red"}>{formData.error}</Typography>
              )}
            </Stack>

            <Button
              variant="contained"
              sx={{
                px: "16px",
                py: "8px",
                fontSize: "0.875rem",

                ...(formData.loading && {
                  cursor: "not-allowed",
                  opacity: 0.5,
                }),
                display: formData.content || formData.file ? "block" : "none",
              }}
              type="submit"
              disabled={
                formData.loading || (!formData.content && !formData.file)
              }
            >
              {formData.loading ? "Processing..." : "Create post"}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default PostForm;
