import * as types from "../constants/userConstants";
import { LOGOUT } from "../constants/authConstants";

const initialState = {
  user: {},
  users: [],
  publicUsers: [],
  publicUserProfile: {},
  followingUsers: [],
  followers: [],
  friends: [],
  isFollowing: null,
  userError: null,
  passwordMatchError: null,
  avatarUpdateError: null,
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      return {
        ...state,
        user: {},
        users: [],
        publicUsers: [],
        publicUserProfile: {},
        followingUsers: [],
        followers: [],
        friends: [],
        isFollowing: null,
        userError: null,
        avatarUpdateError: null,
      };

    case types.GET_USER_SUCCESS:
      return { ...state, user: payload, userError: null };

    case types.GET_USER_FAIL:
      return { ...state, userError: payload };

    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        users: payload || [],
        userError: null,
      };

    case types.GET_USERS_FAIL:
      return { ...state, userError: payload };

    case types.GET_PUBLIC_USERS_SUCCESS:
      return {
        ...state,
        publicUsers: payload || [],
        userError: null,
      };

    case types.GET_PUBLIC_USERS_FAIL:
      return { ...state, userError: payload };

    case types.GET_PUBLIC_USER_PROFILE_SUCCESS:
      return {
        ...state,
        publicUserProfile: payload || {},
        userError: null,
        isFollowing: payload?.isFollowing ?? null,
      };

    case types.GET_PUBLIC_USER_PROFILE_FAIL:
      return { ...state, userError: payload };

    case types.CHANGE_FOLLOW_STATUS_SUCCESS:
      return {
        ...state,
        isFollowing: payload ? payload.isFollowing : null,
        userError: null,
      };

    case types.CHANGE_FOLLOW_STATUS_FAIL:
      return { ...state, userError: payload };

    case types.GET_FOLLOWING_USERS_SUCCESS:
      return { ...state, followingUsers: payload, userError: null };

    case types.GET_FOLLOWING_USERS_FAIL:
      return { ...state, userError: payload };

    case types.GET_FOLLOWERS_SUCCESS:
      return { ...state, followers: payload, userError: null };

    case types.GET_FOLLOWERS_FAIL:
      return { ...state, userError: payload };

    case types.DELETE_USER_SUCCESS:
      return initialState;

    case types.DELETE_USER_FAIL:
      return { ...state, userError: payload };

    case types.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordMatchError: null, // Reset password match error on success
      };
    case types.UPDATE_PASSWORD_FAIL:
      if (action.payload === "New passwords do not match") {
        return {
          ...state,
          passwordMatchError: action.payload, // Set password match error message
        };
      }

      console.error("Password update error:", action.payload);
      return {
        ...state,
      };

    case types.CLEAR_USER_DATA:
      return initialState; // Reset state to initial values for clearing user data

    case types.UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: payload.avatar,
        },
        avatarUpdateError: null, // Clear any previous avatar update errors
      };

    case types.UPDATE_AVATAR_FAIL:
      return {
        ...state,
        avatarUpdateError: payload,
      };

    case types.GET_FRIENDS_SUCCESS:
      return { ...state, friends: payload, userError: null };

    case types.GET_FRIENDS_FAIL:
      return { ...state, userError: payload };

    default:
      return state;
  }
};

export default userReducer;
