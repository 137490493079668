import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { PhotoProvider, PhotoView } from "react-photo-view";

import DeleteModal from "../modals/DeleteModal";
import Like from "./Like";
import "react-photo-view/dist/react-photo-view.css";
// import Tooltip from "../shared/Tooltip";
import { Avatar, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IoChatbubbleOutline } from "react-icons/io5";
import Save from "./Save";

import { MdOutlineDelete } from "react-icons/md";
import { TbMessageReport } from "react-icons/tb";
import ReportPostModal from "../modals/ReportPostModal";

const Post = ({ post }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector((state) => state.auth?.userData);

  const theme = useTheme();

  // console.log("This is userData:", userData);
  // console.log("Post Object:", post);

  const {
    content,
    fileUrl,
    fileType,
    user,
    community,
    createdAt,
    comments,
    isReported,
  } = post;

  const fullName = `${user?.firstName} ${user?.lastName}`;

  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isReportedPost, setIsReportedPost] = useState(isReported);

  const handleReportClick = () => {
    setIsReportModalOpen(true);
  };

  const handleReportClose = () => {
    setIsReportModalOpen(false);
  };

  const [showModal, setShowModal] = useState(false);
  const toggleModal = (value) => {
    setShowModal(value);
  };

  // console.log("User Object:", user);

  return (
    <Stack spacing={3} height={"100%"} width="100%" sx={{ mb: 1.2 }}>
      <Stack
        p={2}
        sx={{
          boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
          backgroundColor:
            theme.palette.mode === "light"
              ? "#f8faff"
              : theme.palette.background.paper,
          borderRadius: 1,
        }}
      >
        <Stack spacing={1} sx={{ mb: 0 }}>
          <Stack direction={"row"} spacing={2}>
            <Avatar src={user?.avatar} alt={fullName} loading="lazy" />

            <Stack
              direction={"row"}
              spacing={0.3}
              sx={{ justifyContent: "space-between", width: "100%" }}
            >
              <Stack>
                <Typography
                  variant="subtitle2"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  {userData?._id === user?._id ? (
                    <Link
                      style={{
                        textDecoration: "none",
                        color:
                          theme.palette.mode === "light"
                            ? "#000"
                            : theme.palette.primary.main,
                      }}
                      to="/profile"
                    >
                      {fullName}
                    </Link>
                  ) : (
                    <Link
                      style={{
                        textDecoration: "none",
                        color:
                          theme.palette.mode === "light"
                            ? "#000"
                            : theme.palette.primary.main,
                      }}
                      to={`/user/${user?._id}`}
                    >
                      {fullName}
                    </Link>
                  )}
                </Typography>
                <Stack>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/community/${community.name}`}
                  >
                    <Typography
                      variant="caption"
                      fontSize={"9px"}
                      color={"#999"}
                    >
                      {community.name}
                    </Typography>
                  </Link>
                </Stack>
              </Stack>
              <Typography variant="caption" fontSize={"9px"} color={"#999"}>
                {createdAt}
              </Typography>
            </Stack>
          </Stack>

          <Stack>
            <Stack sx={{ pt: 1, cursor: "pointer" }} spacing={1}>
              <Typography
                fontSize={15}
                onClick={() => {
                  navigate(`/post/${post._id}`, {
                    state: { from: location.pathname },
                  });
                }}
              >
                {content}
              </Typography>
              <Stack>
                {fileUrl && fileType === "image" ? (
                  <PhotoProvider
                    overlayRender={() => (
                      <Stack>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            textTransform: "capitalize",
                            color:
                              theme.palette.mode === "light"
                                ? "#000"
                                : theme.palette.primary.main,
                          }}
                        >
                          {fullName}
                        </Typography>
                        <Typography variant="subtitle2">
                          {community.name}
                        </Typography>
                        <Typography variant="subtitle2">{createdAt}</Typography>
                      </Stack>
                    )}
                  >
                    <PhotoView src={fileUrl}>
                      <Stack>
                        <img
                          src={fileUrl}
                          alt={content}
                          loading="lazy"
                          className="imagePost"
                        />
                      </Stack>
                    </PhotoView>
                  </PhotoProvider>
                ) : (
                  fileUrl && (
                    <Stack>
                      <video className="videoPost" src={fileUrl} controls />
                    </Stack>
                  )
                )}
              </Stack>
            </Stack>
          </Stack>
          <Divider />

          <Stack>
            <Stack direction={"row"} spacing={0.5}>
              <Like post={post} />

              <IconButton
                onClick={() => {
                  navigate(`/post/${post._id}`, {
                    state: { from: location.pathname },
                  });
                }}
              >
                <IoChatbubbleOutline />
                <Typography variant="subtitle2">{comments.length}</Typography>
              </IconButton>
              <Save postId={post._id} />

              {isReportedPost ? (
                <IconButton disabled>
                  <TbMessageReport style={{ color: "#4fa3a5" }} />
                </IconButton>
              ) : (
                <IconButton onClick={handleReportClick}>
                  <TbMessageReport />
                </IconButton>
              )}

              <Stack>
                {userData?._id === post.user?._id && (
                  // <Tooltip text="Delete post">
                  <IconButton
                    onClick={() => toggleModal(true)}
                    className="delete-btn"
                  >
                    <MdOutlineDelete />
                  </IconButton>
                  // </Tooltip>
                )}
              </Stack>
            </Stack>
          </Stack>

          {showModal && (
            <DeleteModal
              showModal={showModal}
              postId={post._id}
              onClose={() => toggleModal(false)}
              prevPath={location.pathname}
            />
          )}

          <ReportPostModal
            open={isReportModalOpen}
            onClose={handleReportClose}
            postId={post._id}
            communityId={community._id}
            setReportedPost={setIsReportedPost}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Post;
