import { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { Avatar, Typography, Link as MuiLink, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useResponsive from "../../hooks/useResponsive";

const PublicProfileCard = ({ user }) => {
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");

  const followingSince = new Date(user.followingSince).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <Stack>
      <MuiLink component={RouterLink} underline="none" to={`/user/${user._id}`}>
        <Stack
          spacing={3}
          sx={{
            px: 3,
            py: 3,
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
            borderRadius: 1,
            "&:hover": {
              boxShadow: "2xl",
            },
            "&.Mui-focusVisible": {
              boxShadow: "2xl",
            },
            justifyContent: "space-between",
          }}
          minWidth={"300px"}
        >
          <Stack
            direction={isDesktop ? "row" : "column"}
            alignItems="center"
            spacing={3}
          >
            <Avatar
              src={user.avatar}
              alt="Avatar"
              sx={{ width: 60, height: 60 }}
            />
            <Stack>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ textTransform: "capitalize" }}
              >
                {user.name}
              </Typography>
              <Typography
                variant="caption"
                fontWeight={"light"}
                display="flex"
                alignItems="center"
                gap={1}
                color="text.secondary"
              >
                <CiLocationOn />
                {user.location || "N/A"}
              </Typography>
            </Stack>
          </Stack>

          {/* <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="caption"
              fontWeight="light"
              color="text.secondary"
            >
              Following Since
            </Typography>
            <Typography
              variant="caption"
              fontWeight={"light"}
              color="text.secondary"
            >
              {followingSince}
            </Typography>
          </Stack> */}
        </Stack>
      </MuiLink>
    </Stack>
  );
};

export default memo(PublicProfileCard);
