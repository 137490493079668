// Import PropTypes for defining prop types for the component
import PropTypes from "prop-types";

// Import useMemo hook from React for performance optimization
import { useMemo } from "react";

// Import necessary components and utilities from Material-UI
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

// Import a custom hook 'useSettings' from '../hooks/useSettings.js'
import useSettings from "../hooks/useSettings.js";

// Import various configuration objects for the theme
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";

// Define PropTypes for the ThemeProvider component
ThemeProvider.propTypes = {
  children: PropTypes.node,
};

// Define the ThemeProvider component as a function
export default function ThemeProvider({ children }) {
  // Use the 'useSettings' hook to get the current theme mode and direction
  const { themeMode, themeDirection } = useSettings();

  // Determine if the current theme mode is light
  const isLight = themeMode === "light";

  // Create an options object for the MUI theme using useMemo for memoization
  const themeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark, // Use the appropriate color palette based on the theme mode
      typography,
      breakpoints,
      shape: { borderRadius: 8 }, // Define shape properties, such as border radius
      direction: themeDirection, // Set the theme direction (ltr or rtl)
      shadows: isLight ? shadows.light : shadows.dark, // Use the appropriate shadow styles
      customShadows: isLight ? customShadows.light : customShadows.dark, // Use custom shadow styles
    }),
    [isLight, themeDirection] // Depend on theme mode and direction for memoization
  );

  // Create a MUI theme using 'createTheme' with the defined options
  const theme = createTheme(themeOptions);

  // Apply component overrides to the theme
  theme.components = componentsOverride(theme);

  // Render the StyledEngineProvider and MUIThemeProvider with the theme and child components
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline /> {/* Apply a CSS baseline to reset browser styles */}
        {children} {/* Render the child components */}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
