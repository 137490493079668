import React from "react";
import { Box, Container, Stack } from "@mui/material";
import Footer from "../../components/Footer";
import NotificationElement from "../../components/NotificationElement";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Notifications = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Stack height={"100vh"} maxHeight={"100vh"} width="100%">
        <Box
          width={"100%"}
          sx={{
            flexGrow: 1,
            height: "100%",
            overflowY: "scroll",
            backgroundColor:
              theme.palette.mode === "light"
                ? "#f0f4fa "
                : theme.palette.background.default,
          }}
        >
          <Container sx={{ mt: isMobile ? 10 : 5 }} maxWidth="sm">
            <Stack spacing={3} height={"100%"} width="100%" sx={{ mb: 5 }}>
              {/* notification */}
              <NotificationElement />
              {/* <Footer /> */}
            </Stack>
          </Container>
        </Box>
      </Stack>
    </>
  );
};

export default Notifications;
