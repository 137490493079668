import React from "react";
import { Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const UserStatsCard = ({
  followers,
  following,
  totalCommunities,
  totalPosts,
}) => {
  const theme = useTheme();

  const styles = {
    columnStack: {
      justifyContent: "center",
      alignItems: "center",
      padding: 1,
      borderRadius: 1,
    },
    columnStackText: {
      color: "#999",
      fontSize: "12px",
    },
    columnStackTextHeader: {
      fontSize: "20px",
      fontWeight: "bolder",
    },
    link: {
      textDecoration: "none",
      color: "inherit",
    },
  };

  return (
    <Stack
      spacing={2}
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Stack
        component={RouterLink}
        to="/following"
        sx={{ ...styles.columnStack, ...styles.link }}
        direction={"column"}
      >
        <Stack sx={styles.columnStackTextHeader}>{followers}</Stack>
        <Typography sx={styles.columnStackText}>Followers</Typography>
      </Stack>

      <Stack
        component={RouterLink}
        to="/following"
        sx={{ ...styles.columnStack, ...styles.link }}
        direction={"column"}
      >
        <Stack sx={styles.columnStackTextHeader}>{following}</Stack>
        <Typography sx={styles.columnStackText}>Following</Typography>
      </Stack>

      <Stack
        component={RouterLink}
        to="/communities"
        sx={{ ...styles.columnStack, ...styles.link }}
        direction={"column"}
      >
        <Stack sx={styles.columnStackTextHeader}>{totalCommunities}</Stack>
        <Typography sx={styles.columnStackText}>Total Groups</Typography>
      </Stack>

      {/* <Stack
        component={RouterLink}
        to="/following"
        sx={{ ...styles.columnStack, ...styles.link }}
        direction={"column"}
      >
        <Stack sx={styles.columnStackTextHeader}>{totalPosts}</Stack>
        <Typography sx={styles.columnStackText}>Posts</Typography>
      </Stack> */}
    </Stack>
  );
};

export default UserStatsCard;
