import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Divider, Link, Stack, Typography } from "@mui/material";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <Divider />
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ color: "#999" }}
        spacing={1}
      >
        <Stack direction={"row"} spacing={3}>
          <Typography fontSize={14}>
            <Link color="#999" to={"https://www.meshofmothers.ca"}>
              Mesh Of Mothers Foundation
            </Link>
          </Typography>
          <Typography fontSize={14}>
            <Link
              color="#999"
              to={"https://www.meshofmothers.ca/privacy-policy"}
            >
              Privacy Policy
            </Link>
          </Typography>
          <Typography fontSize={14}>
            <Link
              color="#999"
              to={"https://www.meshofmothers.ca/cookie-policy"}
            >
              Cookie Policy
            </Link>
          </Typography>
          <Typography fontSize={14}>
            <Link
              color="#999"
              to={"https://www.meshofmothers.ca/terms-and-conditions"}
            >
              Terms & Conditions
            </Link>
          </Typography>
        </Stack>
        <Stack>
          <Typography fontSize={14}>
            &copy; {year}{" "}
            <Link color="#999" to={"https://www.meshofmothers.ca"}>
              {" "}
              Mesh Of Mothers
            </Link>
          </Typography>
        </Stack>

        <Stack direction={"row"} sx={{ my: 2 }} spacing={0.5}>
          <Typography fontSize={14}>Designed & Developed By:</Typography>
          <Link
            color="#999"
            fontSize={14}
            component={RouterLink}
            to="http://www.veltetech.com"
          >
            Velte Technology Solutions
          </Link>
        </Stack>
      </Stack>
    </>
  );
};

export default Footer;
