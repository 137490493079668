import * as types from "../constants/messageConstants";

const initialState = {
  messages: [],
  unreadCount: 0,
  messageError: null,
};

const messageReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: payload,
        messageError: null,
      };

    case types.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [...state.messages, payload],
        messageError: null,
      };

    case types.MARK_MESSAGE_AS_READ_SUCCESS:
      return {
        ...state,
        messages: state.messages.map((message) =>
          message._id === payload._id ? { ...message, isRead: true } : message
        ),
        messageError: null,
      };

    case types.DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: state.messages.filter(
          (message) => message._id !== payload._id
        ),
        messageError: null,
      };

    case types.GET_UNREAD_MESSAGES_COUNT_SUCCESS:
      return {
        ...state,
        unreadCount: payload.count,
      };

    case types.GET_MESSAGES_FAIL:
    case types.SEND_MESSAGE_FAIL:
    case types.MARK_MESSAGE_AS_READ_FAIL:
    case types.DELETE_MESSAGE_FAIL:
    case types.GET_UNREAD_MESSAGES_COUNT_FAIL:
      return {
        ...state,
        messageError: payload,
      };

    default:
      return state;
  }
};

export default messageReducer;
