export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_PUBLIC_USERS_SUCCESS = "GET_PUBLIC_USERS_SUCCESS";
export const GET_PUBLIC_USERS_FAIL = "GET_PUBLIC_USERS_FAIL";
export const GET_PUBLIC_USER_PROFILE_SUCCESS =
  "GET_PUBLIC_USER_PROFILE_SUCCESS";
export const GET_PUBLIC_USER_PROFILE_FAIL = "GET_PUBLIC_USER_PROFILE_FAIL";
export const CHANGE_FOLLOW_STATUS_SUCCESS = "CHANGE_FOLLOW_STATUS_SUCCESS";
export const CHANGE_FOLLOW_STATUS_FAIL = "CHANGE_FOLLOW_STATUS_FAIL";
export const GET_FOLLOWING_USERS_SUCCESS = "GET_FOLLOWING_USERS_SUCCESS";
export const GET_FOLLOWING_USERS_FAIL = "GET_FOLLOWING_USERS_FAIL";

export const GET_FOLLOWERS_SUCCESS = "GET_FOLLOWERS_SUCCESS";
export const GET_FOLLOWERS_FAIL = "GET_FOLLOWERS_FAIL";

export const UPDATE_USERNAME_FAIL = "UPDATE_USERNAME_FAIL";
export const UPDATE_USERNAME_SUCCESS = "UPDATE_USERNAME_SUCCESS";
export const UPDATE_AVATAR_FAIL = "UPDATE_AVATAR_FAIL";
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";

export const GET_FRIENDS_SUCCESS = "GET_FRIENDS_SUCCESS";
export const GET_FRIENDS_FAIL = "GET_FRIENDS_FAIL";
