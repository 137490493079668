import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FriendElement from "../../components/UserComponents/FriendElement";
import { fetchFriends } from "../../redux/actions/userActions";

const FriendsList = () => {
  const dispatch = useDispatch();
  const friends = useSelector((state) => state.user.friends);
  // console.log("Friends", friends);

  useEffect(() => {
    dispatch(fetchFriends());
  }, [dispatch]);

  return (
    <>
      {friends.map((el) => {
        return <FriendElement key={el._id} {...el} />;
      })}
    </>
  );
};

export default FriendsList;
