import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Box,
  Container,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  getUserAction,
  updateUserPasswordAction,
} from "../../redux/actions/userActions";
import { clearMessage } from "../../redux/actions/authActions";
import { RxCross1 } from "react-icons/rx";
import ButtonLoadingSpinner from "../../components/loader/ButtonLoadingSpinner";
import CommonLoading from "../../components/loader/CommonLoading";

// import useResponsive from "../../hooks/useResponsive";

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  // const isDesktop = useResponsive("up", "md");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const user = useSelector((state) => state.user?.user);
  const userId = user?._id;
  const userData = useSelector((state) => state.auth?.userData);

  const passwordMatchError = useSelector(
    (state) => state.user.passwordMatchError
  );

  useEffect(() => {
    if (!user) {
      console.error("User object is undefined or null.");
      // Optionally, dispatch getUserAction here to fetch user data
    }
  }, [user]);

  const [isUpdating, setIsUpdating] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  useEffect(() => {
    setIsUpdating(true);
    const fetchUser = async () => {
      await dispatch(getUserAction(userData._id));
    };
    fetchUser().then(() => setIsUpdating(false));
  }, [dispatch, userData._id]);

  const handleUpdatePassword = async () => {
    setIsUpdating(true);
    setPasswordUpdated(false);

    if (newPassword.trim() !== repeatNewPassword.trim()) {
      setPasswordError("New passwords do not match.");
      return;
    }

    const userId = user?._id;
    if (!userId) {
      console.error("User ID is undefined or null.");
      return;
    }

    try {
      // Validate old password
      if (!oldPassword) {
        setPasswordError("Please enter your old password.");
        setIsUpdating(false);
        return;
      }

      // Validate new password match
      if (newPassword !== repeatNewPassword) {
        setPasswordError("New passwords do not match.");
        setIsUpdating(false);
        return;
      }

      const formData = {
        oldPassword,
        newPassword,
      };

      await dispatch(updateUserPasswordAction(user._id, formData));
      await dispatch(getUserAction(user._id));

      setOldPassword("");
      setNewPassword("");
      setRepeatNewPassword("");
      setPasswordError(null);
      setIsUpdating(false);
      setPasswordUpdated(true);
    } catch (error) {
      console.error("Error updating password:", error);

      // Check if the error has a response from the server
      if (error.response && error.response.data) {
        setPasswordError(error.response.data.message);
      } else {
        setPasswordError("Error updating password. Please try again.");
      }

      setPasswordError("Error updating password. Please try again.");
      setIsUpdating(false);
    }
  };

  const handleClearMessage = () => {
    dispatch(clearMessage());
  };

  if (!user) {
    return (
      <>
        <CommonLoading />
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          width: isMobile ? "100vh" : "calc(100vw - 320px)",
          backgroundColor:
            theme.palette.mode === "light"
              ? "#f0f4fa "
              : theme.palette.background.default,
        }}
      >
        <Container sx={{ mt: isMobile ? 10 : 5 }} maxWidth="sm">
          <Box
            width={"100%"}
            sx={{
              position: "relative",
              flexGrow: 1,
              height: "100%",
              overflowY: "scroll",
              borderRadius: 1,
              backgroundColor:
                theme.palette.mode === "light"
                  ? "#f8faff "
                  : theme.palette.background.paper,

              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            }}
            p={2}
          >
            <Stack spacing={2}>
              <Typography>Change Password</Typography>
              <Stack>
                <TextField
                  id="password"
                  label="Old Password"
                  variant="outlined"
                  type="password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </Stack>

              <Stack>
                <TextField
                  id="password"
                  label="New Password"
                  variant="outlined"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Stack>

              <Stack>
                <TextField
                  id="password"
                  label="Repeat New Password"
                  variant="outlined"
                  type="password"
                  value={repeatNewPassword}
                  onChange={(e) => setRepeatNewPassword(e.target.value)}
                />
              </Stack>
              {passwordError && (
                <Box
                  mt={3}
                  sx={{
                    color: "#dc2626",
                    border: "1px solid #dc2626",
                    padding: "12px",
                    borderRadius: "8px",
                    textAlign: "center",
                    mx: "auto",
                    width: "100%",
                  }}
                  mb={2}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography color="error">{passwordError}</Typography>

                    {/* <IconButton color="error" onClick={handleClearMessage}>
                      <RxCross1 />
                    </IconButton> */}
                  </Stack>
                </Box>
              )}
            </Stack>
            {passwordUpdated && (
              <Box
                mt={3}
                sx={{
                  color: "#22bb33",
                  border: "1px solid #22bb33",
                  padding: "12px",
                  borderRadius: "8px",
                  textAlign: "center",
                  mx: "auto",
                  width: "100%",
                }}
                mb={2}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography color="#22bb33">
                    Password updated successfully!
                  </Typography>

                  {/* <IconButton color="error" onClick={handleClearMessage}>
                    <RxCross1 />
                  </IconButton> */}
                </Stack>
              </Box>
            )}
            {passwordMatchError && (
              <Box
                mt={3}
                sx={{
                  color: "#dc2626",
                  border: "1px solid #dc2626",
                  padding: "12px",
                  borderRadius: "8px",
                  textAlign: "center",
                  mx: "auto",
                  width: "100%",
                }}
                mb={2}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography color="error">{passwordMatchError}</Typography>

                  {/* <IconButton color="error" onClick={handleClearMessage}>
                    <RxCross1 />
                  </IconButton> */}
                </Stack>
              </Box>
            )}
            <Stack
              spacing={1}
              direction={"row"}
              mt={2}
              width={"100%"}
              sx={{ justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                disabled={isUpdating}
                type="button"
                onClick={handleUpdatePassword}
              >
                {isUpdating ? (
                  <ButtonLoadingSpinner loadingText={"Updating..."} />
                ) : (
                  <Typography>Update</Typography>
                )}
              </Button>
              {/* <Button variant="outlined" type="button" onClick={handleClose}>
              Cancel
            </Button> */}
            </Stack>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ProfileSettings;
